import axios from 'axios';
import { useState, useEffect } from 'react';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import{ IonCol,IonButton,IonDatetimeButton,IonDatetime,IonRow,IonCard,IonIcon,IonItem,IonLabel,IonModal, IonLoading, IonCardHeader, IonCardTitle, IonHeader, IonToolbar, IonGrid, IonContent, IonCardContent, IonAlert, IonList, IonChip, IonText}from '@ionic/react';
import { IonDatetimeCustomEvent, DatetimeChangeEventDetail,} from '@ionic/core/components';
import { cashOutline, statsChartOutline, logoEuro, trash, eyeOutline, fileTrayOutline, closeOutline, businessOutline, storefrontOutline } from 'ionicons/icons';
import { infoControlView } from '../../common/contractUtils';
import 'react-data-grid/lib/styles.css';
import ReactDataGrid from 'react-data-grid';
import { JSX } from 'react/jsx-runtime';
let URL = obtainURLBackendClay();
const VistaControl = () => {
    const {  dispatch: appDispatch } = useAppContext();
    const [detailsModal, setDetailsModal] = useState<DetailsModal>({company: null, bank: null, currency: null, realBalance: null, sapBalance: null});
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const [rows_first_Table, setRowsFirstTable] = useState<any>([]);
    const [rows_second_Table, setRowsSecondTable] = useState<any>([]);
    const [response, setResponse] = useState<[]>([]);
    const [tokenPSG, setTokenPSG] = useState(JSON.parse("" + localStorage.getItem("user")).token);
    const [cambioUSD, setCambioUSD] = useState('');
    const [cambioEUR, setCambioEUR] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateUntil, setDateUntil] = useState('');
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(),currentDate.getMonth(), 1);
    const defaultEndDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate() -1);
    const [selectedStartDate, setStartDate] = useState(defaultStartDate);
    const [selectedEndDate, setEndDate] = useState(defaultStartDate);
    const [startDatePicked, setStartDatePicked] = useState(false);
    const [EndDatePicked, setEndDatePicked] = useState(false);
    const [loading, setLoading] = useState(false)
  
    localStorage.setItem("cacheTabla", '[]');
    localStorage.setItem("row", '[]');
    let USDollar = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD' });
    interface DetailsModal  {company: string | null, bank: string | null, currency: string | null, realBalance: number | null, sapBalance: number | null}
    

    // Función para determinar la clase CSS condicionalmente
    const getCellClass = (row:any) => {
       if (["TOTAL CORRIENTE", "TOTAL INVERSION", "TOTAL GENERAL"].includes(row.accountType)) {
       return 'bold-cell';
       }
       return '';
    };
    const columns_first_table = [
      {key: 'accountType', name: 'Tipo de Cuenta', cellClass: getCellClass},
      {key: 'bankAlias', name: 'Banco', cellClass: getCellClass},
      {key: 'currency', name: 'Moneda', cellClass: getCellClass},
      {key: 'BRYSA', name: 'BRYSA', cellClass: getCellClass},
      {key: 'CIPP', name: 'CIPP', cellClass: getCellClass},
      {key: 'HIGHFLY', name: 'HIGH FLY', cellClass: getCellClass},
      {key: 'INMOBILIARIA', name: 'INMOBILIARIA', cellClass: getCellClass},
      {key: 'PSG', name: 'PSG', cellClass: getCellClass},
      {key: 'PSGPERU', name: 'PSG PERU', cellClass: getCellClass},
      {key: 'groupSum', name: 'TOTAL', cellClass: getCellClass}
    ];

    const columns_second_table  = [
      {key: 'accountType', name: 'Tipo de Cuenta', cellClass: getCellClass},
      {key: 'bankAlias', name: 'Banco', cellClass: getCellClass},
      {key: 'currency', name: 'Moneda', cellClass: getCellClass},
      {key: 'BRYSA', name: 'BRYSA', cellClass: getCellClass},
      {key: 'CIPP', name: 'CIPP', cellClass: getCellClass},
      {key: 'HIGHFLY', name: 'HIGH FLY', cellClass: getCellClass},
      {key: 'INMOBILIARIA', name: 'INMOBILIARIA', cellClass: getCellClass},
      {key: 'PSG', name: 'PSG', cellClass: getCellClass},
      {key: 'PSGPERU', name: 'PSG PERU', cellClass: getCellClass},
      {key: 'groupSum', name: 'TOTAL', cellClass: getCellClass}
    ];

    useEffect(() => {
      const fechaActual = new Date()
      const yyyy = fechaActual.getFullYear()
      const mm = String(fechaActual.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
      const dd = String(fechaActual.getDate() - 1).padStart(2, '0')
      const dateUntil = `${yyyy}-${mm}-${dd}`;
      // Formatear la fecha como "YYYY-MM-DD"
      const dateFrom = `${defaultStartDate.getFullYear()}-${(defaultStartDate.getMonth() + 1).toString().padStart(2, '0')}-${defaultStartDate.getDate().toString().padStart(2, '0')}`;

      setDateFrom(dateFrom); //Siempre parado en el primer dia de cada mes 
      setDateUntil(dateUntil) //Siempre parado en el dia anterior del dia actual de cada mes
      localStorage.setItem("fechaDesde",dateFrom);
      localStorage.setItem("fechaHasta",dateUntil);
      setTokenPSG(JSON.parse("" + localStorage.getItem("user")).token);
      callBalancesClayCompanies(dateFrom, dateUntil, tokenPSG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callBalancesClayCompanies = async (datefrom: string, dateUntil: string,  token: string) =>  {
      setLoading(true) // Cada vez que se recarga la pagina sale el loading
      setRowsFirstTable([]);
      setResponse([]);

      await axios.get(`${URL}/cuentas/saldos/consolidado/control1?fechaInicio=${datefrom}&fechaTermino=${dateUntil}`, createHeader(token))
        .then((response) => {
          console.log('RESPONSE: ', response.data.data);
          console.log('SALDOS: ', response.data.data.response.pivot);
          response.data.data.response.realVsExtractoSap.length > 0 ? setLoading(false) : setLoading(false) 
          setResponse(response.data.data.response.realVsExtractoSap);
          setCambioUSD(response.data.data.response.tipoCambio.filter((item: any)=> item.currency === 'USD')[0].exchangeRate);
          setCambioEUR(response.data.data.response.tipoCambio.filter((item: any)=> item.currency === 'EUR')[0].exchangeRate);
          
          const balancesFirstTable = response.data.data.response?.realVsExtractoSap;
          const balancesSecondTable = response.data.data.response?.cuentaMayorSapVsExtractoSap;
          // const testBalances = infoControlView.pivot
          fillBalances(balancesFirstTable) // esto retorna ya el array con la data arreglada de la primera tabla
          fillBalances(balancesSecondTable) //  esto retorna ya el array con la data arreglada de la segunda tabla
          
          setRowsFirstTable(fillBalances(balancesFirstTable));
          setRowsSecondTable(fillBalances(balancesSecondTable));
          setLoading(false)
        }).catch((error) => {
          console.error("Error: " + error);
          tokenInvalido();
        });
    }
    

    const fillBalances = (balances: Array<any>) => {
       const result: { accountType: any; bankAlias: any; currency: any; BRYSA: string | JSX.Element; CIPP: string | JSX.Element; HIGHFLY: string | JSX.Element; INMOBILIARIA: string | JSX.Element; PSG: string | JSX.Element; PSGPERU: string | JSX.Element; groupSum: JSX.Element; }[] = []
       balances.forEach((element: any) => {
              let response = {
                accountType: element.accountType,
                bankAlias: element.bankAlias,
                currency: element.currency,
                BRYSA: element.companies.BRYSA.hasDifference ? <><IonChip class="ion-no-margin" color='tertiary'>
                                                                  <IonLabel>{USDollar.format(element.companies.BRYSA.realBalance)}</IonLabel>
                                                                 </IonChip>
                                                                 <div>
                                                                   {USDollar.format(element.companies.BRYSA.realBalance)}
                                                                 </div>
                                                                 <div>
                                                                   {USDollar.format(element.companies.BRYSA.sapBalance)}
                                                                 </div>
                                                               </>: USDollar.format(element.companies.BRYSA.realBalance),
                CIPP: element.companies.CIPP.hasDifference ? <><IonChip class="ion-no-margin" color='tertiary'>
                                                                  <IonLabel>{USDollar.format(element.companies.CIPP.realBalance)}</IonLabel>
                                                               </IonChip>
                                                                  <div>
                                                                   {USDollar.format(element.companies.CIPP.realBalance)}
                                                                  </div>
                                                                  <div>
                                                                    {USDollar.format(element.companies.CIPP.sapBalance)}
                                                                  </div>
                                                             </>: USDollar.format(element.companies.CIPP.realBalance),
                HIGHFLY: element.companies.HIGHFLY.hasDifference ? <><IonChip class="ion-no-margin" color='tertiary' >
                                                                            <IonLabel> {USDollar.format(element.companies.HIGHFLY.realBalance)}</IonLabel>
                                                                     </IonChip>
                                                                            <div>
                                                                              {USDollar.format(element.companies.HIGHFLY.realBalance)}
                                                                            </div>
                                                                            <div>
                                                                              {USDollar.format(element.companies.HIGHFLY.sapBalance)}
                                                                            </div>
                                                                    </>: USDollar.format(element.companies.HIGHFLY.realBalance),
                INMOBILIARIA: element.companies.INMOBILIARIA.hasDifference ? <><IonChip class="ion-no-margin" color='tertiary' >
                                                                                    <IonLabel>{USDollar.format(element.companies.INMOBILIARIA.realBalance)}</IonLabel>
                                                                                </IonChip>
                                                                                    <div>
                                                                                      {USDollar.format(element.companies.INMOBILIARIA.realBalance)}
                                                                                    </div>
                                                                                    <div>
                                                                                      {USDollar.format(element.companies.INMOBILIARIA.sapBalance)}
                                                                                    </div>
                                                                             </>: USDollar.format(element.companies.INMOBILIARIA.realBalance),
                PSG: element.companies.PSG.hasDifference ? <>
                                                             <IonChip class="ion-no-margin" color='tertiary'>
                                                                  <IonLabel>{USDollar.format(element.companies.PSG.realBalance)}</IonLabel>
                                                             </IonChip>
                                                                  <div>
                                                                   {USDollar.format(element.companies.PSG.realBalance)}
                                                                  </div>
                                                                  <div>
                                                                    {USDollar.format(element.companies.PSG.sapBalance)}
                                                                  </div>
                                                           </>: USDollar.format(element.companies.PSG.realBalance),
                PSGPERU: element.companies.PSGPERU.hasDifference ? <><IonChip class="ion-no-margin" color='tertiary'>
                                                                              <IonLabel>{USDollar.format(element.companies.PSGPERU.realBalance)}</IonLabel>
                                                                     </IonChip>
                                                                              <div>
                                                                              {USDollar.format(element.companies.PSGPERU.realBalance)}
                                                                              </div>
                                                                              <div>
                                                                                {USDollar.format(element.companies.PSGPERU.sapBalance)}
                                                                              </div>
                                                                   </>: USDollar.format(element.companies.PSGPERU.realBalance),
                groupSum: <><IonText color='light'>{USDollar.format(element.rowSum)}</IonText></>
              };
              result.push(response);
       });
       return result;
    }

    const tokenInvalido = () => {
      setTimeout(function () {
        console.log("cerrar sesion");
        localStorage.removeItem('user');
        localStorage.removeItem('screen');
        localStorage.removeItem('sapcredentials');
        appDispatch({
          type: AppStateReducerActionTypes.LOGOUT
        });
      }, 4000);
    }

    const onDateChange = (event: IonDatetimeCustomEvent < DatetimeChangeEventDetail >, type: string ) => {
      if (event.detail.value) {
          let fecha =  new Date(event.detail.value as string);
          let yyyy = new Date(event.detail.value as string).getFullYear()
          let mm = String(fecha.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
          let dd = String(fecha.getDate()).padStart(2, '0')
          let dateSelec = `${yyyy}-${mm}-${dd}`;
        if (type === 'start') {
          setStartDate(new Date(event.detail.value as string));
          setDateFrom(dateSelec);
          setStartDatePicked(true);
          console.log('ESTA ES LA FECHA DESDE CUANDO SE SETEA:', dateSelec);
          localStorage.setItem("dateFrom",''+ dateSelec);
        } else {
          setEndDate(new Date(event.detail.value as string));
          setDateUntil(dateSelec);
          setEndDatePicked(true);
          console.log('ESTA ES LA FECHA HASTA CUANDO SE SETEA:', dateSelec);
          localStorage.setItem("fechaHasta",''+ dateSelec);
        }
      }
    }

    const refreshSaldos = () => {
        callBalancesClayCompanies(dateFrom, dateUntil, tokenPSG);
    }

    const clearDateFilter = (type: string)=> {
      if (type === 'start') {
        setStartDatePicked(false);
      } else {
        setEndDatePicked(false);
      }
    }
    

    const onCellClick = async (info: any) => {
      console.log('INFO DE LA CELDA QUE CLICKEE:', info);
      
      //Verifico que la celda clickeada sea valida para mostrar el modal de diferencia de saldos y si no muestro la alerta
      if ((info.column.key === 'PSG' || info.column.key === 'PSGPERU' || 
          info.column.key === 'CIPP' || info.column.key === 'BRYSA' || 
          info.column.key === 'HIGHFLY' || info.column.key === 'INMOBILIARIA') && info.row.accountType === 'CORRIENTE' && typeof info.row[info.column.key] !== 'string') {
            console.log('ENTRE AL IF');
            
            //Seteo el detalle del titulo del modal dinamico
            setDetailsModal({
              company: info.column.key,
              bank: info.row.bankAlias,
              realBalance: info.row[info.column.key].props.children[1].props.children,
              sapBalance: info.row[info.column.key].props.children[2].props.children,
              currency: info.row.currency
            });
            setModalIsOpen(true);
      } else {
            setAlertIsOpen(true);
      }
    }


    // const formatearNumero = (numero: number): string => {
    //    // Usamos el objeto Intl.NumberFormat con el parámetro 'es-CL' para el formato chileno
    //    const formatoChileno = new Intl.NumberFormat('es-CL');
    //     // Formateamos el número utilizando el objeto Intl.NumberFormat
    //    const numeroFormateado = formatoChileno.format(numero);
    //    // Devolvemos el número formateado
    //    return numeroFormateado;
    // }

    return (
    <>
      <IonCard>
        <IonLoading isOpen={loading}  message="Cargando Datos Vistas de control..." />
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Vistas de control</IonCardTitle>
        </IonCardHeader>
        {/* Selector de fechas */}
        <IonRow class='ion-align-items-center ion-justify-content-start'>
          <IonCol size='12' sizeMd='12' sizeLg='5' sizeXl='4'>
            <IonItem lines='none'>
              <IonLabel color='dark'>Fecha desde:</IonLabel>
              <IonDatetimeButton datetime='datetimeStart'></IonDatetimeButton>
              <IonButton color='primary' fill='clear' onClick={()=> clearDateFilter('start')} disabled={!startDatePicked}>
                <IonIcon slot='icon-only' icon={trash}></IonIcon>
              </IonButton>
              <IonModal keepContentsMounted={true} onIonModalDidDismiss={refreshSaldos}>
                <IonDatetime 
                  locale='es-CL'
                  onIonChange={(e)=> onDateChange(e, 'start')}
                  presentation='date'
                  value={
                  startDatePicked
                  ? selectedStartDate.toISOString()
                  : defaultStartDate.toISOString()
                  }
                  id='datetimeStart'></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='5' sizeXl='4'>
            <IonItem lines='none'>
              <IonLabel color='dark'>Fecha hasta:</IonLabel>
              <IonDatetimeButton datetime='datetimeEnd'></IonDatetimeButton>
              <IonButton color='primary' fill='clear' onClick={()=> clearDateFilter('end')} disabled={!EndDatePicked}>
                <IonIcon slot='icon-only' icon={trash}></IonIcon>
              </IonButton>
              <IonModal keepContentsMounted={true} onIonModalDidDismiss={refreshSaldos}>
                <IonDatetime 
                  locale='es-CL'
                  onIonChange={(e)=> onDateChange(e, 'end')}
                  presentation='date'
                  value={
                  EndDatePicked
                  ? selectedEndDate.toISOString()
                  : defaultEndDate.toISOString()
                  }
                  id='datetimeEnd'></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonCard>
      
      {/* Card Tipo de cambio */}
      <IonCard>
        <IonRow class='ion-align-items-left ion-justify-content-left '>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='primary'  icon={statsChartOutline} slot="start"></IonIcon>
              <IonLabel>Tipo de cambio:</IonLabel>
             </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='success'  icon={cashOutline} slot="start"></IonIcon>
              <IonLabel>DOLAR: {cambioUSD}</IonLabel>
             </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='success'  icon={logoEuro} slot="start"></IonIcon>
              <IonLabel>EURO: {cambioEUR}</IonLabel>
             </IonItem>
          </IonCol>
        </IonRow>
      </IonCard>
      
      {/* Tablas */}
      {
        response.length > 0 ? 
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className='bold' color='primary'>Saldos Real vs Extracto SAP</IonCardTitle>
          </IonCardHeader>
            <ReactDataGrid className='rdg-light'  columns={columns_first_table} rows={rows_first_Table} onCellClick={(info)=> onCellClick(info)}/>         
        </IonCard> : 
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonIcon color='danger' icon={fileTrayOutline} slot="end"></IonIcon>
              <IonLabel className='bold'>No hay saldos disponibles</IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard>
      }
      {
        response.length > 0 ? 
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className='bold' color='primary'>Extracto Bancario vs Cuenta Mayor</IonCardTitle>
          </IonCardHeader>
            <ReactDataGrid className='rdg-light'  columns={columns_second_table} rows={rows_second_Table} onCellClick={(info)=> onCellClick(info)}/>
        </IonCard> : <></>
      }
    
      {/* Modal */}
      <IonModal isOpen={isModalOpen} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='10'>
                  <IonText className='bold' color='primary'>
                    Diferencias de saldos y detalles.
                  </IonText>
                </IonCol>
                <IonCol size='2' sizeMd='1' sizeLg='1' sizeXl='1'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setModalIsOpen(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRow>
            <IonCol>
              <IonList lines="inset">
                <IonItem>
                  <IonIcon color='primary' aria-hidden="true" icon={statsChartOutline} slot="start"></IonIcon>
                  <IonLabel>Saldo real: {detailsModal.realBalance}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon color='primary' aria-hidden="true" icon={statsChartOutline} slot="start"></IonIcon>
                  <IonLabel>Saldo en sap: {detailsModal.sapBalance}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon color='primary' aria-hidden="true" icon={storefrontOutline} slot="start"></IonIcon>
                  <IonLabel>Banco: {detailsModal.bank}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon color='primary' aria-hidden="true" icon={cashOutline} slot="start"></IonIcon>
                  <IonLabel>Moneda: {detailsModal.currency}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonIcon color='primary' aria-hidden="true" icon={businessOutline} slot="start"></IonIcon>
                  <IonLabel>Empresa: {detailsModal.company}</IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={isAlertOpen}
        header="¡Celda clickeada no valida!"
        message="¡Para ver la diferencia de saldos, selecciona una celda que contenga Ver diferencia!."
        buttons={['Entiendo']}
        onDidDismiss={() => setAlertIsOpen(false)}
      ></IonAlert>
    </>
    );
    };

    export default VistaControl;


