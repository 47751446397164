import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useAppContext } from '../common/appContext';
import { ScreenTypes } from '../common/reducer';
import ConfirmRegistration from './confirmRegistration';
import Login from './login';
import PasswordRecovery from './passwordRecovery';
import Registration from './registration';
import Welcome from './welcome';

interface Props {
  codigo?: string | null;
  email?: string | null;
}

const Main = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  React.useEffect(() => {});

  let page = <></>;

  switch (appState.screen) {
    case ScreenTypes.Login:
    case ScreenTypes.None:
      if (props.codigo !== undefined) {
        page = (
          <ConfirmRegistration codigo={props.codigo} email={props.email || ''} />
        );
      } else {
        page = <Login />;
      }
      break;
    case ScreenTypes.Registration:
      page = <Registration />;
      break;
    case ScreenTypes.PasswordRecovery:
      page = <PasswordRecovery />;
      break;
    case ScreenTypes.RegistrationConfirmation:
      page = (
        <ConfirmRegistration
          codigo={appState.confirmationCode}
          email={appState.confirmationEmail}
        />
      );
      break;
    default:
      page = <Welcome />;
      break;
  }

  return (
    <>
      <IonGrid class="ion-no-padding ion-no-margin">
        <IonRow class="ion-no-padding ion-no-margin ion-align-items-center">
          <IonCol
            sizeMd="5"
            sizeLg="8"
            class="ion-no-padding ion-no-margin ion-hide-md-down"
            style={{ height: '100vh' }}>
            <Welcome />
          </IonCol>
          <IonCol
            size="12"
            sizeMd="7"
            sizeLg="4"
            class="ion-no-padding ion-no-margin"
            style={{ height: '100vh' }}>
            {page}
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* <section id="loginPage">
				<Welcome />
				{page}
			</section> */}
    </>
  );
};

export default Main;
