import axios from 'axios';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ResponseCache } from '../../interfaces/ResponseCache';
import { EmpresaDetalladaClay } from '../../interfaces/empresaDetallaClay';
import { obtainURL, obtainURLBackendClay } from '../../common/obtainURL';
import { logoutIfUnauthorized, createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes, EmpresasDictionary } from '../../common/reducer';
import{IonCol,IonButton,IonDatetimeButton,IonDatetime,IonRow,IonCard,IonIcon,IonItem,IonLabel,IonSearchbar,IonTitle,IonSelect,IonSelectOption,IonLoading,IonAlert,IonHeader,IonToolbar,IonGrid,IonFooter,IonProgressBar,IonModal,IonContent,}from '@ionic/react';
import {refreshOutline,cloudUploadOutline,saveOutline,closeOutline,bugOutline,trash,search} from 'ionicons/icons';
import { IonDatetimeCustomEvent, DatetimeChangeEventDetail,} from '@ionic/core/components';
import { responseClaySaldos } from '../../interfaces/respondeClaySaldos';

let URL = obtainURLBackendClay();
var test = localStorage.getItem("user");
var tokenClay = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGdydXBvcHNnLm5ldCIsImlhdCI6MTY5NTgzNjQyMywiZXhwIjoxNjk1ODc5NjIzfQ.jBfiIrTfkhNEKr3U3vv0JVPTUJ4LgCxE_N80CijdEbI';

const AperturaEmpresas = () => {
  
    interface Empresa {realName: string;rut: string;}
    const { state: appState, dispatch: appDispatch } = useAppContext();
    const [tableRefreshPending, setTableRefreshPending] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const empresasDefault: Empresa[] = [];
    const [empresas, setEmpresas] = React.useState(empresasDefault);
    var [rowsTable, setRowsTable] = React.useState([]);
    const selectedEmpresaDefault: Empresa | null = null;
    const [selectedEmpresa, setSelectedEmpresa] = React.useState(selectedEmpresaDefault);
    const defaultCache: ResponseCache = { cached: false,response: null,};
    const [responseCache, setResponseCache] = React.useState(defaultCache);
    const [messageResponse, setMessageResponse] = React.useState('');
    const [idTransImport, setIdTransImport] = React.useState('');
    const currentDate = new Date();
    const  defaultStartDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate());
    const [selectedStartDate, setStartDate] = React.useState(defaultStartDate);
    const [startDatePicked, setStartDatePicked] = React.useState(true);
    const [tokenPSG, setTokenPSG] = React.useState('');

    const columns: TableColumn < responseClaySaldos > [] = [
      {name: 'Tipo de Cuenta', selector: (row) => row.accountType, sortable: true, width: '270px',},
      {name: 'PSG',selector: (row) => ''+row.PSG,sortable: true,width: '120px',},
      {name: 'PSG PERU',selector: (row) => ''+row.PSGPERU,sortable: true,width: '140px',},
      {name: 'CIPPP',selector: (row) => ''+row.CIPP,sortable: true,width: '140px',},
      {name: 'BRYSA',selector: (row) => ''+row.BRYSA,sortable: true,width: '140px',},
      {name: 'HIGH FLY',selector: (row) => ''+row.HIGHFLY,sortable: true,width: '140px',},
      {name: 'INMOBILIARIA',selector: (row) => ''+row.INMOBILIARIA,sortable: true,width: '140px',},
      {name: 'TOTAL',selector: (row) => ''+row.groupSum,sortable: true,width: '200px',}
    ];

    React.useEffect(() => {
      const fechaActual = new Date()
      const yyyy = fechaActual.getFullYear()
      const mm = String(fechaActual.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
      const dd = String(fechaActual.getDate()).padStart(2, '0')
      const fechaHasta = `${yyyy}-${mm}-${dd}`
      const tokenPSG = JSON.parse(""+localStorage.getItem("user") ).token;
      console.log("token init ",tokenPSG);
      setTokenPSG( tokenPSG );
      cargaListadoEmpresa(tokenPSG);

      listadoGeneralSaldosClayEmpresas(fechaHasta,"all",tokenPSG);
    },[]);



    function cargaListadoEmpresa( token:string) {
      if (responseCache.cached) return;
      setTableRefreshPending(true);
      let rows: any = [];
      axios
        .get( URL+'/empresas', createHeader(token) )
        .then((response) => {
          setResponseCache({ cached: true, response: response });
          const empresasDictionary: EmpresasDictionary = {};
          response.data.data.response[1].forEach((empresa: Empresa) => {
            rows.push({ rut: empresa.rut, realName: empresa.realName});
            empresasDictionary[empresa.rut] = empresa.realName;
          });
          setEmpresas(rows);
  
          appDispatch({
            type: AppStateReducerActionTypes.SAVE_EMPRESAS,
            empresas_payload: empresasDictionary,
          });
          setTableRefreshPending(false);
        }).catch((error) => {
          setMessageResponse(" Error en autorizacion, por favor vuelve a iniciar sesion: "+error.message+" - method: cargaListadoEmpresa");
          console.error( "Error: " + error);
          setResponseCache({ cached: true,  response: null });
          setTableRefreshPending(false);
           tokenInvalido();
        });
    }

    function listadoGeneralSaldosClayEmpresas(date:string,type:string,token:string ) {
  
      console.log("listadoGeneralSaldosClayEmpresas = "+date+" -"+type);
      if (responseCache.cached) return;
      setTableRefreshPending(true);
      axios.get( URL + '/cuentas/saldos/pivot2'+ prepareGetFilters(type,date), createHeader(token))
        .then((response) => {
          console.log(response);
          console.log("response ***",response.data.data.response.saldos);
          setResponseCache({ cached: true, response: response });
          let rowsTable: any  = [];
          response.data.data.response.saldos.forEach((empresaclayDet: responseClaySaldos) => {
            if( empresaclayDet.accountType === 'TOTAL CORRIENTE'){
              var response: responseClaySaldos = { movimientos: [], id:0, accountType:empresaclayDet.accountType , type: empresaclayDet.accountType,  bankAlias: '', currency:  '',   PSG:  empresaclayDet.PSG, PSGPERU:  empresaclayDet.PSGPERU,CIPP:  empresaclayDet.CIPP,BRYSA:  empresaclayDet.BRYSA,HIGHFLY:  empresaclayDet.HIGHFLY,INMOBILIARIA:  empresaclayDet.INMOBILIARIA,groupSum:  empresaclayDet.groupSum};
              rowsTable.push(response);
            }
          });
          setRowsTable(rowsTable);
          setTableRefreshPending(false);
        }).catch((error) => {

          console.error( "Error: " + error);
          setMessageResponse("Error en autorizacion, por favor vuelve a iniciar sesion:: "+error.message+" - method: listadoGeneralSaldosClayEmpresas");
          setResponseCache({ cached: true,  response: null });
          setTableRefreshPending(false);
          tokenInvalido();

        });
    }

    function findSaldosEmpresas(){

      console.log("empresa: - "+selectedEmpresa);
      const fechaActual = new Date()

      const yyyy = selectedStartDate.getFullYear()
      const mm = String(selectedStartDate.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
      const dd = String(selectedStartDate.getDate()).padStart(2, '0')
      const fechaHasta = `${yyyy}-${mm}-${dd}`
      console.log("fecha actual ",fechaHasta);
      listadoGeneralSaldosClayEmpresas(fechaHasta,"",tokenPSG);
      setResponseCache({ cached: false, response: null });

    }

    function clearCache() {
      setResponseCache({
        cached: false,
        response: null
      });
      setRows([]);
    }

    function tokenInvalido(){
      setTimeout(function(){
        console.log("cerrar sesion");
        localStorage.removeItem('user');
        localStorage.removeItem('screen');
        localStorage.removeItem('sapcredentials');
        appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
      }, 4000);
    }

    
    function prepareGetFilters(type:string, date:string) {
      let filters = '?';
      if(type == 'all'){
        console.log("filter all ",filters+'fecha='+date);
        return filters+'fecha='+date;
      }else{
        if (startDatePicked) { filters += 'fecha=' + date}
        if (selectedEmpresa !== null) {  filters += '&rut=' + selectedEmpresa; }
        console.log(filters);
        return filters;
      }
      
    }

    function onStartDateChange(
      event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
    ) {
      if (event.detail.value) {
        setStartDate(new Date(event.detail.value as string));
        setStartDatePicked(true);
       
      }
    }

    function clearStartDateFilter() {
      setStartDatePicked(false);
    }

    function onSelectChanged(data: any) {
      console.log('data', data.detail.value);
      if (!data) return;
      setSelectedEmpresa(data.detail.value);
     
    }

    const systemStartDate = new Date('2021-12-22');


    return (
    <>
      <IonCard>

        <IonRow class='ion-align-items-left ion-justify-content-left ion-padding'>
          <IonCol size='6' sizeLg='8'>
            <IonTitle color='primary'>
              <IonTitle className='bold'>MONTOS AGRUPADOS: Listado</IonTitle>
            </IonTitle>
          </IonCol>
        </IonRow>

        <IonRow class='ion-align-items-center ion-justify-content-center'>
          <IonCol>
            <IonItem lines='none'>
              <IonLabel position='floating'> Seleccione una empresa</IonLabel>
              <IonSelect interface="action-sheet" onIonChange={onSelectChanged} disabled={empresas.length < 2}>
                <IonSelectOption value={null}>Ninguna</IonSelectOption>
                {empresas.map((empresa) => (
                <IonSelectOption value={empresa.rut} key={empresa.rut}>
                  {empresa.realName}
                </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem lines='none'>
              <IonLabel color='dark'>Fecha:</IonLabel>
              <IonDatetimeButton datetime='datetimeStart'></IonDatetimeButton>
              <IonButton color='primary' fill='clear' onClick={clearStartDateFilter} disabled={!startDatePicked}>
                <IonIcon slot='icon-only' icon={trash}></IonIcon>
              </IonButton>
              <IonModal keepContentsMounted={true}>
                <IonDatetime 
                  locale='es-CL'
                  onIonChange={onStartDateChange}
                  presentation='date'
                  value={
                  startDatePicked
                  ? selectedStartDate.toISOString()
                  : systemStartDate.toISOString()
                  }
                  id='datetimeStart'></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>

   
        </IonRow>

        <IonRow class='ion-align-items-center ion-justify-content-left'>

        <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                expand='block'
                onClick={findSaldosEmpresas}
                >
                <IonIcon slot='start' icon={search}></IonIcon>
                Consultar Saldos
              </IonButton>
          </IonCol>

        <IonCol size='12' sizeLg='2'>
            <IonButton expand='block' color='tertiary' onClick={clearCache}>
              <IonIcon slot='start' icon={refreshOutline}></IonIcon>
              Refrescar
            </IonButton>
          </IonCol>
        </IonRow>


        <IonRow class='ion-align-items-start '>
          <IonCol size='12' sizeLg='12'>
            <h5>{messageResponse}  {idTransImport}</h5>
          </IonCol>
        </IonRow>

        <DataTable  title='Montos Agrupados' data={rowsTable} progressPending={tableRefreshPending} pagination  columns={columns}  />
      </IonCard>
    </>
    );
    };
    export default AperturaEmpresas;