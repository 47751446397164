const titleCaps = (title: string): string => {
	const words = title.split(' ');

	for (var i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
	}

	return words.join(' ');
}

export default titleCaps;