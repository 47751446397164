import axios from 'axios';
import FileSaver from 'file-saver';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { ResponseCache } from '../../interfaces/ResponseCache';
import { FincloudEntry } from '../../interfaces/FincloudEntry';
import { obtainURL } from '../../common/obtainURL';
import { FincloudSap } from '../../interfaces/FincloudSap';
import FincloudSAPTable from '../../fincloud/fincloudsaptable';
import { logoutIfUnauthorized, createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { onConnectionError } from '../../common/connectionerrorhandling';
import { AppStateReducerActionTypes, EmpresasDictionary } from '../../common/reducer';
import {
  IonCol,
  IonButton,
  IonDatetimeButton,
  IonDatetime,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonFooter,
  IonProgressBar,
  IonModal,
  IonContent,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  // IonCheckbox,
} from '@ionic/react';
import {
  refreshOutline,
  cloudUploadOutline,
  saveOutline,
  closeOutline,
  bugOutline,
  fileTrayOutline,
  trash,
} from 'ionicons/icons';
import {
  IonDatetimeCustomEvent,
  DatetimeChangeEventDetail,
} from '@ionic/core/components';
interface Empresa {
  nombre: string;
  cod: string;
}

interface AsientoSAP {
  id: number;
  rendicion: number;
  sap: FincloudSap;
  display: FincloudSap;
  empresa: string;
}

interface ReimportData {
  memo: string,
  error: string,
  nombreEmpresa: string,
  empresa: string,
  id: string,
}

let URL = obtainURL();

const toggle_export_SAP_key = 'FINCLOUD'
const toggle_reimport_key = 'FINCLOUD_REIMPORT'
const Fincloud = () => {
  const columns: TableColumn<FincloudEntry>[] = [
    {
      name: 'No Rendicion',
      selector: (row) => row.IdRendicion,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Fecha',
      selector: (row) => row.FechaAprobacionRendicion,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Área de Negocio',
      selector: (row) => row.AreaDeNegocio,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Centro de Costo',
      selector: (row) => row.CentroDeCosto,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Brigada',
      selector: (row) => row.Brigada,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Línea de Negocio',
      selector: (row) => row.LineaDeNegocio,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Usuario',
      selector: (row) => row.Usuario,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Categoria',
      selector: (row) => row.Categoria,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Fecha doc',
      selector: (row) => row.FechaDocumento,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Tipo doc',
      selector: (row) => row.TipoDocumento,
      sortable: true,
      width: '160px',
    },
    {
      name: 'No doc',
      selector: (row) => row.NumeroDocumento,
      sortable: true,
      width: '140px',
    },
    {
      name: 'Proveedor',
      selector: (row) => row.Proveedor,
      sortable: true,
      width: '300px',
    },
    {
      name: 'Descripción',
      selector: (row) => row.DescripcionGasto,
      sortable: true,
      width: '400px',
    },
    {
      name: 'Monto',
      selector: (row) => row.Monto,
      sortable: true,
      width: '100px',
      right: true,
    },
    {
      name: 'Orden Fabricación',
      selector: (row) => row.OrdenFabricacion,
      sortable: true,
      width: '140px',
    },
    {
      name: 'SAP',
      selector: (row) => row.Sent,
      sortable: true,
    },
    {
      name: 'SAP Error',
      selector: (row) => row.SAPError,
      sortable: true,
      width: '500px',
    },
    {
      name: 'Cod Empresa',
      selector: (row) => row.CodEmpresa,
      sortable: true,
    },
    {
      name: 'Empresa',
      selector: (row) => row.NombreEmpresa,
      sortable: true,
      width: '400px',
    },
  ];

  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [tableRefreshPending, setTableRefreshPending] = React.useState(true);
  const [csvPending, setCSVPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const empresasDefault: Empresa[] = [];
  const [empresas, setEmpresas] = React.useState(empresasDefault);
  const selectedEmpresaDefault: Empresa | null = null;
  const [selectedEmpresa, setSelectedEmpresa] = React.useState(
    selectedEmpresaDefault
  );
  const [exporting, setExporting] = React.useState(false);
  const [sapModal, setSapModal] = React.useState(false);
  const [sapErrorModal, setSapErrorModal] = React.useState(false);
  const [reimportModal, setReimportModal] = React.useState(false);

  const defaultErroresSAP: string[] = []
  const [erroresSAP, setErroresSAP] = React.useState(defaultErroresSAP);

  const defaultReimportData: ReimportData[] = [];
  const [reimport, setReimport] = React.useState(defaultReimportData);
  const [reimportInProgress, setReimportInProgress] = React.useState(false);

  const defaultCache: ResponseCache = {
    cached: false,
    response: null,
  };
  const [responseCache, setResponseCache] = React.useState(defaultCache);
  const [responseSapCache, setResponseSapCache] = React.useState(defaultCache);

  const [sapResultsModal, setSAPResultsModal] = React.useState(false);
  const [sapExportResults, setSAPExportResults] = React.useState('');
  const [theme, setTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

  React.useEffect(() => {
    checkReimportStatus();
    refresh();
    // console.log('empresa', empresas);
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme); // "dark" or "light"
        setTheme(event.matches ? 'dark' : 'light');
      });
  });

  interface BodyParameters {
    [key: string]: any;
  }

  function preparePostFilters() {
    let filters: BodyParameters = {};

    if (startDatePicked) {
      filters['sdate'] = selectedStartDate.toDateString();
    }

    if (endDatePicked) {
      filters['edate='] = selectedEndDate.toDateString();
    }

    filters['empresa'] = selectedEmpresa;

    return filters;
  }

  function prepareGetFilters() {
    let filters = '?t=1';

    if (startDatePicked) {
      filters += '&sdate=' + selectedStartDate.toDateString();
    }

    if (endDatePicked) {
      filters += '&edate=' + selectedEndDate.toDateString();
    }

    if (selectedEmpresa !== null) {
      filters += '&empresa=' + selectedEmpresa;
    }

    return filters;
  }

  function refresh() {
    if (responseCache.cached) return;

    setTableRefreshPending(true);
    setCSVPending(true);

    const filters = prepareGetFilters();

    axios
      .get(URL + '/fincloud' + filters, createHeader(appState.token))
      .then((response) => {
        setResponseCache({ cached: true, response: response });
        setResponseSapCache({ cached: false, response: null });
        let rows: any = [];
        let counter = 0;
        response.data.data.rendiciones.forEach((rendicion: FincloudEntry) => {
          // Conversion de boolean a string, sino no se ve en la tabla
          rendicion.Sent = rendicion.Sent ? 'SI' : 'NO';
          rows.push(rendicion);

          counter++;
          if (rendicion.IdRendicion === 2725) {
            console.log('RENDICION 2725', counter);
          }
        });
        setRows(rows);

        let empresas: Empresa[] = [];

        const empresasDictionary: EmpresasDictionary = {};
        response.data.data.empresas.forEach((empresa: Empresa) => {
          empresas.push({ cod: empresa.cod, nombre: empresa.nombre });
          empresasDictionary[empresa.cod] = empresa.nombre;
        });

        setEmpresas(empresas);
        console.log('EMPRESAS: ', empresas);
        
        if (selectedEmpresa === null) {
          //setSelectedEmpresa('ninguna');
          //todo default IOnic
        }

        appDispatch({
          type: AppStateReducerActionTypes.SAVE_EMPRESAS,
          empresas_payload: empresasDictionary,
        });

        setTableRefreshPending(false);
        setCSVPending(false);
      })
      .catch((error) => {
        console.error(error);
        setResponseCache({ cached: true, response: null });

        const err: FincloudEntry = {
          CodEmpresa: error.code,
          NombreEmpresa: error.message,
          IdRendicion: 0,
          FechaCreacionRendicion: '',
          FechaAprobacionRendicion: '',
          RutUsuario: '',
          Usuario: '',
          Categoria: '',
          FechaDocumento: 0,
          CodTipoDocumento: '',
          TipoDocumento: '',
          NumeroDocumento: '',
          RutProveedor: '',
          Proveedor: '',
          CodTipoGasto: '',
          TipoGasto: '',
          DescripcionGasto: '',
          Monto: 0,
          AreaDeNegocio: '',
          CentroDeCosto: '',
          Brigada: '',
          LineaDeNegocio: '',
          Sent: '',
          SAPError: '',
          OrdenFabricacion: '',
        };

        let rows: any = [];
        rows.push(err);
        setRows(rows);
        setTableRefreshPending(false);
        setCSVPending(false);
        onConnectionError(error, appDispatch);
        logoutIfUnauthorized(error, appDispatch);
      });
  }

  function refreshSAP() {
    setSapModal(true);
    if (responseSapCache.cached) return;
    if (rows.length === 0) return;

    const body = preparePostFilters();
    body.sap = {};

    rows.forEach((item) => {
      const itemFincloud = item as FincloudEntry;
      if (!body.sap[itemFincloud.CodEmpresa]) {
        body.sap[itemFincloud.CodEmpresa] = [];
      }
      body.sap[itemFincloud.CodEmpresa].push((item as FincloudEntry).IdRendicion);
    });

    for (const key in body.sap) {
      // Remover duplicados
      body.sap[key] = [...new Set(body.sap[key])];
    }

    axios
      .post(URL + '/fincloud-sap-preview', body, createHeader(appState.token))
      .then((response) => {
        setResponseSapCache({ cached: true, response: response.data.data.sap });

        setAsientosSAP(response.data.data.sap);

        // TODO apply search
        // TODO export only visible
        appDispatch({ type: AppStateReducerActionTypes.TOGGLE_CLEAR });
        response.data.data.sap.forEach((item: AsientoSAP) => {
          appDispatch({
            type: AppStateReducerActionTypes.TOGGLE_ADD_ITEM,
            toggle_item_payload: { key: toggle_export_SAP_key, empresa: item.empresa, id: item.display.Reference },
          });
        });
        appDispatch({ type: AppStateReducerActionTypes.TOGGLE_TRUE });
      })
      .catch((error) => {
        console.error(error);
        setResponseSapCache({ cached: true, response: null });

        logoutIfUnauthorized(error, appDispatch);
      });
  }

  function clearCache() {
    setResponseCache({ cached: false, response: null });
    setResponseSapCache({ cached: false, response: null });
    setAsientosSAP([]);
    setRows([]);
  }

  function convertToCSV(rendiciones: FincloudEntry[]) {
    const separator: string = ';';
    let csv = '';

    csv += 'CodEmpresa' + separator;
    csv += 'NombreEmpresa' + separator;
    csv += 'IdRendicion' + separator;
    csv += 'AreaDeNegocio' + separator;
    csv += 'CentroDeCosto' + separator;
    csv += 'Brigada' + separator;
    csv += 'LineaDeNegocio' + separator;
    csv += 'Usuario' + separator;
    csv += 'Categoria' + separator;
    csv += 'FechaDocumento' + separator;
    csv += 'TipoDocumento' + separator;
    csv += 'NumeroDocumento' + separator;
    csv += 'Proveedor' + separator;
    csv += 'DescripcionGasto' + separator;
    csv += 'Monto' + separator;
    // csv += 'Estado' + separator;
    csv += '\n';

    rendiciones.forEach((rendicion: FincloudEntry) => {
      csv += (rendicion.CodEmpresa || '') + separator;
      csv += (rendicion.NombreEmpresa || '') + separator;
      csv += (rendicion.IdRendicion || '') + separator;
      csv += (rendicion.AreaDeNegocio || '') + separator;
      csv += (rendicion.CentroDeCosto || '') + separator;
      csv += (rendicion.Brigada || '') + separator;
      csv += (rendicion.LineaDeNegocio || '') + separator;
      csv += (rendicion.Usuario || '') + separator;
      csv += (rendicion.Categoria || '') + separator;
      csv += (rendicion.FechaDocumento || '') + separator;
      csv += (rendicion.TipoDocumento || '') + separator;
      csv += (rendicion.NumeroDocumento || '') + separator;
      csv += (rendicion.Proveedor || '') + separator;
      csv += (rendicion.DescripcionGasto || '') + separator;
      csv += (rendicion.Monto || '') + separator;
      // csv += (rendicion.Estado || '') + separator;
      csv += '\n';
    });

    return csv;
  }

  function saveCSV() {
    if (!responseCache.cached) return;

    setCSVPending(true);
    const data = convertToCSV(responseCache.response.data.data.rendiciones);
    const file = new File([data], 'fincloud.csv', {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(file);
    setCSVPending(false);
  }

  const currentDate = new Date();
  const defaultStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const [selectedStartDate, setStartDate] = React.useState(defaultStartDate);
  const [startDatePicked, setStartDatePicked] = React.useState(true);

  function onStartDateChange(
    event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ) {
    if (event.detail.value) {
      setStartDate(new Date(event.detail.value as string));
      setStartDatePicked(true);
    }
  }

  function clearStartDateFilter() {
    setStartDatePicked(false);
  }

  const [selectedEndDate, setEndDate] = React.useState(new Date());
  const [endDatePicked, setEndDatePicked] = React.useState(false);

  function onEndDateChange(
    event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
  ) {
    if (event.detail.value) {
      setEndDate(new Date(event.detail.value as string));
      setEndDatePicked(true);
    }
  }

  function clearEndDateFilter() {
    setEndDatePicked(false);
  }

  function onSelectChanged(data: any) {
    //console.log('data', data);
    if (!data) return;
    setSelectedEmpresa(data.detail.value);
    clearCache()
  }

  const asientosSAPDefault: AsientoSAP[] = [];
  const [asientosSAP, setAsientosSAP] = React.useState(asientosSAPDefault);

  function onSAPCSV() {
    if (!responseSapCache.cached) return;

    const data = convertSAPToCSV(responseSapCache.response);
    const file = new File([data], 'fincloudSAP.csv', {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(file);
  }

  function convertSAPToCSV(asientos: AsientoSAP[]) {
    const separator: string = ';';
    let csv = '';

    csv += 'Asiento' + separator;
    csv += 'Rendicion' + separator;
    csv += 'Line_ID' + separator;
    csv += 'AccountCode' + separator;
    csv += 'DueDate' + separator;
    csv += 'Debit' + separator;
    csv += 'Credit' + separator;
    csv += 'ShortName' + separator;
    csv += 'LineMemo' + separator;
    csv += 'ReferenceDate1' + separator;
    csv += 'Reference1' + separator;
    csv += 'Reference2' + separator;
    csv += 'CostingCode1' + separator;
    csv += 'CostingCode2' + separator;
    csv += 'CostingCode3' + separator;
    csv += 'CostingCode4' + separator;
    csv += 'FederalTaxID' + separator;
    csv += '\n';

    asientos.forEach((asiento: AsientoSAP) => {
      asiento.sap.JournalEntryLines.forEach((register) => {
        csv += (asiento.id || '') + separator;
        csv += (asiento.rendicion || '') + separator;

        csv += (register.Line_ID || '') + separator;
        csv += (register.AccountCode || '') + separator;
        csv += (register.DueDate || '') + separator;
        csv += (register.Debit || '') + separator;
        csv += (register.Credit || '') + separator;
        csv += (register.ShortName || '') + separator;
        csv += (register.LineMemo || '') + separator;
        csv += (register.ReferenceDate1 || '') + separator;
        csv += (register.Reference1 || '') + separator;
        csv += (register.Reference2 || '') + separator;
        csv += (register.CostingCode || '') + separator;
        csv += (register.CostingCode2 || '') + separator;
        csv += (register.CostingCode3 || '') + separator;
        csv += (register.CostingCode4 || '') + separator;
        csv += (register.FederalTaxID || '') + separator;
        csv += '\n';
      });
    });

    return csv;
  }

  function searchKey(event: any) {
    if (!responseCache.cached) return;

    if (event.key === 'Enter') {
      search(event.target.value);
    }
  }

  function searchChanged(event: any) {
    if (!responseCache.cached) return;
    search(event.target.value);
  }

  function search(text: string) {
    const keywords = text.toLowerCase().split(' ');
    let rows: any = [];
    responseCache.response.data.data.rendiciones.forEach(
      (rendicion: FincloudEntry) => {
        const rendicionText = JSON.stringify(rendicion).toLowerCase();

        let found: boolean = true;
        keywords.forEach((keyword) => {
          if (!rendicionText.includes(keyword)) {
            found = false;
            return;
          }
        });

        if (found) {
          rows.push(rendicion);
        }
      }
    );
    setRows(rows);
  }

  function exportSAP() {
    if (!appState.sapCredentials) {
      appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'No hay credenciales SAP configuradas' });
      return;
    }
    //const selection = Object.keys(appState.toggleDictionary);
    const rendiciones: any = {};
    /*selection.forEach(rendition => {
      if (appState.toggleDictionary[rendition]) {
        rendiciones.push(rendition);
      }
    })*/

    const dict = appState.toggleDictionaries[toggle_export_SAP_key];
    for (const empresa in dict) {
      for (const rendicion in dict[empresa]) {
        if (dict[empresa][rendicion]) {
          if (!rendiciones[empresa]) {
            rendiciones[empresa] = [];
          }
          rendiciones[empresa].push(parseInt(rendicion));
        }
      }
    }

    if (rendiciones.length < 1) return;

    const body = { sap: rendiciones };
    setSapModal(false);
    setSAPResultsModal(true);
    setExporting(true);
    setSAPExportResults('');
    axios
      .post(URL + '/fincloud-sap-export', body, createHeader(appState.token))
      .then((result) => {
        console.log(result);

        setSAPResultsModal(true);

        // const message: JSX.Element = (
        //   <div>
        //     {result.data.data.message}
        //     <br />
        //     {result.data.data.ids ? (
        //       <div>Rendiciones con error: {result.data.data.ids.join(',')}</div>
        //     ) : (
        //       <></>
        //     )}
        //   </div>
        // );

        // setSAPExportResults(message);
        result.data.data.ids
          ? setSAPExportResults(
            `${result.data.data.message
            }  Rendiciones con error: ${result.data.data.ids.join(',')}`
          )
          : setSAPExportResults(result.data.data.message);
      })
      .catch((error) => {
        console.error(error);

        const message = error.response.data?.data?.errorMessage
          ? error.response.data.data.errorMessage
          : error.response.data?.error
            ? error.response.data.error
            : error.message;

        setSAPResultsModal(false);
        appDispatch({
          type: AppStateReducerActionTypes.ERROR_ALERT,
          error_payload: message,
        });
      })
      .finally(() => {
        setExporting(false);
      });
  }

  function toggleAll(event: React.ChangeEvent<HTMLInputElement>) {
    //function toggleAll(event: any) {
    appDispatch({
      type: AppStateReducerActionTypes.TOGGLE_ALL,
      //toggle_all_payload: event.detail.checked,
      toggle_all_payload: { key: toggle_export_SAP_key, value: event.target.checked },
    });
  }

  function toggleReimportAll(event: React.ChangeEvent<HTMLInputElement>) {
    appDispatch({
      type: AppStateReducerActionTypes.TOGGLE_ALL,
      toggle_all_payload: { key: toggle_reimport_key, value: event.target.checked },
    });
  }

  function showSapErrors() {
    if (!responseCache.cached) return;

    interface sapError {
      [key: string]: {
        error: string,
        empresa: string,
      };
    }

    const erroresDict: sapError = {};
    responseCache.response.data.data.rendiciones.forEach((item: any) => {
      if (item.SAPError && item.SAPError.length > 0) {
        erroresDict[item.IdRendicion] = {
          error: item.SAPError,
          empresa: item.NombreEmpresa
        }
      }
    });

    const erroresArray = [];
    for (const error in erroresDict) {
      erroresArray.push(`[${error}] ${erroresDict[error].empresa}: Error: ${erroresDict[error].error}`);
    }

    setErroresSAP(erroresArray);
    setSapErrorModal(true);
  }

  async function openReimportModal() {
    if (!responseCache.cached || (responseCache.cached && !responseCache.response)) return;

    interface ReimportDataDict {
      [key: string]: ReimportData,
    }

    if (reimportInProgress) {
      setReimportModal(true);
      return
    }

    setReimportInProgress(true);
    appDispatch({ type: AppStateReducerActionTypes.TOGGLE_CLEAR, toggle_payload: { key: toggle_reimport_key } });

    const reimportDict: ReimportDataDict = {}
    const reimportArray: ReimportData[] = [];
    responseCache.response.data.data.rendiciones.forEach((item: any) => {
      const reimportItem: ReimportData = {
        memo: item.Usuario,
        error: item.SAPError,
        nombreEmpresa: item.NombreEmpresa,
        empresa: item.CodEmpresa,
        id: item.IdRendicion,
      }

      reimportDict[item.IdRendicion] = reimportItem;
    });

    for (const reimportItem in reimportDict) {
      //const error = reimportDict[reimport].error.length > 0 ? `Error: ${reimportDict[reimport].error}` : '';
      //reimportArray.push(`[${reimport}]: ${reimportDict[reimport].memo} ${reimportDict[reimport].nombreEmpresa} ${error}`);
      reimportArray.push(reimportDict[reimportItem]);

      appDispatch({
        type: AppStateReducerActionTypes.TOGGLE_ADD_ITEM,
        toggle_item_payload: { key: toggle_reimport_key, empresa: reimportDict[reimportItem].empresa, id: reimportDict[reimportItem].id },
      });

    }
    appDispatch({ type: AppStateReducerActionTypes.TOGGLE_TRUE, toggle_payload: { key: toggle_reimport_key } });
    setReimport(reimportArray);
    setReimportModal(true);
    checkReimportStatus();
  }

  async function checkReimportStatus() {
    try {
      const result = await axios.get(URL + '/fincloud-refresh-selective-check-status', createHeader(appState.token));
      console.log(result);

      const processing = result.data.data.processing;
      setReimportInProgress(processing);

      if (processing) {
        setTimeout(() => {
          checkReimportStatus();
        }, 5000);
      }
    } catch (e: any) {
      console.error(e.message)
    }
  }

  async function reimportRendiciones() {
    console.log('REIMPORTING');

    const rendiciones: any = {};

    const cached = responseCache.response.data.data.rendiciones
    const dict = appState.toggleDictionaries[toggle_reimport_key];
    let dates = []
    for (const empresa in dict) {
      for (const rendicion in dict[empresa]) {
        if (dict[empresa][rendicion]) {
          if (!rendiciones[empresa]) {
            rendiciones[empresa] = [];
          }
          rendiciones[empresa].push(parseInt(rendicion));
          const foundDate = cached.find((item: any) =>
            item.IdRendicion.toString() === rendicion
          )
          if (foundDate) {
            dates.push(foundDate.FechaAprobacionRendicion)
          }
        }
      }
    }

    if (Object.keys(rendiciones).length < 1) {
      appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'No hay rendiciones seleccionadas' });
      return;
    }


    if (dates.length < 1) {
      appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'Las rendiciones no contienen Fecha de Aprobación' });
      return;
    }

    dates = dates.sort();

    const dateRange = {
      startDate: dates[0],
      endDate: dates[dates.length - 1]
    };

    setReimportInProgress(true);
    const body = { rendiciones, dateRange };

    console.log(body);

    axios
      .post(URL + '/fincloud-refresh-selective-start', body, createHeader(appState.token))
      .then((result) => {
        console.log(result);
        checkReimportStatus();
      })
      .catch((error) => {
        console.error(error);

        const message = error.response.data?.data?.errorMessage
          ? error.response.data.data.errorMessage
          : error.response.data?.error
            ? error.response.data.error
            : error.message;

        setSAPResultsModal(false);
        appDispatch({
          type: AppStateReducerActionTypes.ERROR_ALERT,
          error_payload: message,
        });
        setReimportInProgress(false);
      })
  }

  async function cancelReimportRendiciones() {
    console.log('CANCEL REIMPORT');

    const result = await axios.post(URL + '/fincloud-refresh-selective-cancel', {}, createHeader(appState.token));
    console.log(result);

    const processing = result.data.data.processing;
    setReimportInProgress(processing);
  }

  const systemStartDate = new Date('2021-12-22');
  // {asientosSAP.map((asiento, index) => { return (<FincloudSAPTable key={index} title={'Asiento ' + asiento.id + ' / REND.' + asiento.rendicion} rows={asiento.registers} />) })};
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Exportar FinCloud</IonCardTitle>
        </IonCardHeader>
        <IonCardContent> 
          <IonRow class='ion-align-items-center ion-justify-content-center'>
            <IonCol>
              <IonItem lines='none'>
                <IonLabel color='dark'>Desde:</IonLabel>
                <IonDatetimeButton datetime='datetimeStart'></IonDatetimeButton>
                <IonButton
                  color='primary'
                  fill='clear'
                  onClick={clearStartDateFilter}
                  disabled={!startDatePicked}>
                  <IonIcon slot='icon-only' icon={trash}></IonIcon>
                </IonButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    // onIonCancel={(e) => console.log(e)}
                    locale='es-CL'
                    onIonChange={onStartDateChange}
                    presentation='date'
                    value={
                      startDatePicked
                        ? selectedStartDate.toISOString()
                        : systemStartDate.toISOString()
                    }
                    id='datetimeStart'></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>

            <IonCol>
              <IonItem lines='none'>
                <IonLabel color='dark'>Hasta:</IonLabel>
                <IonDatetimeButton datetime='datetimeEnd'></IonDatetimeButton>
                <IonButton
                  color='primary'
                  fill='clear'
                  onClick={clearEndDateFilter}
                  disabled={!endDatePicked}>
                  <IonIcon slot='icon-only' icon={trash}></IonIcon>
                </IonButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    // onIonCancel={(e) => console.log(e)}
                    locale='es-CL'
                    onIonChange={onEndDateChange}
                    presentation='date'
                    value={endDatePicked ? selectedEndDate.toISOString() : ''}
                    id='datetimeEnd'></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>

            <IonCol>
              <IonItem lines='none'>
                <IonLabel position='floating'> Seleccione una empresa</IonLabel>
                <IonSelect
                  interface="popover"
                  onIonChange={onSelectChanged}
                  disabled={empresas.length < 2}>
                  <IonSelectOption value={null}>Ninguna</IonSelectOption>
                  {empresas.map((empresa) => (
                    <IonSelectOption value={empresa.cod} key={empresa.cod}>
                      {empresa.nombre}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow class='ion-align-items-center ion-justify-content-between'>
            
              <IonButton
                expand='block'
                onClick={saveCSV}
                disabled={csvPending || rows.length === 0}>
                <IonIcon slot='start' icon={saveOutline}></IonIcon>
                Descargar como CSV
              </IonButton>
            
          
              <IonButton
                expand='block'
                onClick={refreshSAP}
                disabled={rows.length === 0}>
                <IonIcon slot='start' icon={cloudUploadOutline}></IonIcon>
                Exportar a SAP
              </IonButton>
            
            
              <IonButton
                fill='outline'
                color='secondary'
                expand='block'
                onClick={showSapErrors}
                disabled={rows.length === 0}
              >
                <IonIcon slot='start' icon={bugOutline}></IonIcon>
                Errores SAP
              </IonButton>
            
            
              <IonButton
                fill='outline'
                color='secondary'
                expand='block'
                onClick={openReimportModal}
                disabled={rows.length === 0}
              >
                <IonIcon slot='start' icon={refreshOutline}></IonIcon>
                Reimportar
              </IonButton>

               <IonButton
                expand='block'
                color='success'
                onClick={clearCache}
              // disabled={rows.length === 0}
              >
                <IonIcon slot='start' icon={refreshOutline}></IonIcon>
                Refrescar listado
              </IonButton>
          
          </IonRow>
          <IonRow class='ion-align-items-center ion-justify-content-start'>
            <IonCol size='12' sizeSm='12' sizeLg='6' sizeXl='5'>
              <IonItem lines='none' class='ion-no-padding'>
                <IonSearchbar
                  type='text'
                  placeholder='Buscar por Nro de rendición'
                  showCancelButton='focus'
                  onIonChange={(e) => searchChanged(e)}
                  onKeyDown={searchKey}></IonSearchbar>
              </IonItem>
            </IonCol>
          </IonRow>
          <DataTable
            key={1}
            title=''
            columns={columns}
            data={rows}
            progressPending={tableRefreshPending}
            pagination
            dense
            striped
          // theme={theme}
          />
        </IonCardContent>
      </IonCard>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={sapResultsModal && exporting}
        message={'Exportando...'}
        duration={5000}
      />
      <IonAlert
        isOpen={sapResultsModal && !exporting}
        header={sapExportResults}
        buttons={['OK']}
        onDidDismiss={clearCache}
      />
      <IonModal isOpen={sapModal}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Exportar a SAP
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setSapModal(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        {responseSapCache.cached ? (
          <></>
        ) : (
          <IonProgressBar type='indeterminate'></IonProgressBar>
        )}

        <IonContent>
          <IonRow>
            <IonCol>
              <IonItem lines='none'>
                <label>
                  <input type="checkbox" onChange={toggleAll} checked={appState.toggleAllSet[toggle_export_SAP_key]} />
                  Todos / Ninguno
                </label>
              </IonItem>
            </IonCol>
          </IonRow>
          {asientosSAP.map((asiento, index) => {
            return (
              <div key={index}>
                <FincloudSAPTable
                  keyValue={asiento.display.Reference}
                  showToggle={true}
                  title={
                    'Asiento ' +
                    asiento.id +
                    ' / ' +
                    asiento.display.Memo +
                    ' / ' +
                    appState.empresas[asiento.empresa]
                  }
                  sapRegister={asiento.display}
                  empresa={asiento.empresa}
                />
              </div>
            );
          })}
        </IonContent>
        <IonFooter>
          <IonRow class='ion-justify-content-end ion-align-items-center'>
            <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='outline'
                size='default'
                color='primary'
                expand='block'
                onClick={onSAPCSV}
                disabled={!responseSapCache.cached}>
                Exportar SAP a CSV
              </IonButton>
            </IonCol>
            <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                expand='block'
                onClick={exportSAP}>
                <IonIcon slot='start' icon={cloudUploadOutline}></IonIcon>
                Exportar a SAP
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonModal isOpen={sapErrorModal}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Errores en SAP
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setSapErrorModal(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {erroresSAP.length > 0 ? erroresSAP.map((asiento, index) => {
            return (
              <ul key={index}>
                <li>{asiento}</li>
              </ul>
            );
          })
            :
            <IonCard>
              <IonCardContent>
                <IonItem>
                  <IonIcon color='danger' icon={fileTrayOutline} slot="end"></IonIcon>
                  <IonLabel className='bold'>No hay errores en SAP</IonLabel>
                </IonItem>
              </IonCardContent>
        </IonCard>
          }
        </IonContent>
      </IonModal>
      <IonModal isOpen={reimportModal}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Reimportar Rendiciones Individuales
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setReimportModal(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel >
                    Para actualizar si hubo cambios en Fincloud o SAP que necesitan reflejarse en rendiciones que ya fueron importadas previamente
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>

        </IonHeader>
        <IonContent>
          {reimport.length > 0 ?
            <IonRow key='irc'>
              <IonItem>
                <label>
                  <input type="checkbox" onChange={toggleReimportAll} checked={appState.toggleAllSet[toggle_reimport_key]} disabled={reimportInProgress} />
                  Todos / Ninguno
                </label>
              </IonItem>
            </IonRow>
            :
            <></>
          }
          {reimport.length > 0 ? reimport.map((asiento, index) => {
            return (
              <>

                <IonRow key={index + 'ir'}>
                  <IonItem key={index + 'ii'}>
                    <label key={index + 'lb'}>
                      <input type="checkbox" key={index + 'cb'} checked={appState.toggleDictionaries[toggle_reimport_key][asiento.empresa][asiento.id]}
                        className="pepe"
                        disabled={reimportInProgress}
                        onChange={
                          (event) => {
                            appDispatch({
                              type: event.target.checked
                                ? AppStateReducerActionTypes.TOGGLE_ADD_ITEM
                                : AppStateReducerActionTypes.TOGGLE_REMOVE_ITEM,
                              toggle_item_payload: { key: toggle_reimport_key, empresa: asiento.empresa, id: asiento.id },
                            })
                          }
                        }
                      />
                      [{asiento.id}] {asiento.nombreEmpresa} {asiento.error}
                    </label>
                  </IonItem>
                </IonRow>
              </>
            );
          })
            :
            <IonRow key='irn'>
              <IonItem>
                <div>No hay rendiciones en el listado</div>
              </IonItem>
            </IonRow>
          }
        </IonContent>
        <IonFooter>
          {!reimportInProgress ? (
            <></>
          ) : (
            <IonProgressBar type='indeterminate'></IonProgressBar>
          )}
          <IonRow class='ion-justify-content-end ion-align-items-center'>
            {reimportInProgress ?
              <IonCol size='3' sizeLg='3'>
                <IonButton
                  fill='outline'
                  size='default'
                  color='tertiary'
                  expand='block'
                  onClick={cancelReimportRendiciones}
                >
                  <IonIcon slot='start' icon={trash}></IonIcon>
                  CANCELAR
                </IonButton>
              </IonCol>
              :
              <></>
            }
            <IonCol size='5' sizeLg='5'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                expand='block'
                disabled={reimportInProgress}
                onClick={reimportRendiciones}
              >
                <IonIcon slot='start' icon={refreshOutline}></IonIcon>
                Reimportar Rendiciones Seleccionadas
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default Fincloud;
