import axios from 'axios';
import React, { FormEvent } from 'react';
import { useAppContext } from '../../common/appContext';
import { obtainURL } from '../../common/obtainURL';
import { AppStateReducerActionTypes } from '../../common/reducer';
import {
  IonText,
  IonCol,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonCardContent,
  IonTitle,
  useIonToast,
} from '@ionic/react';
import {
  arrowUndoOutline,
  checkmarkDoneOutline,
  personAddOutline,
} from 'ionicons/icons';
import { createHeader } from '../../common/auth';
let URL = obtainURL();

const EditUserAdmin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [asyncState, setAsyncState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [present] = useIonToast();

  const presentToast = (position: 'top' | 'middle' | 'bottom') => {
    present({
      message: 'Credenciales guardadas exitosamente',
      duration: 3500,
      position: position,
      color: 'success',
      icon: checkmarkDoneOutline,
    });
  };

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrorMessage('');
    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (formDictionary.usuarioSAP.toString().length < 1) {
      setErrorMessage('Ingrese un usuario');
    } else if (formDictionary.password.toString().length < 1) {
      setErrorMessage('Ingrese una contraseña');
    } else if (formDictionary.password.toString() !== formDictionary.password2.toString()) {
      setErrorMessage('Las contraseñas no coinciden');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/user-sap-credentials', {
          email: appState.email,
          sap_email: email,
          sap_user: formDictionary.usuarioSAP,
          sap_password: formDictionary.password,
        },
          createHeader(appState.token))
        .then((result) => {
          setAsyncState(false);
          presentToast('bottom');
          appDispatch({
            type: AppStateReducerActionTypes.SET_SAP_CREDENTIALS_STATUS,
            sap_credentials_payload: true,
          });
        })
        .catch((reason) => {
          setAsyncState(false);
          console.error('reason', reason);
          setErrorMessage(
            'Error guardando credenciales SAP: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function back() {
    appDispatch({ type: AppStateReducerActionTypes.SHOW_USER_ADMIN });
  }

  function resetError() {
    setErrorMessage('');
  }

  const email: string = appState.sapCredentialsEmail && appState.sapCredentialsEmail.length > 0 ?
    appState.sapCredentialsEmail
    :
    appState.email;

  return (
    <IonCard>
      <IonRow class='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
        <IonCol size='12' sizeLg='5'>
          <IonTitle color='primary'>
            <h2 className='bold'>Credenciales SAP - {email}</h2>
          </IonTitle>
        </IonCol>
        {
          appState.sapCredentialsEmail && appState.sapCredentialsEmail.length > 0 ?
            <IonCol size='12' sizeLg='3'>
              <IonButton
                color='success'
                fill='solid'
                expand='block'
                size='default'
                shape='round'
                onClick={back}>
                <IonIcon slot='start' icon={arrowUndoOutline}></IonIcon>
                Regresar
              </IonButton>
            </IonCol>
            :
            <></>
        }
      </IonRow>

      <IonCardContent>
        <form onSubmit={formSubmit}>
          <IonRow class='ion-justify-content-start ion-align-items-center'>
            <IonCol size='12' sizeLg='4'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Usuario SAP
                </IonLabel>
                <IonInput type='text' name='usuarioSAP'></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='4'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Password
                </IonLabel>
                <IonInput
                  type='password'
                  name='password'
                  onClick={resetError}></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='4'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Repita Password
                </IonLabel>
                <IonInput
                  type='password'
                  name='password2'
                  onClick={resetError}></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow class='ion-align-items-end ion-margin-top'>
            <IonCol size='12' class='ion-justify-content-center ion-wrap'>
              <IonButton
                expand='block'
                type='submit'
                disabled={asyncState}
                color='primary'>
                <IonIcon slot='start' icon={personAddOutline}></IonIcon>
                Guardar Credenciales
              </IonButton>

              <IonText color='danger' className='bold'>
                {errorMessage}
              </IonText>
            </IonCol>
          </IonRow>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default EditUserAdmin;
