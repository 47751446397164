import axios from 'axios';
import FileSaver from 'file-saver';
import React, { useCallback, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BukEntry } from '../../interfaces/BukEntry';
import { obtainURL } from '../../common/obtainURL';
import { ResponseCache } from '../../interfaces/ResponseCache';
import BukSAPTable from '../../buk/buksaptable';
import { BukSap } from '../../interfaces/BukSap';
import { logoutIfUnauthorized, createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { onConnectionError } from '../../common/connectionerrorhandling';
import { AppStateReducerActionTypes } from '../../common/reducer';
import {
  IonProgressBar,
  IonSearchbar,
  IonCol,
  useIonLoading,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonTitle,
  IonGrid,
  IonModal,
  IonHeader,
  IonToolbar,
  IonFooter,
  IonToast,
  IonContent,
  IonAlert,
  IonLabel,
  IonDatetime,
  IonLoading,
  IonChip,
  IonDatetimeButton,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';
import {
  IonDatetimeCustomEvent,
  DatetimeChangeEventDetail,
} from '@ionic/core/components';
import {
  closeOutline,
  cloudUploadOutline,
  saveOutline,
  alertCircleOutline,
  cloudDownloadOutline,
  checkmarkCircleOutline,
  //refreshOutline,
  bugOutline,
  // trash,
} from 'ionicons/icons';

interface Empresa {
  entries: BukEntry[];
  sap: BukSap[];
  balanced: boolean;
  razon_social: string;
  rut: string;
  sapError: any;
  JdtNum: string;
}

interface AsientoSAP {
  id: number;
  empresa: string;
  registers: BukSap[];
  rut: string,
}

let URL = obtainURL();

const toggle_export_SAP_key = 'BUK'
const Buk = () => {
  const columns: TableColumn<BukEntry>[] = [
    {
      name: 'Razon Social',
      selector: (row) => row.razon_social,
      width: '340px',
      sortable: true,
    },
    {
      name: 'Account Code',
      selector: (row) => row.account_code,
      sortable: true,
      width: '180px',
    },
    {
      name: 'Debit',
      selector: (row) => row.debit,
      sortable: true,
    },
    {
      name: 'Credit',
      selector: (row) => row.credit,
      sortable: true,
    },
    {
      name: 'Line Memo',
      selector: (row) => row.line_memo,
      sortable: true,
      width: '380px'
    },
    {
      name: 'Reference',
      selector: (row) => row.reference_1,
      sortable: true,
    },
    {
      name: 'Reference 2',
      selector: (row) => row.reference_2,
      sortable: true,
    },
    {
      name: 'Cousting code',
      selector: (row) => row.cousting_code,
      sortable: true,
    },
    {
      name: 'Cousting code 2',
      selector: (row) => row.cousting_code2,
      sortable: true,
    },
    {
      name: 'Cousting code 3',
      selector: (row) => row.cousting_code3,
      sortable: true,
    },
    {
      name: 'Cousting code 4',
      selector: (row) => row.cousting_code4,
      sortable: true,
    },
    {
      name: 'Estado',
      selector: (row) => row.sap_error,
      sortable: true,
      width: '500px',
    },
  ];

  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [present, dismiss] = useIonLoading();
  const [tableRefreshPending, setTableRefreshPending] = React.useState(false);
  const [csvPending, setCSVPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenTwo, setIsOpenTwo] = React.useState(false);
  const [isOpenThree, setIsOpenThree] = React.useState(false);
  const defaultCache: ResponseCache = {
    cached: false,
    response: null,
  };
  const [responseCache, setResponseCache] = React.useState(defaultCache);

  const [exporting, setExporting] = React.useState(false);
  const [sapModal, setSapModal] = React.useState(false);
  const [sapErrorModal, setSapErrorModal] = React.useState(false);

  const defaultErroresSAP: string[] = []
  const [erroresSAP, setErroresSAP] = React.useState(defaultErroresSAP);

  const [sapResultsModal, setSAPResultsModal] = React.useState(false);
  const [sapExportResults, setSAPExportResults] = React.useState('');

  const [selectedDate, setDate] = React.useState(null as unknown as Date);
  const [datePicked, setDatePicked] = React.useState(false);
  const [firstLoad, setFirtLoad] = React.useState(false);
  const [actualDate, setActualDate] = React.useState(true)
  const getFilters = useCallback(() => {
    let filters = '';
    console.log(datePicked, selectedDate);

    if (datePicked) {
      filters += '?month=' + (selectedDate.getMonth() + 1);
      filters += '&year=' + selectedDate.getFullYear();
    }

    console.log(filters);
    return filters;
  }, [datePicked, selectedDate]);

  function convertToCSV(empresa: Empresa) {
    const separator: string = ';';
    let csv = '';

    csv += 'ParentKey' + separator;
    csv += 'LineNum' + separator;
    csv += 'AccountCode' + separator;
    csv += 'Debit' + separator;
    csv += 'Credit' + separator;
    csv += 'LineMemo' + separator;
    csv += 'Reference1' + separator;
    csv += 'Reference2' + separator;
    csv += 'CostingCode' + separator;
    csv += 'CostingCode2' + separator;
    csv += 'CostingCode3' + separator;
    csv += 'CostingCode4' + separator;
    csv += '\n';

    csv += 'JdtNum' + separator;
    csv += 'LineNum' + separator;
    csv += 'Account' + separator;
    csv += 'Debit' + separator;
    csv += 'Credit' + separator;
    csv += 'LineMemo' + separator;
    csv += 'Ref1' + separator;
    csv += 'Ref2' + separator;
    csv += 'ProfitCode' + separator;
    csv += 'OcrCode2' + separator;
    csv += 'OcrCode3' + separator;
    csv += 'OcrCode4' + separator;
    csv += '\n';

    empresa.entries.forEach((item) => {
      csv += item.parent_key + separator;
      csv += item.line_num + separator;
      csv += item.account_code + separator;
      csv += item.debit + separator;
      csv += item.credit + separator;
      csv += (item.line_memo || '') + separator;
      csv += (item.reference_1 || '') + separator;
      csv += (item.reference_2 || '') + separator;
      csv += (item.cousting_code || '') + separator;
      csv += (item.cousting_code2 || '') + separator;
      csv += (item.cousting_code3 || '') + separator;
      csv += (item.cousting_code4 || '') + separator;
      csv += '\n';
    });

    return csv;
  }

  function saveCSV() {
    if (!responseCache.cached) return;
    if (rows.length === 0) return;

    setCSVPending(true);

    for (const key in responseCache.response.data.data[0].periodo) {
      const empresa: Empresa = responseCache.response.data.data[0].periodo[key];
      const data = convertToCSV(empresa);
      const file = new File([data], empresa.razon_social + '.csv', {
        type: 'text/plain;charset=utf-8',
      });
      FileSaver.saveAs(file);
    }
    setCSVPending(false);
  }

  function onClickMonth(event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) {
     console.log(event.detail.value);
     setActualDate(checkActualDate(event.detail.value)) //Verifica si la fecha seteada es la actual o no.
    if (event.detail.value) {
      setDate(new Date(event.detail.value as string));
      // setDatePicked(true);
    }
  }

  // function clearDateFilter() {
  //   setDatePicked(false);
  //   setRows([]);
  //   setAsientosSAP(asientosSAPDefault);
  // }

  const asientosSAPDefault: AsientoSAP[] = useMemo(() => {
    return [];
  }, []);

  const [asientosSAP, setAsientosSAP] = React.useState(asientosSAPDefault);

  function onCreateSAP() {
    setSapModal(true);
    if (!responseCache.cached) return;

    setAsientosSAP(convertToSAP(responseCache.response.data.data));
  }

  function convertToSAP(data: any) {
    const asientos: AsientoSAP[] = [];
    let idAsiento = 1;

    for (const item of data) {
      if (item.sent) continue
      const empresa: Empresa = item.periodo as Empresa;
      asientos.push({
        empresa: empresa.razon_social,
        id: idAsiento++,
        registers: empresa.sap,
        rut: empresa.rut,
      });
    }

    return asientos;
  }

  function searchKey(event: any) {
    if (!responseCache.cached) return;

    if (event.key === 'Enter') {
      search(event.detail.value);
    }
  }

  function searchChanged(event: any) {
    if (!responseCache.cached) return;
    search(event.detail.value);
  }

  function search(text: string) {
    const keywords = text.toLowerCase().split(' ');
    let rows: any = [];
    for (const item of responseCache.response.data.data) {
      item.periodo.entries.forEach((entry: BukEntry) => {
        const rendicionText = JSON.stringify(entry).toLowerCase();

        let found: boolean = true;
        keywords.forEach((keyword) => {
          if (!rendicionText.includes(keyword)) {
            found = false;
            return;
          }
        });

        if (found) {
          rows.push(entry);
        }
      });
    }
    setRows(rows);
  }

  const noRegisters = responseCache.cached ? (
    <IonTitle>No se encontraron datos</IonTitle>
  ) : (
    <></>
  );

  function exportSAP() {
    if (!appState.sapCredentials) {
      appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'No hay credenciales SAP configuradas' });
      return;
    }

    setSapModal(false);

    if (asientosSAP.length < 1) {
      return;
    }

    setSAPResultsModal(true);
    setExporting(true);
    setSAPExportResults('');
    setSAPExportResults('');

    const body: { rutList: string[] } = { rutList: [] };

    const dict = appState.toggleDictionaries[toggle_export_SAP_key];

    for (const asiento of asientosSAP) {
      const rendiciones = dict[asiento.rut]
      for (const rendicion in rendiciones) {
        console.log(rendicion)
        if (rendiciones[rendicion]) {
          body.rutList.push(asiento.rut);
        }
      }
    }

    axios
      .post(
        URL + '/buk-sap-export' + getFilters(),
        body,
        createHeader(appState.token)
      )
      .then((result) => {
        console.log(result);

        setSAPResultsModal(true);

        result.data.data.ids
          ? setSAPExportResults(
            `${result.data.data.message}  Rendiciones con error: ${result.data.data.ids.join(',')}`
          )
          : setSAPExportResults(result.data.data.message);
      })
      .catch((error) => {
        console.error(error);

        const message = error.response.data?.data?.errorMessage
          ? error.response.data.data.errorMessage
          : error.response.data?.error
            ? error.response.data.error
            : error.message;

        setSAPResultsModal(false);
        appDispatch({
          type: AppStateReducerActionTypes.ERROR_ALERT,
          error_payload: message,
        });
      })
      .finally(() => {
        setExporting(false);
      });
  }

  const setFinalDateofModal = (event: any) => {
    console.log(event);
    setDatePicked(true);
    refresh();
  };

  const refresh = useCallback(() => {
    setTableRefreshPending(true);
    setCSVPending(true);
    setRows([]);
    setAsientosSAP(asientosSAPDefault);
    setResponseCache({ cached: false, response: null });

    axios
      .get(URL + '/buk' + getFilters(), createHeader(appState.token))
      .then((response) => {
        setResponseCache({ cached: true, response: response });

        let rows: any = [];

        for (const data of response.data.data) {
          const empresa: Empresa = data.periodo;
          empresa.JdtNum = data.JdtNum;
          empresa.sapError = data.sapError;

          empresa.entries.forEach((item) => {
            item.razon_social = empresa.razon_social;
            if (
              empresa.sapError !== undefined &&
              empresa.sapError.error !== undefined
            ) {
              item.sap_error =
                '[' +
                empresa.sapError.error.code +
                '] ' +
                empresa.sapError.error.message.value;
            } else if (empresa.JdtNum !== undefined) {
              item.sap_error = empresa.JdtNum;
            } else {
              item.sap_error = 'NO';
            }

            return item;
          });

          rows = rows.concat(empresa.entries);
        }

        setRows(rows);
        setTableRefreshPending(false);
        setCSVPending(false);
      })
      .catch((error) => {
        console.error(error);

        const err: BukEntry = {
          razon_social: '',
          parent_key: error.code,
          line_num: 0,
          account_code: error.message,
          debit: 0,
          credit: 0,
          line_memo: '',
          reference_1: '',
          reference_2: '',
          cousting_code: '',
          cousting_code2: '',
          cousting_code3: '',
          cousting_code4: '',
          sap_error: '',
        };

        let rows: any = [];
        rows.push(err);
        setRows(rows);
        setTableRefreshPending(false);
        setCSVPending(false);
        onConnectionError(error, appDispatch);
        logoutIfUnauthorized(error, appDispatch);
      });
  }, [appDispatch, appState.token, asientosSAPDefault, getFilters]);

  const [theme, setTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  React.useEffect(() => {
    if (selectedDate === null) {
      setDate(new Date());
      setDatePicked(true);
      setFirtLoad(true);
    }
    if (firstLoad) {
      setFirtLoad(false);
      refresh();
    }
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme); // "dark" or "light"
        setTheme(event.matches ? 'dark' : 'light');
      });
  }, [firstLoad, refresh, selectedDate]);

  function toggleAll(event: React.ChangeEvent<HTMLInputElement>) {
    //function toggleAll(event: any) {
    appDispatch({
      type: AppStateReducerActionTypes.TOGGLE_ALL,
      //toggle_all_payload: event.detail.checked,
      toggle_all_payload: { key: toggle_export_SAP_key, value: event.target.checked },
    });
  }

  function showSapErrors() {
    if (!responseCache.cached) return;

    const erroresArray = [];

    for (const data of responseCache.response.data.data) {
      if (data.sapError && data.sapError.error && data.sapError.error.message.value.length > 0) {
        erroresArray.push(`${data.periodo.razon_social}: [${data.sapError.error.code}] Error: ${data.sapError.error.message.value}`);
      }
    }

    setErroresSAP(erroresArray);
    setSapErrorModal(true);
  }


  function checkActualDate(fecha: any): boolean {
    const fechaComparar = new Date(fecha); // Convertir la fecha dada en un objeto de tipo Date
    const fechaActual = new Date();  // Obtener la fecha actual
    fechaActual.setMonth(fechaActual.getMonth() - 1); // Establecer la fecha actual como un mes anterior
    return (
      fechaComparar.getFullYear() !== fechaActual.getFullYear() ||
      fechaComparar.getMonth() !== fechaActual.getMonth()
    );
  }
  
  function extractBukData() {
     present({
          message: 'Extrayendo datos de BUK',
          duration: 110000,
     });
     axios.get(URL + '/call-buk-refresh' + getFilters(), createHeader(appState.token)).then(response => {
       console.log('llamada al servicio de extraer data de buk...', response);
     }).catch((error) => {
      console.error(error);
     })
  }
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Exportar Buk</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow class='ion-align-items-center'>
            <IonCol
              size='12'
              sizeLg='4'
              class='ion-justify-content-center ion-padding'>
              <IonItem lines='none'>
                <IonLabel>Fecha:</IonLabel>
                <IonDatetimeButton datetime='datetime'></IonDatetimeButton>
                <IonModal
                  onIonModalDidDismiss={setFinalDateofModal}
                  keepContentsMounted={true}>
                  <IonDatetime
                    locale='es-CL'
                    onIonChange={onClickMonth}
                    presentation='month-year'
                    value={datePicked ? selectedDate.toISOString() : ''}
                    id='datetime'></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>

            <IonCol size='12' sizeLg='8' class='ion-no-padding'>
              <IonSearchbar
                type='text'
                placeholder='Buscar por razón social'
                showCancelButton='focus'
                onIonChange={(e) => searchChanged(e)}
                onKeyDown={searchKey}></IonSearchbar>
            </IonCol>
          </IonRow>
          <IonRow class='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
            
              <IonButton
                expand='block'
                color='primary'
                onClick={saveCSV}
                disabled={csvPending || rows.length === 0}>
                <IonIcon slot='start' icon={saveOutline}></IonIcon>
                Guardar Como SVG
              </IonButton>
            
            
              <IonButton
                expand='block'
                color='primary'
                onClick={onCreateSAP}
                disabled={rows.length === 0}>
                <IonIcon slot='start' icon={cloudUploadOutline}></IonIcon>
                Exportar a SAP
              </IonButton>
            
            
              <IonButton
                id="click-trigger"
                color='primary'
                expand='block'
                onClick={()=> setIsOpenTwo(true)}
              >
                <IonIcon slot='start' icon={cloudDownloadOutline}></IonIcon>
                Extraer Buk data
              </IonButton>
              <IonAlert
                isOpen={isOpenTwo}
                header="¡Aviso!"
                subHeader="Podrás extraer data de Buk de meses anteriores segun la fecha seteada, puede que tarde  unos minutos, luego recargue la pagina."
                buttons={[
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                    handler: () => setIsOpenTwo(false),
                  },
                  {
                    text: 'Continuar',
                    role: 'confirm',
                    handler: () => extractBukData(),
                  },
                ]}
                onDidDismiss={() => setIsOpen(false)}
              ></IonAlert>
            
            
              <IonButton
                fill='outline'
                expand='block'
                onClick={showSapErrors}
                disabled={rows.length === 0}
              >
                <IonIcon slot='start' icon={bugOutline}></IonIcon>
                Errores SAP
              </IonButton>
            
          </IonRow>
          {datePicked && tableRefreshPending ? (
            <IonProgressBar type='indeterminate'></IonProgressBar>
          ) : (
            <IonRow>
              {datePicked && !tableRefreshPending ? (
                <DataTable
                  title='BUK'
                  columns={columns}
                  data={rows}
                  progressPending={tableRefreshPending}
                  pagination
                  dense
                  striped
                // theme={theme}
                />
              ) : (
                noRegisters
              )}
            </IonRow>
          )}
        </IonCardContent>
      </IonCard>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={sapResultsModal && exporting}
        message={'Exportando...'}
        duration={5000}
      />
      <IonAlert
        isOpen={sapResultsModal && !exporting}
        header={sapExportResults}
        buttons={['OK']}
        onDidDismiss={setFinalDateofModal}
      />
      <IonModal isOpen={sapModal}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Exportar a SAP
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setSapModal(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          {
            asientosSAP.length > 0 ?
              <>
                <label>
                  <input type="checkbox" onChange={toggleAll} checked={appState.toggleAllSet[toggle_export_SAP_key]} />
                  Todos / Ninguno
                </label>
                {
                  asientosSAP.map((asiento, index) => {
                    console.log(asiento)
                    return (
                      <div key={index}>
                        <BukSAPTable
                          keyValue={index}
                          title={'Asiento ' + asiento.id + ' / ' + asiento.empresa}
                          rows={asiento.registers}
                          empresa={asiento.rut}
                          showToggle={true}
                        />
                      </div>
                    );
                  })
                }
              </>
              :
              <>No hay contenido para exportar</>
          }
        </IonContent>
        <IonFooter>
          <IonRow class='ion-justify-content-end ion-align-items-center'>
            {actualDate && 
              <IonCol size="12" sizeLg='5'>
                <IonChip color="warning">
                  <IonIcon icon={alertCircleOutline} color="warning"></IonIcon>
                  <IonLabel>Reprocesar asientos solo del proceso actual</IonLabel>
                </IonChip>
              </IonCol>
            }
            <IonCol size="6" sizeLg='4'>
             <IonButton
              onClick={() => setIsOpen(true)}
              id="present-alert"
              expand='block'
              color='success'
              disabled={actualDate}>
              <IonIcon slot='start' icon={cloudUploadOutline}></IonIcon>
              Reprocesar asientos
            </IonButton>
            <IonAlert
              isOpen={isOpen}
              header="¡Aviso!"
              subHeader="Podrás reprocesar los asientos una vez el administrador acepte la solicitud, espere unos minutos y vuelva a abrir el modal"
              buttons={[
                {
                  text: 'Cancelar',
                  role: 'cancel',
                  handler: () => setIsOpen(false),
                },
                {
                  text: 'Continuar',
                  role: 'confirm',
                  handler: () => {
                      axios
                          .get(URL + '/send-reprocessing-email')
                          .then((result) => {
                            console.log('llamada al servicio de send-email-reprocessing', result);
                            
                            appDispatch({
                                type: AppStateReducerActionTypes.REPROCESSING,
                                reprocessing_payload: { status: true },
                            })
                          })
                          .catch((reason) => {
                            console.error('reason', reason);
                          });
                          setSapModal(false)
                          setIsOpenThree(true)

                  },
                },
              ]}
              onDidDismiss={() => setIsOpen(false)}
            ></IonAlert>
          </IonCol>
            <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                expand='block'
                disabled={asientosSAP.length === 0}
                onClick={exportSAP}>
                <IonIcon slot='start' icon={cloudUploadOutline}></IonIcon>
                Exportar a SAP
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonToast
          isOpen={isOpenThree}
          color='success'
          message="¡Solicitud de reprocesamiento enviada!"
          onDidDismiss={() => setIsOpen(false)}
          duration={3000}
          icon={checkmarkCircleOutline}
      ></IonToast>
      <IonModal isOpen={sapErrorModal}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Errores en SAP
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setSapErrorModal(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {erroresSAP.length > 0 ? erroresSAP.map((asiento, index) => {
            return (
              <ul key={index}>
                <li>{asiento}</li>
              </ul>
            );
          })
            :
            <div>No hay errores en el listado</div>
          }
        </IonContent>
      </IonModal>
    </>
  );
};

export default Buk;