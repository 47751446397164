import { useState, useEffect } from 'react';
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonList,
  IonFooter,
  IonSelect,
  IonSelectOption,
  useIonModal,
} from '@ionic/react';
import { closeOutline, addOutline, documentOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';
import NewClientModal from './modal-newClient';
import {
  almacen,
  costo,
  negocio,
  articulo,
  // regiones,
} from '../../common/contractUtils';

const ContractModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number | object) => void;
}) => {
  interface Contract {
    project: string;
    almacen: string;
    negocio: string;
    articulo: string;
    costo: string;
    name: string;
    observations: string;
    startDate: string;
    deadlineTerms: string;
    client: string;
    mangaXyPrice: string;
    BypassPrice: string;
    pUnitUdPrice: string;
    pdfFile: File | string;
  }
  const newDefaultContract: Contract = {
    project: '',
    almacen: '',
    negocio: '',
    articulo: '',
    costo: '',
    name: '',
    observations: '',
    startDate: '',
    deadlineTerms: '',
    client: '',
    mangaXyPrice: '',
    BypassPrice: '',
    pUnitUdPrice: '',
    pdfFile: '',
  };
  const [newContract, setNewContract] = useState(newDefaultContract);

  const handleInputChange = (e: any) => {
    // console.log(e);
    if (e.target.name === 'pdfFile') {
      setNewContract({
        ...newContract,
        pdfFile: e.target.files[0],
      });
    } else {
      setNewContract({
        ...newContract,
        [e.target.name]: e.detail.value,
      });
    }
  };

  const handleformSubmit = (e: any) => {
    e.preventDefault();
    onDismiss(newContract);
    console.log('Contract data send  --> ', newContract);
  };

  const pdfFile = () => {
    const input = document.getElementById('pdfFile');
    input?.click();
  };

  //---- New Client Modal -------

  const [present, dismiss] = useIonModal(NewClientModal, {
    onDismiss: (data: string) => dismiss(data),
  });

  const [infoClient, setInfoClient] = useState(null);

  const openModal = () => {
    present({
      onWillDismiss: (event: CustomEvent<OverlayEventDetail>) => {
        setInfoClient(event.detail.data);
      },
    });
  };

  useEffect(() => {
    console.log('Client data received --> ', infoClient);
  }, [infoClient]);

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Creación de contrato
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => onDismiss(null)}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <form>
            <IonRow class='ion-padding'>
              <IonCol size='12' sizeLg='3'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    N de Proyecto
                  </IonLabel>
                  <IonInput
                    value={newContract.project}
                    name='project'
                    type='text'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow class='ion-padding'>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='floating'>Area de Negocio</IonLabel>

                  <IonSelect
                    interface='popover'
                    name='negocio'
                    onIonChange={(e) => handleInputChange(e)}>
                    {negocio.map((n) => (
                      <IonSelectOption value={n.negocio} key={n.code}>
                        {n.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='floating'>Articulo</IonLabel>

                  <IonSelect
                    interface='popover'
                    name='articulo'
                    onIonChange={(e) => handleInputChange(e)}>
                    {articulo.map((a) => (
                      <IonSelectOption value={a.name} key={a.code}>
                        {a.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow class='ion-padding'>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='floating'>Selecciona un Almacen</IonLabel>

                  <IonSelect
                    interface='popover'
                    name='almacen'
                    onIonChange={(e) => handleInputChange(e)}>
                    {almacen.map((al) => (
                      <IonSelectOption value={al.name} key={al.code}>
                        {al.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='floating'>Centro de Costo</IonLabel>
                  <IonSelect
                    interface='popover'
                    name='costo'
                    onIonChange={(e) => handleInputChange(e)}>
                    {costo.map((c) => (
                      <IonSelectOption value={c.name} key={c.code}>
                        {c.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Nombre del contrato
                  </IonLabel>
                  <IonInput
                    value={newContract.name}
                    name='name'
                    type='text'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Acuerdos / Observaciones
                  </IonLabel>
                  <IonInput
                    value={newContract.observations}
                    name='observations'
                    type='text'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Fecha de inicio
                  </IonLabel>
                  <IonInput
                    value={newContract.startDate}
                    name='startDate'
                    type='date'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Plazos boletas de garantía
                  </IonLabel>
                  <IonInput
                    value={newContract.deadlineTerms}
                    name='deadlineTerms'
                    type='date'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center ion-margin-bottom'>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='floating'>Selecciona un cliente</IonLabel>
                  <IonSelect
                    value={newContract.client}
                    name='client'
                    onIonChange={(e) => handleInputChange(e)}
                    interface='popover'>
                    <IonSelectOption value='Daniel'>Daniel</IonSelectOption>
                    <IonSelectOption value='Lucia'>Lucia</IonSelectOption>
                    <IonSelectOption value='Fede'>Fede</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonButton
                  color='success'
                  expand='block'
                  fill='solid'
                  shape='round'
                  onClick={() => openModal()}>
                  <IonIcon slot='start' icon={addOutline}></IonIcon>
                  Nuevo cliente
                </IonButton>
              </IonCol>
            </IonRow>
            <IonTitle color='dark' class='ion-margin-bottom'>
              Valores acordados:
            </IonTitle>
            <IonRow>
              <IonCol>
                <IonRow class='ion-justify-content-between ion-align-items-center ion-hide-lg-down'>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>
                        Ítem / servicio
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>
                        modalidad cobro
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>Valor</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonList class='ion-no-padding'>
                  <IonRow class='ion-justify-content-between ion-align-items-center'>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>Manga XY</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>UF / CLP</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='12' sizeLg='4' class='ion-text-center'>
                      <IonItem>
                        <IonLabel position='floating' className='bold'>
                          Valor x por metros
                        </IonLabel>
                        <IonInput
                          value={newContract.mangaXyPrice}
                          name='mangaXyPrice'
                          type='text'
                          onIonChange={(e) => handleInputChange(e)}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow class='ion-justify-content-between ion-align-items-center'>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>Bypass</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>UF / CLP</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='12' sizeLg='4' class='ion-text-center'>
                      <IonItem>
                        <IonLabel position='floating' className='bold'>
                          Valor x por día
                        </IonLabel>
                        <IonInput
                          value={newContract.BypassPrice}
                          name='BypassPrice'
                          type='text'
                          onIonChange={(e) => handleInputChange(e)}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow class='ion-justify-content-between ion-align-items-center'>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>PUnit UD</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='6' sizeLg='4'>
                      <IonLabel class='ion-text-center'>
                        <p>uf/m</p>
                      </IonLabel>
                    </IonCol>
                    <IonCol size='12' sizeLg='4' class='ion-text-center'>
                      <IonItem>
                        <IonLabel position='floating' className='bold'>
                          Valor UF x unidad
                        </IonLabel>
                        <IonInput
                          name='pUnitUdPrice'
                          type='text'
                          value={newContract.pUnitUdPrice}
                          onIonChange={(e) => handleInputChange(e)}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12' class='ion-hide'>
                <input
                  id='pdfFile'
                  name='pdfFile'
                  type='file'
                  onChange={(e) => handleInputChange(e)}
                />
              </IonCol>
              <IonCol size='12'>
                <IonButton
                  onClick={pdfFile}
                  color='warning'
                  size='default'
                  expand='block'
                  fill='solid'>
                  <IonIcon slot='end' icon={documentOutline}></IonIcon>
                  Adjuntar PDF del contrato
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonContent>
        <IonFooter>
          <IonRow class='ion-justify-content-end ion-align-items-center'>
            <IonCol size='6' sizeLg='3'>
              <IonButton fill='clear' size='default' color='primary'>
                Limpiar campos
              </IonButton>
            </IonCol>
            <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                onClick={(e) => handleformSubmit(e)}>
                Guardar contrato
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonPage>
    </>
  );
};
export default ContractModal;

// async onUploadChange(event) {
//     for (const file of event.target.files) {
//       if (file.size / 1024 / 1024 < 3) {
//         this.sizeColor = 'success';
//       }
//       else{
//         this.sizeColor = 'danger';
//         break;
//       }
//     }

//     this.fileInput = event.target.files;
//     this.documentationForm.controls.file.setValue(this.fileInput);

//     this.attachments = this.fileInput;
//     this.newAttachment = true;
//   }

//         <ion-col size="12">
//           <input type="file" color="primary" (change)="onUploadChange($event)" multiple="true" #fileInput
//             class="ion-hide" value="asd" />
//         </ion-col>
//         <ion-col size="12">
//           <ion-item lines="none">
//             <label *ngIf="activeToUpload" for="select-file" class="custom-file-upload attach-buttom"
//               (click)="fileInput.click()">
//               <ion-icon slot="start" name="attach-outline" class="icon"></ion-icon>
//               <span class="attach-buttom-title ion-hide-sm-down">adjuntar archivos</span>
//             </label>

//           </ion-item>
//           <ion-item lines="none" style="padding-bottom: 0;">
//             <ion-text  [color]="sizeColor" >*Tamaño máximo de documentación por archivo, 3MB</ion-text>
//           </ion-item>
//           <ion-item *ngIf="documentationForm.controls.file.invalid" lines="none">
//             <ion-text  color="danger" >*Debe subir al menos 1 documento</ion-text>
//           </ion-item>

//         </ion-col>
