import React, { useState, useEffect } from 'react';
import { closeOutline, checkmarkDoneOutline } from 'ionicons/icons';
import { regiones, moneda, rubro, pago } from '../../common/contractUtils';
import {
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonList,
  IonFooter,
  IonSelect,
  IonSelectOption,
  useIonToast,
  IonText,
} from '@ionic/react';

const NewClientModal = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number | object) => void;
}) => {
  interface NewClient {
    name: string;
    rutCodigo: string;
    tipoCodigo: string;
    codigo: string;
    rut: string;
    moneda: string;
    telefono: string;
    rubro: string;
    giro: string;
    domicilio: string;
    email: string;
    ciudad: string;
    region: string;
    pais: string;
    condicionesPago: string;
    idContacto: string;
  }

  const newDefaultClient: NewClient = {
    name: '',
    rutCodigo: '',
    tipoCodigo: '',
    codigo: '',
    rut: '',
    moneda: '',
    telefono: '',
    rubro: '',
    giro: '',
    domicilio: '',
    email: '',
    ciudad: '',
    region: '',
    pais: '',
    condicionesPago: '',
    idContacto: '',
  };
  const [newClient, setNewClient] = useState(newDefaultClient);
  const [present] = useIonToast();
  const [formIsValid, setFormIsValid] = useState(false);
  const [paises, setPaises] = useState<any[]>([]);

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/region/americas')
      .then((response) => response.json())
      .then((data) => {
        setPaises(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleValidateForm = (name: any, email: any, rut: any) => {
    if (name !== '' && email !== '' && rut !== '') {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const presentToast = (position: 'top' | 'middle' | 'bottom') => {
    present({
      message: '¡Cliente Creado!',
      duration: 5000,
      position: position,
      color: 'success',
      icon: checkmarkDoneOutline,
    });
  };

  const handleInputChange = (e: any) => {
    // console.log(e);
    if (e.target.name === 'e.detail.value') {
      setNewClient({
        ...newClient,
      });
    } else {
      setNewClient({
        ...newClient,
        [e.target.name]: e.detail.value,
      });
    }
  };

  const handleformSubmit = (e: any) => {
    e.preventDefault();
    onDismiss(newClient);
    console.log('Client data send  --> ', newClient);
    presentToast('bottom');
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='7'>
                  <IonTitle className='bold' color='primary'>
                    Crear Nuevo Cliente
                  </IonTitle>
                </IonCol>
                <IonCol size='2' sizeLg='2'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => onDismiss(null)}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <form>
            <IonTitle color='primary' class='ion-margin-bottom'>
              Datos Generales
            </IonTitle>
            <IonRow>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='floating'>Codigo</IonLabel>
                  <IonSelect
                    value={newClient.codigo}
                    name='codigo'
                    onIonChange={(e) => handleInputChange(e)}
                    interface='popover'>
                    <IonSelectOption value='Manual'>Manual</IonSelectOption>
                    <IonSelectOption value='xxxx'>xxxx</IonSelectOption>
                    <IonSelectOption value='xxxx'>xxxx</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>

              <IonCol size='12' sizeLg='5'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Rut s/Cod.Verificador.+Letra Codigo
                  </IonLabel>
                  <IonInput
                    value={newClient.rutCodigo}
                    name='rutCodigo'
                    type='text'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='3'>
                <IonItem>
                  <IonLabel position='floating'> Tipo cliente</IonLabel>
                  <IonSelect
                    value={newClient.tipoCodigo}
                    name='tipoCodigo'
                    onIonChange={(e) => handleInputChange(e)}
                    interface='popover'>
                    <IonSelectOption value='Cliente'>Cliente</IonSelectOption>
                    <IonSelectOption value='Proveedor'>Proveedor</IonSelectOption>
                    <IonSelectOption value='random'>Lead</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Nombre (*)
                  </IonLabel>
                  <IonInput
                    value={newClient.name}
                    name='name'
                    type='text'
                    onIonChange={(e) => {
                      handleInputChange(e);
                      handleValidateForm(
                        newClient.name,
                        newClient.email,
                        newClient.rut
                      );
                    }}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='6'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    RUT c/Cod. Verificador(*)
                  </IonLabel>
                  <IonInput
                    value={newClient.rut}
                    name='rut'
                    type='number'
                    onIonChange={(e) => {
                      handleInputChange(e);
                      handleValidateForm(
                        newClient.name,
                        newClient.email,
                        newClient.rut
                      );
                    }}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center ion-margin-bottom'>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='floating'>Moneda</IonLabel>
                  <IonSelect
                    interface='popover'
                    name='moneda'
                    onIonChange={(e) => handleInputChange(e)}>
                    {moneda.map((m) => (
                      <IonSelectOption value={m.name} key={m.cod}>
                        {m.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='floating'>Rubro</IonLabel>
                  <IonSelect
                    value={newClient.rubro}
                    name='rubro'
                    onIonChange={(e) => handleInputChange(e)}
                    interface='popover'>
                    {rubro.map((r) => (
                      <IonSelectOption value={r.name} key={r.cod}>
                        {r.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Giro
                  </IonLabel>
                  <IonInput
                    value={newClient.giro}
                    name='giro'
                    type='text'
                    onIonChange={(e) => handleInputChange(e)}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonTitle color='primary' class='ion-margin-botton ion-padding-top'>
              Datos de Persona de Contacto
            </IonTitle>
            <IonRow>
              <IonCol>
                <IonRow class='ion-justify-content-between ion-align-items-center '>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Id Contacto
                      </IonLabel>
                      <IonInput
                        value={newClient.idContacto}
                        name='idContacto'
                        type='text'
                        onIonChange={(e) => handleInputChange(e)}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Domicilio
                      </IonLabel>
                      <IonInput
                        value={newClient.domicilio}
                        name='domicilio'
                        type='text'
                        onIonChange={(e) => handleInputChange(e)}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Telefono
                      </IonLabel>
                      <IonInput
                        value={newClient.telefono}
                        name='telefono'
                        type='number'
                        onIonChange={(e) => handleInputChange(e)}></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonList class='ion-no-padding'></IonList>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonRow class='ion-justify-content-between ion-align-items-center '>
                  <IonCol size='12' sizeLg='6'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Correo Electronico(*)
                      </IonLabel>
                      <IonInput
                        value={newClient.email}
                        name='email'
                        type='text'
                        onIonChange={(e) => {
                          handleInputChange(e);
                          handleValidateForm(
                            newClient.name,
                            newClient.email,
                            newClient.rut
                          );
                        }}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='6'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Ciudad
                      </IonLabel>
                      <IonInput
                        value={newClient.ciudad}
                        name='ciudad'
                        type='text'
                        onIonChange={(e) => handleInputChange(e)}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='6'>
                    <IonItem>
                      <IonLabel position='floating'>Estado/Region</IonLabel>
                      <IonSelect
                        interface='popover'
                        name='region'
                        onIonChange={(e) => handleInputChange(e)}>
                        {regiones.map((r: any) => (
                          <IonSelectOption value={r.region} key={r.region}>
                            {r.region}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='6'>
                    <IonItem>
                      <IonLabel position='floating'>Pais</IonLabel>
                      <IonSelect
                        value={newClient.pais}
                        name='pais'
                        onIonChange={(e) => handleInputChange(e)}
                        interface='popover'>
                        {paises.map((p: any) => (
                          <IonSelectOption value={p.name.common} key={p.name.common}>
                            {p.name.common}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

            <IonTitle color='primary' class='ion-margin-bottom ion-padding'>
              Condiciones de Pago
            </IonTitle>
            <IonRow>
              <IonCol>
                <IonRow class='ion-justify-content-between ion-align-items-center '>
                  <IonCol size='12' sizeLg='6'>
                    <IonItem>
                      <IonLabel position='floating'>Pago</IonLabel>
                      <IonSelect
                        value={newClient.condicionesPago}
                        name='condicionesPago'
                        onIonChange={(e) => handleInputChange(e)}
                        interface='popover'>
                        {pago.map((p) => (
                          <IonSelectOption value={p.name} key={p.cod}>
                            {p.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow class=' ion-justify-content-end ion-padding'>
                  <IonCol size='12' sizeLg='12' class='ion-text-right'>
                    <IonText>(*) Los campos son Obligatorios </IonText>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </form>
        </IonContent>
        <IonFooter>
          <IonRow class='ion-justify-content-end ion-align-items-center'>
            <IonCol size='6' sizeLg='3'>
              <IonButton fill='clear' size='default' color='primary'>
                Limpiar campos
              </IonButton>
            </IonCol>
            <IonCol size='6' sizeLg='3'>
              <IonButton
                fill='solid'
                size='default'
                color='primary'
                disabled={!formIsValid}
                onClick={(e) => handleformSubmit(e)}>
                Guardar Cliente
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonPage>
    </>
  );
};
export default NewClientModal;
