import React from 'react';
import { IonText, IonPage, IonContent, IonRow, IonCol } from '@ionic/react';
const Welcome = () => {
  return (
    <>
      <IonPage>
        <IonContent color="primary">
          <IonRow id="welcome">
            <IonCol size="6">
              <IonText class="ion-text-center" color="light">
                <img src="/Logo-PSG-Fondo-Azul.png" alt="Grupo PSG" />
              </IonText>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Welcome;
