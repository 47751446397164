import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { statsChartOutline } from 'ionicons/icons';

function EfectivoEquivalenteSelect() {
  const { dispatch: stateDispatch } = useAppContext();

  function showAperturaEmpresas() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_APERTURA_EMPRESAS });
  }

  const showVistaControl = () =>  {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_VISTA_CONTROL });
  }

  // const showAperturaEmpresasMontos = () =>  {
  //   stateDispatch({ type: AppStateReducerActionTypes.SHOW_APERTURA_EMPRESAS_MONTOS });
  // }

  const showVistaImportacionClay =() => {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_IMPORTACION_CLAY });
  }

  const showVistaInversiones = () => {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_INVERSIONES });
  }

  return (
    <>
      <IonItem slot="header" color="primary">
        <IonIcon
          color="white"
          icon={statsChartOutline}
          slot="start"></IonIcon>
        <IonLabel>Efectivos y Equivalentes</IonLabel>
      </IonItem>
      <div
        className="ion-padding divStyle"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showAperturaEmpresas}>
        Apertura de Empresas
      </div>
      <div
        className="ion-padding divStyle"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showVistaControl}>
        Vistas de Control
      </div>
      <div
        className="ion-padding divStyle"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showVistaImportacionClay}>
        Importar datos desde Clay.
      </div>
      <div
        className="ion-padding"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showVistaInversiones}>
        Cuentas bancarias
      </div>
    </>
  );
}

export default EfectivoEquivalenteSelect;
