import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes, Permission } from '../../common/reducer';
import { IonItem, IonLabel, IonIcon, IonAccordionGroup, IonAccordion, IonList } from '@ionic/react';
import { documentTextOutline } from 'ionicons/icons';

function UserSelect() {
  const { state: appState, dispatch: stateDispatch } = useAppContext();

  function showAdmin() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_USER_ADMIN });
  }

  function showCredencialesSAP() {
    stateDispatch({ type: AppStateReducerActionTypes.EDIT_USER_ADMIN, edit_sap_credentials_payload: undefined });
  }

  const admin = appState.permissions.includes(Permission.ADMIN);
  const user = appState.permissions.includes(Permission.CREDENCIALES_SAP) || admin;

  return (
    <>
      <IonAccordionGroup color='primary'>
         <IonAccordion value="first">
          <IonItem slot="header" color="primary">
            <IonLabel>USUARIOS</IonLabel>
          </IonItem>
          <div  slot="content">
            <IonList class='ion-no-padding'>
               {
                  admin ? <div
                    className="ion-padding divStyle"
                    style={{ color: '#ffff', background: '#002a54' }}
                    slot="content"
                    onClick={showAdmin}>
                    Administración de Usuarios
                  </div> :
                    <></>
                }
                {
                  user ?
                    <div
                      className="ion-padding"
                      style={{ color: '#ffff', background: '#002a54' }}
                      slot="content"
                      onClick={showCredencialesSAP}>
                      Editar Mis Credenciales SAP
                    </div>
                    :
                    <></>
                }
            </IonList>
          </div>
        </IonAccordion>
      </IonAccordionGroup>
      {/* <IonItem slot="header" color="primary">
        <IonIcon
          color="white"
          icon={documentTextOutline}
          slot="start"></IonIcon>
        <IonLabel>Usuarios</IonLabel>
      </IonItem>
      {
        admin ? <div
          className="ion-padding divStyle"
          style={{ color: '#ffff', background: '#002a54' }}
          slot="content"
          onClick={showAdmin}>
          Administración de Usuarios
        </div> :
          <></>
      }
      {
        user ?
          <div
            className="ion-padding"
            style={{ color: '#ffff', background: '#002a54' }}
            slot="content"
            onClick={showCredencialesSAP}>
            Editar Mis Credenciales SAP
          </div>
          :
          <></>
      } */}

    </>
  );
}

export default UserSelect;
