import React from "react";
import { AppStateAction, AppStateReducerActionTypes } from "./reducer";

export const onConnectionError = (error: any, appDispatch: React.Dispatch<AppStateAction>) => {
	if (error.custom) {
		appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: error.custom })
	} else if (error.response) {
		console.log(error.response.data);
		console.log(error.response.status);
		console.log(error.response.headers);
		appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: error.response.status === 401 ? 'Credenciales incorrectas o sesión expirada' : 'Error en el servicio' })
	} else if (error.request) {
		console.log(error.request);
		appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'Error de conexión' })
	} else {
		console.log('Error', error.message);
		appDispatch({ type: AppStateReducerActionTypes.ERROR_ALERT, error_payload: 'Hubo un error desconocido' })
	}
}