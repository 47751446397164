export interface ToggleDictionary {
	[id: string]: { [key: string]: boolean };
}

export const toggleDictionaryValues = (dictionary: ToggleDictionary, on: boolean): ToggleDictionary => {
	for (const empresa in dictionary) {
		for (const rendicion in dictionary[empresa]) {
			dictionary[empresa][rendicion] = on;
		}
	}
	return dictionary;
}

export const setToggleDictionaryValues = (dictionary: ToggleDictionary): ToggleDictionary => {
	for (const empresa in dictionary) {
		for (const rendicion in dictionary[empresa]) {
			dictionary[empresa][rendicion] = true;
		}
	}
	return dictionary;
}

export const allToggleDictionaryValuesAreTrue = (dictionary: ToggleDictionary): boolean => {
	let res: boolean = true;

	for (const empresa in dictionary) {
		for (const rendicion in dictionary[empresa]) {
			if (!dictionary[empresa][rendicion]) {
				res = false;
			}
		}
	}

	return res;
}

export const toggleDictionaryHasAtLeastOneSet = (dictionary: ToggleDictionary): boolean => {
	let res: boolean = false;

	for (const empresa in dictionary) {
		for (const rendicion in dictionary[empresa]) {
			if (dictionary[empresa][rendicion]) {
				res = true;
			}
		}
	}

	return res;
}