import {
  IonFooter,
  IonRow,
  IonInput,
  IonTitle,
  IonText,
  IonButton,
  IonIcon,
  IonCol,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonLoading,
} from '@ionic/react';
import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { useAppContext } from '../common/appContext';
import { obtainAuthURL } from '../common/obtainURL';
import { AppStateReducerActionTypes } from '../common/reducer';
import { logInOutline } from 'ionicons/icons';
let URL = obtainAuthURL();

const Login = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [asyncState, setAsyncState] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [showLoading, setShowLoading] = useState(false);

  React.useEffect(() => { });

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowLoading(true);
    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!formDictionary.email) {
      setErrorMessage('Debe ingresar su email');
    } else if (!formDictionary.password) {
      setErrorMessage('Debe ingresar una contraseña');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/sign-in', {
          email: formDictionary.email,
          password: formDictionary.password,
        })
        .then((response) => {
          setAsyncState(false);

          console.log(response);
          localStorage.setItem('user', JSON.stringify(response.data.data));
          appDispatch({
            type: AppStateReducerActionTypes.SET_USER_PERMISSIONS,
            permissions_payload: response.data.data.permisos,
          });
          appDispatch({
            type: AppStateReducerActionTypes.LOGIN,
            login_payload: {
              email: response.data.data.email,
              token: response.data.data.token,
            },
          });
          appDispatch({
            type: AppStateReducerActionTypes.SET_SAP_CREDENTIALS_STATUS,
            sap_credentials_payload: response.data.data.tieneCredencialesSAP,
          });
        })
        .catch((reason) => {
          setShowLoading(false);
          setAsyncState(false);
          console.error(reason);
          setErrorMessage(
            reason.response?.data ?
              'Error de ingreso: ' + reason.response.data.data.errorMessage
              :
              'Error de ingreso: ' + reason.code
          );
        });
    }
  }

  function forgot() {
    appDispatch({ type: AppStateReducerActionTypes.RECOVER_PASSWORD });
  }

  function register() {
    appDispatch({ type: AppStateReducerActionTypes.REGISTER });
  }

  function resetError() {
    setErrorMessage('');
  }

  var logoStyle: string = "height:80%;"
  return (
    <IonPage>
      <IonContent color="white">
        <IonRow class='ion-margin-top'>
          <IonCol size='12'>
            <IonTitle color='primary' class='ion-text-center'>
              <img src="/Logo-PSG-Fondo-Blanco.png" alt="Grupo PSG" style={{ maxWidth: "25%" }} />
            </IonTitle>
          </IonCol>
        </IonRow>
        <IonRow class='ion-margin-bottom'>
          <IonCol size='12'>
            <IonText color='dark' class='ion-text-center'>
              <h2>¡Bienvenido!</h2>
            </IonText>
          </IonCol>
        </IonRow>
        <form onSubmit={formSubmit}>
          <IonRow class='ion-justify-content-center'>
            <IonCol
              size='11'
              sizeLg='10'
              class='ion-no-margin ion-no-padding ion-margin-top'>
              <IonRow>
                <IonCol size='12'>
                  <IonItem>
                    <IonLabel position='stacked' className='bold'>
                      Ingresa tu correo
                    </IonLabel>
                    <IonInput
                      type='email'
                      name='email'
                      onChange={resetError}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow class='ion-margin-bottom'>
                <IonCol size='12'>
                  <IonItem>
                    <IonLabel position='stacked' className='bold'>
                      Ingrese su contraseña
                    </IonLabel>
                    <IonInput
                      type='password'
                      name='password'
                      placeholder='Ingresar contraseña'
                      onChange={resetError}></IonInput>
                  </IonItem>
                  <IonText color='danger' class='ion-text-center'>
                    {errorMessage}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='12' class='ion-margin-top ion-padding'>
                  <IonButton
                    color='primary'
                    size='default'
                    expand='block'
                    type='submit'
                    disabled={asyncState}
                    onClick={resetError}>
                    <IonLoading
                      cssClass='my-custom-class'
                      isOpen={showLoading}
                      onDidDismiss={() => setShowLoading(false)}
                      message={'Iniciando Sesion...'}
                    />
                    <IonIcon slot='end' icon={logInOutline}></IonIcon>
                    Ingresar
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow class='ion-margin-bottom ion-padding'>
                <IonCol size='12'>
                  <IonButton
                    expand='block'
                    size='default'
                    color='tertiary'
                    fill='outline'
                    onClick={forgot}>
                    ¿Olvidaste tu usuario o clave?
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
      <IonFooter id='footer' collapse='fade' class='ion-no-border ion-no-padding'>
        <IonButton
          class='ion-no-margin'
          color='primary'
          size='large'
          expand='full'
          fill='solid'
          onClick={register}>
          Registrarme como nuevo usuario
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Login;