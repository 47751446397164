import React, {  useState } from 'react'
import axios from 'axios';
import {
  IonRow,
  IonSpinner,
  IonCardHeader,
  IonTitle,
  IonText,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonCol,
  IonItem,
  IonContent,
  IonPage,
  IonGrid,
  IonButton,
} from '@ionic/react';
import Welcome from './welcome';
import { shieldCheckmarkOutline, shieldOutline, checkmarkOutline, alertCircleOutline } from 'ionicons/icons';
import { obtainURL } from '../common/obtainURL';
let URL = obtainURL();
const WaitingReprocessing = () => {
 const [progress, setProgress] = useState(false);
 const [done, setDone] = useState(false);
 const [refused, setRefuse] = useState(false)



  const authorizeReprocessing = () => {
    console.log('Ejecutando la autorización:');
    setProgress(true);
    axios
        .post(URL + '/buk-seats-reprocessing', {
          value: true,
        })
        .then((response) => {
          console.log(response); 
            setProgress(false)
            setDone(true)
        })
        .catch((reason) => {
          console.log(reason);
        });
  }
  
  const denyReprocessing = () => {
    console.log('Ejecutando la cancelación:');
     setProgress(true);
     axios
        .post(URL + '/buk-seats-reprocessing', {
          value: false,
        })
        .then((response) => {
          console.log(response);
            setProgress(false)
            setRefuse(true)
        })
        .catch((reason) => {
          console.log(reason);   
        });
  }
  return (
    <IonGrid class="ion-no-padding ion-no-margin">
      <IonRow class="ion-no-padding ion-no-margin ion-align-items-center">
              <IonCol
                sizeMd="5"
                sizeLg="8"
                class="ion-no-padding ion-no-margin ion-hide-md-down"
                style={{ height: '100vh' }}>
                <Welcome />
              </IonCol>
              <IonCol
                size="12"
                sizeMd="7"
                sizeLg="4"
                class="ion-no-padding ion-no-margin"
                style={{ height: '100vh' }}>
                <IonPage>
                  <IonContent color="white">
                    <IonRow class='ion-margin-top'>
                      <IonCol size='12'>
                        <IonTitle color='primary' class='ion-text-center'>
                          <img src="/Logo-PSG-Fondo-Blanco.png" alt="Grupo PSG" style={{ maxWidth: "25%" }} />
                        </IonTitle>
                      </IonCol>
                    </IonRow>
                    <IonRow class='ion-margin-bottom'>
                      <IonCol size='12'>
                        <IonText color='dark' class='ion-text-center'>
                          <h2>¡Bienvenido Mario!</h2>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    {(!done && !refused) &&    
                    <IonRow>
                      <IonCol>
                        <IonCard color="primary">
                          <IonCardHeader>
                            <IonItem color='primary'>
                              <IonIcon size='large' color='success' slot='start' icon={shieldOutline}></IonIcon>
                              {/* <IonLabel class="ion-text-start">
                                  <h1>¡Reproceso aceptado!</h1>
                              </IonLabel> */}
                              <IonCardTitle>¡Solicitud de reprocesamiento!</IonCardTitle>
                              {progress && <IonSpinner slot='end' color='success' name="dots"></IonSpinner>}
                            </IonItem>
                            <IonCardSubtitle>El administrador de la plataforma te solicita autorización para el reproceso de los asientos contables de BUK del mes actual para su posterior exportación a la plataforma SAP.</IonCardSubtitle>
                          </IonCardHeader>
                          <IonCardContent>
                            <IonRow>
                              <IonCol>
                                <IonButton color='danger' expand="block" onClick={()=> denyReprocessing()}>
                                  <IonIcon size='default'  slot='start' icon={alertCircleOutline}></IonIcon>
                                  Cancelar
                                </IonButton>
                              </IonCol>
                              <IonCol>
                                <IonButton color='success' expand="block" onClick={()=> authorizeReprocessing()}>
                                  <IonIcon size='default'  slot='start' icon={checkmarkOutline}></IonIcon>
                                  Autorizar
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                    }
                    {done && 
                    <IonRow class='scale-up-center'>
                      <IonCol>
                        <IonCard color="primary">
                          <IonCardHeader>
                            <IonItem color='primary'>
                              <IonIcon size='large' color='success' slot='start' icon={shieldCheckmarkOutline}></IonIcon>
                              {/* <IonLabel class="ion-text-start">
                                  <h1>¡Reproceso aceptado!</h1>
                              </IonLabel> */}
                              <IonCardTitle>¡Reproceso aceptado!</IonCardTitle>
                            </IonItem>
                            <IonCardSubtitle>Acabas de autorizar el reproceso de los asientos contables de BUK del mes actual para su posterior exportación a la plataforma SAP por el administrador.</IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    </IonRow>
                    }
                    {
                      refused && 
                      <IonRow class='scale-up-center'>
                        <IonCol>
                          <IonCard color="primary">
                            <IonCardHeader>
                              <IonItem color='primary'>
                                <IonIcon size='large' color='warning' slot='start' icon={alertCircleOutline}></IonIcon>
                                {/* <IonLabel class="ion-text-start">
                                    <h1>¡Reproceso aceptado!</h1>
                                </IonLabel> */}
                                <IonCardTitle>¡Reproceso cancelado!</IonCardTitle>
                              </IonItem>
                              <IonCardSubtitle>Acabas de cancelar el reproceso de los asientos contables de BUK del mes actual para su posterior exportación a la plataforma SAP por el administrador.</IonCardSubtitle>
                            </IonCardHeader>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                    }
                  </IonContent>
                </IonPage>
              </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WaitingReprocessing

