import axios from 'axios';
import { useState, useEffect } from 'react';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import{ IonCol,IonButton,IonDatetimeButton,IonDatetime,IonRow,IonCard,IonIcon,IonItem,IonLabel,IonModal, IonLoading, IonCardHeader, IonCardTitle, IonHeader, IonToolbar, IonGrid, IonProgressBar, IonContent, IonCardContent, IonAlert, IonList, IonChip, IonText}from '@ionic/react';
import { IonDatetimeCustomEvent, DatetimeChangeEventDetail,} from '@ionic/core/components';
import { responseClaySaldos } from '../../interfaces/respondeClaySaldos';
// import styled, { keyframes } from 'styled-components';
// import { ResponseClaySaldos } from '../../interfaces/ResponseClaySaldos';
// import { ResponseClaySaldosMovimientos } from '../../interfaces/movimientos';
import { cashOutline, statsChartOutline, logoEuro, trash, closeOutline, fileTrayOutline  } from 'ionicons/icons';
import 'react-data-grid/lib/styles.css';
import ReactDataGrid from 'react-data-grid';
let URL = obtainURLBackendClay();
const AperturaEmpresas = () => {
    const { state: appState, dispatch: appDispatch } = useAppContext();
    const [detailsModal, setDetailsModal] = useState<DetailsModal>({company: '', bank: ''});
    const [isModalOpen, setModalIsOpen] = useState(false)
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const [rowsTable, setRowsTable] = useState([]);
    const [rowsTableAgrupados, setRowsTableAgrupados] = useState([]);
    const [responseClaySaldos, setResponseClaySaldos] = useState<ClaySaldos[]>([]);
    const [tokenPSG, setTokenPSG] = useState(JSON.parse("" + localStorage.getItem("user")).token);
    const [cambioUSD, setCambioUSD] = useState('');
    const [cambioEUR, setCambioEUR] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateUntil, setDateUntil] = useState('');
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(),currentDate.getMonth(), 1);
    const defaultEndDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate() -1);
    const [selectedStartDate, setStartDate] = useState(defaultStartDate);
    const [selectedEndDate, setEndDate] = useState(defaultStartDate);
    const [startDatePicked, setStartDatePicked] = useState(false);
    const [EndDatePicked, setEndDatePicked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [indeterminateLoading, setIndeterminateLoading] = useState(true)
    const [modalMovements, setModalMovements] = useState<Movements[]>([])
    localStorage.setItem("cacheTabla", '[]');
    localStorage.setItem("row", '[]');
    let USDollar = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD' });
    interface DetailsModal  {company: string, bank: string}
    interface ClaySaldos {accountNumber: string, accountType: string, balanceInHand: number, bankAlias: string, 
      bankId: string, countableBalance: number, createdAt: string, createdBy: string, currency: string,
      empresaShortName: string, loadId: string, reportedDate: string, rut: string}
    interface Movements {isPayment: boolean, description: string, transactionDate: string, transactionId: string, amount: number, currency:string}
    

    // Función para determinar la clase CSS condicionalmente
    const getCellClass = (row:any) => {
       if (["TOTAL CORRIENTE", "TOTAL INVERSION", "TOTAL GENERAL"].includes(row.accountType)) {
       return 'bold-cell';
       }
       return '';
    };

    const columns  = [
      {key: 'accountType', name: 'Tipo de Cuenta', cellClass: getCellClass},
      {key: 'bankAlias', name: 'Banco', cellClass: getCellClass},
      {key: 'currency', name: 'Moneda', cellClass: getCellClass},
      {key: 'PSG', name: 'PSG', cellClass: getCellClass},
      {key: 'PSGPERU', name: 'PSG PERU', cellClass: getCellClass},
      {key: 'CIPP', name: 'CIPP', cellClass: getCellClass},
      {key: 'BRYSA', name: 'BRYSA', cellClass: getCellClass},
      {key: 'HIGHFLY', name: 'HIGH FLY', cellClass: getCellClass},
      {key: 'INMOBILIARIA', name: 'INMOBILIARIA', cellClass: getCellClass},
      {key: 'groupSum', name: 'TOTAL', cellClass: getCellClass}
    ];
    
    const columnsAgrupados = [
      {key: 'accountType', name: 'Tipo de Cuenta'},
      {key: 'PSG', name: 'PSG'},
      {key: 'PSGPERU', name: 'PSG PERU'},
      {key: 'CIPP', name: 'CIPP'},
      {key: 'BRYSA', name: 'BRYSA'},
      {key: 'HIGHFLY', name: 'HIGH FLY'},
      {key: 'INMOBILIARIA', name: 'INMOBILIARIA'},
      {key: 'groupSum', name: 'TOTAL'}
    ];

    useEffect(() => {
      const fechaActual = new Date()
      const yyyy = fechaActual.getFullYear()
      const mm = String(fechaActual.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
      const dd = String(fechaActual.getDate() - 1).padStart(2, '0')
      const dateUntil = `${yyyy}-${mm}-${dd}`;
      // Formatear la fecha como "YYYY-MM-DD"
      const dateFrom = `${defaultStartDate.getFullYear()}-${(defaultStartDate.getMonth() + 1).toString().padStart(2, '0')}-${defaultStartDate.getDate().toString().padStart(2, '0')}`;

      setDateFrom(dateFrom); //Siempre parado en el primer dia de cada mes 
      setDateUntil(dateUntil) //Siempre parado en el dia anterior del dia actual de cada mes
      localStorage.setItem("fechaDesde",dateFrom);
      localStorage.setItem("fechaHasta",dateUntil);
      setTokenPSG(JSON.parse("" + localStorage.getItem("user")).token);
      callBalancesClayCompanies(dateFrom, dateUntil, tokenPSG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callBalancesClayCompanies = async (datefrom: string, dateUntil: string,  token: string) =>  {
      setLoading(true) // Cada vez que se recarga la pagina sale el loading
      setRowsTable([]);
      setResponseClaySaldos([]);
      await axios.get(`${URL}/cuentas/saldos/consolidado?fechaInicio=${datefrom}&fechaTermino=${dateUntil}`, createHeader(token))
        .then((response) => {
          console.log('SALDOS: ', response.data.data.response.saldos);
          response.data.data.response.saldos.length > 0 ? setLoading(false) : setLoading(false) //Si el array de saldos se llena o viene vacio oculto el loading para mostrar la card vacia o las tablas con los saldos
          setResponseClaySaldos(response.data.data.response.saldos);
          setCambioUSD(response.data.data.response.tipoCambio.filter((item: any)=> item.currency === 'USD')[0].exchangeRate);
          setCambioEUR(response.data.data.response.tipoCambio.filter((item: any)=> item.currency === 'EUR')[0].exchangeRate);
          let rowsTable: any = [];
          let rowsTableMontos: any = [];
          let contador = 1;

          response.data.data.response.pivot.forEach((empresaclayDet: responseClaySaldos) => {
            let response = {
              accountType: empresaclayDet.accountType,
              bankAlias: empresaclayDet.bankAlias,
              currency: empresaclayDet.currency,
              PSG: USDollar.format(empresaclayDet.PSG),
              PSGPERU: USDollar.format(empresaclayDet.PSGPERU),
              CIPP: USDollar.format(empresaclayDet.CIPP),
              BRYSA: USDollar.format(empresaclayDet.BRYSA),
              HIGHFLY: USDollar.format(empresaclayDet.HIGHFLY),
              INMOBILIARIA: USDollar.format(empresaclayDet.INMOBILIARIA),
              groupSum: USDollar.format(empresaclayDet.groupSum)
            };
            rowsTable.push(response);

            if (empresaclayDet.accountType === 'TOTAL CORRIENTE' || empresaclayDet.accountType === 'TOTAL INVERSION' || empresaclayDet.accountType === 'TOTAL GENERAL') {
              let responseGrupo = {
                accountType: empresaclayDet.accountType,
                PSG: USDollar.format(empresaclayDet.PSG),
                PSGPERU: USDollar.format(empresaclayDet.PSGPERU),
                CIPP: USDollar.format(empresaclayDet.CIPP),
                BRYSA: USDollar.format(empresaclayDet.BRYSA),
                HIGHFLY: USDollar.format(empresaclayDet.HIGHFLY),
                INMOBILIARIA: USDollar.format(empresaclayDet.INMOBILIARIA),
                groupSum: USDollar.format(empresaclayDet.groupSum)
              };
              rowsTableMontos.push(responseGrupo);
            }
            contador = contador + 1 ;
          });
          setRowsTable(rowsTable);
          setRowsTableAgrupados(rowsTableMontos);
        }).catch((error) => {
          console.error("Error: " + error);
          tokenInvalido();
        });
    }

    const tokenInvalido = () => {
      setTimeout(function () {
        console.log("cerrar sesion");
        localStorage.removeItem('user');
        localStorage.removeItem('screen');
        localStorage.removeItem('sapcredentials');
        appDispatch({
          type: AppStateReducerActionTypes.LOGOUT
        });
      }, 4000);
    }

    const onDateChange = (event: IonDatetimeCustomEvent < DatetimeChangeEventDetail >, type: string ) => {
      if (event.detail.value) {
          let fecha =  new Date(event.detail.value as string);
          let yyyy = new Date(event.detail.value as string).getFullYear()
          let mm = String(fecha.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
          let dd = String(fecha.getDate()).padStart(2, '0')
          let dateSelec = `${yyyy}-${mm}-${dd}`;
        if (type === 'start') {
          setStartDate(new Date(event.detail.value as string));
          setDateFrom(dateSelec);
          setStartDatePicked(true);
          console.log('ESTA ES LA FECHA DESDE CUANDO SE SETEA:', dateSelec);
          localStorage.setItem("dateFrom",''+ dateSelec);
        } else {
          setEndDate(new Date(event.detail.value as string));
          setDateUntil(dateSelec);
          setEndDatePicked(true);
          console.log('ESTA ES LA FECHA HASTA CUANDO SE SETEA:', dateSelec);
          localStorage.setItem("fechaHasta",''+ dateSelec);
        }
      }
    }

    const refreshSaldos = () => {
        callBalancesClayCompanies(dateFrom, dateUntil, tokenPSG);
    }

    const clearDateFilter = (type: string)=> {
      if (type === 'start') {
        setStartDatePicked(false);
      } else {
        setEndDatePicked(false);
      }
    }

    // const rowKeyGetter = (row: any) => {
    //  console.log('Este es el id de la fila:', row);
    //  return row;
    // }
    

    const onCellClick = async (info: any) => {
      setIndeterminateLoading(true)
      setModalMovements([])
      //Verifico que la celda clickeada sea valida para mostrar el modal de movimientos y si no muestro la alerta
      if ((info.column.key === 'PSG' || info.column.key === 'PSGPERU' || 
          info.column.key === 'CIPP' || info.column.key === 'BRYSA' || 
          info.column.key === 'HIGHFLY' || info.column.key === 'INMOBILIARIA') && info.row.accountType === 'CORRIENTE') {
            //Seteo el detalle del titulo del modal dinamico
            setDetailsModal({
              company: info.column.key,
              bank: info.row.bankAlias,
            });
            setModalIsOpen(true);
            //Filtro segun su company, bank y currency para encontrar el numero de cuenta
            const foundAccountNumber = responseClaySaldos.find((item) => {
              return (
                item.bankAlias === info.row.bankAlias && item.currency === info.row.currency && item.empresaShortName === info.column.key
              )
            })?.accountNumber

            if (foundAccountNumber !== undefined) {
              //Llamo a la API para ver si trae movimientos a mostrar por este nuemero de cuenta
               await axios.get(`${URL}/cuentas-bancarias/${foundAccountNumber}/movimientos?fechaInicio=${dateFrom}&fechaTermino=${dateUntil}`, createHeader(tokenPSG))
                 .then((response) => {
                   console.log('MOVIMIENTOS =========================>', response.data.data.response.data?.transactions);
                   if (response.data.data.response.success) {
                     setModalMovements(response.data.data.response.data?.transactions)
                   }
                   setIndeterminateLoading(false)
                 }).catch((error)=> {
                   console.error("Error: " + error);
                 })
            } else {
               setIndeterminateLoading(false)
            }
      } else {
            setAlertIsOpen(true);
      }
    }


    const formatearNumero = (numero: number): string => {
       // Usamos el objeto Intl.NumberFormat con el parámetro 'es-CL' para el formato chileno
       const formatoChileno = new Intl.NumberFormat('es-CL');
        // Formateamos el número utilizando el objeto Intl.NumberFormat
       const numeroFormateado = formatoChileno.format(numero);
       // Devolvemos el número formateado
       return numeroFormateado;
    }

    return (
    <>
      <IonCard>
        <IonLoading isOpen={loading}  message="Cargando Datos desde CLAY..." />
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>APERTURA DE EMPRESAS</IonCardTitle>
        </IonCardHeader>
        {/* Selector de fechas */}
        <IonRow class='ion-align-items-center ion-justify-content-start'>
          <IonCol size='12' sizeMd='12' sizeLg='5' sizeXl='4'>
            <IonItem lines='none'>
              <IonLabel color='dark'>Fecha desde:</IonLabel>
              <IonDatetimeButton datetime='datetimeStart'></IonDatetimeButton>
              <IonButton color='primary' fill='clear' onClick={()=> clearDateFilter('start')} disabled={!startDatePicked}>
                <IonIcon slot='icon-only' icon={trash}></IonIcon>
              </IonButton>
              <IonModal keepContentsMounted={true} onIonModalDidDismiss={refreshSaldos}>
                <IonDatetime 
                  locale='es-CL'
                  onIonChange={(e)=> onDateChange(e, 'start')}
                  presentation='date'
                  value={
                  startDatePicked
                  ? selectedStartDate.toISOString()
                  : defaultStartDate.toISOString()
                  }
                  id='datetimeStart'></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='5' sizeXl='4'>
            <IonItem lines='none'>
              <IonLabel color='dark'>Fecha hasta:</IonLabel>
              <IonDatetimeButton datetime='datetimeEnd'></IonDatetimeButton>
              <IonButton color='primary' fill='clear' onClick={()=> clearDateFilter('end')} disabled={!EndDatePicked}>
                <IonIcon slot='icon-only' icon={trash}></IonIcon>
              </IonButton>
              <IonModal keepContentsMounted={true} onIonModalDidDismiss={refreshSaldos}>
                <IonDatetime 
                  locale='es-CL'
                  onIonChange={(e)=> onDateChange(e, 'end')}
                  presentation='date'
                  value={
                  EndDatePicked
                  ? selectedEndDate.toISOString()
                  : defaultEndDate.toISOString()
                  }
                  id='datetimeEnd'></IonDatetime>
              </IonModal>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonCard>
      
      {/* Card Tipo de cambio */}
      <IonCard>
        <IonRow class='ion-align-items-left ion-justify-content-left '>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='primary'  icon={statsChartOutline} slot="start"></IonIcon>
              <IonLabel>Tipo de cambio:</IonLabel>
             </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='success'  icon={cashOutline} slot="start"></IonIcon>
              <IonLabel>DOLAR: {cambioUSD}</IonLabel>
             </IonItem>
          </IonCol>
          <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='3' color='primary'>
             <IonItem lines='none'>
              <IonIcon color='success'  icon={logoEuro} slot="start"></IonIcon>
              <IonLabel>EURO: {cambioEUR}</IonLabel>
             </IonItem>
          </IonCol>
        </IonRow>
      </IonCard>
      
      {/* Tablas */}
      {
        responseClaySaldos.length > 0 ? 
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className='bold' color='primary'>Saldos consolidados</IonCardTitle>
          </IonCardHeader>
            <ReactDataGrid style={{ height: '145px' }} className='rdg-light' columns={columnsAgrupados} rows={rowsTableAgrupados}/>         
        </IonCard> : 
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonIcon color='danger' icon={fileTrayOutline} slot="end"></IonIcon>
              <IonLabel className='bold'>No hay saldos disponibles</IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard>
      }
      {
        responseClaySaldos.length > 0 ? 
        <IonCard>
          <IonCardHeader>
            <IonCardTitle className='bold' color='primary'>Saldos por empresa y Banco Empresa y Banco</IonCardTitle>
          </IonCardHeader>
            <ReactDataGrid style={{ height: '410px' }} className='rdg-light' columns={columns} rows={rowsTable} onCellClick={(info)=> onCellClick(info)}/>
        </IonCard> : <></>
      }
    
      {/* Modal */}
      <IonModal isOpen={isModalOpen} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow class='ion-justify-content-between ion-align-items-center'>
                <IonCol size='10' sizeLg='10'>
                  <IonText className='bold' color='primary'>
                    Movimientos - (Empresa: {detailsModal.company}, Banco: {detailsModal.bank})
                  </IonText>
                </IonCol>
                <IonCol size='2' sizeMd='1' sizeLg='1' sizeXl='1'>
                  <IonButton
                    color='danger'
                    fill='clear'
                    onClick={() => {
                      setModalIsOpen(false);
                    }}>
                    <IonIcon slot='icon-only' icon={closeOutline}></IonIcon>
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonHeader>
        {indeterminateLoading ? <IonProgressBar type='indeterminate'></IonProgressBar>: <></>}
        <IonContent>
          <IonRow>
            <IonCol>
              {
                modalMovements.length === 0 ? 
                <IonItem>
                  <IonIcon color='danger' icon={fileTrayOutline} slot="end"></IonIcon>
                  <IonLabel className='bold'>No hay movimientos disponibles</IonLabel>
                </IonItem> : 
                <IonList lines="full">
                  {modalMovements.map((movement, index)=> (
                      <IonItem key={index}>
                        <IonText>{index +1}- {movement.isPayment ? 'ABONO:': 'CARGO:'}<IonChip color={movement.isPayment ? 'success': 'warning'}>{formatearNumero(movement.amount)} {movement.currency}</IonChip>-<IonChip color='primary'>{movement.description}</IonChip>/<IonChip color='primary'>{movement.transactionDate}</IonChip></IonText>
                      </IonItem>
                  ))}
                </IonList>
              }
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
       
      <IonAlert
        isOpen={isAlertOpen}
        header="¡Celda clickeada no valida!"
        message="Para ver el historial de movimientos, selecciona una celda que contenga un saldo de cualquiera de las empresas disponibles con cuenta corriente"
        buttons={['Entiendo']}
        onDidDismiss={() => setAlertIsOpen(false)}
      ></IonAlert>
    </>
    );
    };

    export default AperturaEmpresas;


