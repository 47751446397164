import axios from 'axios';
import React, { FormEvent } from 'react';
import { useAppContext } from '../common/appContext';
import { obtainAuthURL } from '../common/obtainURL';
import { AppStateReducerActionTypes } from '../common/reducer';

let URL = obtainAuthURL();

interface Props {
  codigo: string | null;
  email: string | null;
}

const ResetPassword = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [asyncState, setAsyncState] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {});

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!formDictionary.codigo) {
      setErrorMessage('Debe ingresar el código');
    } else if (!formDictionary.email) {
      setErrorMessage('Debe ingresar el email');
    } else if (!formDictionary.password) {
      setErrorMessage('Debe ingresar una contraseña');
    } else if (!formDictionary.password2) {
      setErrorMessage('Debe repetir la contraseñas');
    } else if (
      formDictionary.password.toString().length > 0 &&
      formDictionary.password.toString() !== formDictionary.password2.toString()
    ) {
      setErrorMessage('Las contraseñas no coinciden');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/password-recovery-confirm', {
          email: formDictionary.email,
          nuevaPassword: formDictionary.password,
          codigoConfirmacion: parseInt(formDictionary.codigo.toString()),
        })
        .then((result) => {
          setAsyncState(false);
          console.log(result);
          window.history.replaceState({}, document.title, '/');
          appDispatch({
            type: AppStateReducerActionTypes.SET_USER_PERMISSIONS,
            permissions_payload: result.data.data.permisos,
          });
          appDispatch({
            type: AppStateReducerActionTypes.LOGIN,
            login_payload: {
              email: result.data.data.email,
              token: result.data.data.token,
            },
          });
        })
        .catch((reason) => {
          setAsyncState(false);
          console.error(reason);
          setErrorMessage(
            'Error de ingreso: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function resetError() {
    setErrorMessage('');
  }

  return (
    <>
      <section id='reset'>
        <form onSubmit={formSubmit}>
          <p className='bold'>Renovar contraseña</p>
          <input
            type='text'
            defaultValue={props.codigo || ''}
            name='codigo'
            placeholder='Ingresar código'
            onChange={resetError}
          />
          <input
            type='email'
            defaultValue={props.email || ''}
            name='email'
            placeholder='Ingresar email'
            onChange={resetError}
          />
          <input
            type='password'
            name='password'
            placeholder='Ingresar contraseña'
            onChange={resetError}
          />
          <input
            type='password'
            name='password2'
            placeholder='Reingresar contraseña'
            onChange={resetError}
          />
          <p className='bold errorColor'>{errorMessage}</p>
          <input
            type='submit'
            disabled={asyncState}
            value='Actualizar contraseña'
            className='colorA standardSize'
            onClick={resetError}
          />
        </form>
      </section>
    </>
  );
};

export default ResetPassword;
