export function obtainURL() {
	let URL
	switch (window.location.hostname) {
		case 'localhost':
			URL = 'https://dev-api.grupopsg.net/rest';
			break;
		case 'dev.grupopsg.net':
			URL = 'https://dev-api.grupopsg.net/rest';
			break;
		case 'qa.grupopsg.net':
			URL = 'https://qa-api.grupopsg.net/rest';
			break;
		case 'grupopsg.net':
			URL = 'https://api.grupopsg.net/rest';
			break;
		default:
			URL = 'https://dev-api.grupopsg.net/rest';
	}
	return URL;
}

export function obtainAuthURL() {
	let URL
	switch (window.location.hostname) {
		case 'localhost':
			URL = 'https://dev-api.grupopsg.net/auth';
			break;
		case 'dev.grupopsg.net':
			URL = 'https://dev-api.grupopsg.net/auth';
			break;
		case 'qa.grupopsg.net':
			URL = 'https://qa-api.grupopsg.net/auth';
			break;
		case 'grupopsg.net':
			URL = 'https://api.grupopsg.net/auth';
			break;
		default:
			URL = 'https://dev-api.grupopsg.net/auth';
	}
	return URL;
}

export function obtainURLBackendClay() {
	let URL
	switch (window.location.hostname) {
		case 'dev.grupopsg.net':
			URL = 'https://dev-api.grupopsg.net/clay';
			break;
		case 'qa.grupopsg.net':
			URL = 'https://qa-api.grupopsg.net/clay';
			break;
		case 'grupopsg.net':
			URL = 'https://api.grupopsg.net/clay';
			break;
		default:
			URL = 'https://dev-api.grupopsg.net/clay';
	}
	return URL;
}