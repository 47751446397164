export const almacen: Array<any> = [
  {
    name: '000-Pre-Bodega Central',
    code: '000',
  },
  {
    name: '01-Almacen General',
    code: '01',
  },
  {
    name: '013-CIPP Stgo Bodega Central',
    code: '013',
  },
  {
    name: '800-Bodega Storetek',
    code: '800',
  },
];

export const articulo: Array<any> = [
  {
    name: 'SERVICIO DE REHABILITACION (SIN OF)',
    code: '90430008',
    grupo: 'SERVICIOS SANITARIOS',
    emision: 'Notificacion',
  },
  {
    name: 'SERVICIO LIMPIEZA ITV (SIN OF)',
    code: '90430009',
    grupo: 'SERVICIOS SANITARIOS',
    emision: 'Notificacion',
  },
  {
    name: 'SERVICIO TELEINSPECCION',
    code: '50201001',
    grupo: 'SERVICIOS SANITARIOS',
    emision: 'Manual',
  },
];

export const negocio: Array<any> = [
  {
    name: 'OPERACIONES',
    code: '03',
  },
  {
    name: 'COMERCIAL',
    code: '04',
  },
];

export const costo: Array<any> = [
  {
    name: 'OPERACIONES',
    code: '0300',
  },
  {
    name: 'EQUIPO TECNICO',
    code: '0340',
  },
  {
    name: 'GERENCIA',
    code: '0415',
  },
  {
    name: 'ADMINISTRACION COMERCIAL',
    code: '0416',
  },

  {
    name: 'PERCO SERVICIOS Y ASESORIAS',
    code: '0417',
  },

  {
    name: 'ITV y LIMPIEZA',
    code: '0441',
  },
  {
    name: 'REHABILITACION',
    code: '0443',
  },
  {
    name: 'IMPREGNACION',
    code: '0445',
  },
  {
    name: 'DESAGRIPADO',
    code: '0446',
  },
  {
    name: 'OTROS SERVICIOS',
    code: '0449',
  },
  {
    name: 'OTROS COSTOS DE OPERACION',
    code: '0460',
  },
  {
    name: 'OH CORPORATIVO',
    code: '0490',
  },
];

export const moneda: Array<any> = [
  { name: 'Monedas(Todas)', cod: 'MD' },
  {
    name: 'Dolar Americano',
    cod: 'USD',
  },
  {
    name: 'Dolar Australiano',
    cod: 'USDA',
  },
  {
    name: 'Dolar Canadiense',
    cod: 'USDc',
  },
  {
    name: 'Euro',
    cod: 'ER',
  },
  {
    name: 'Libra Esterlina',
    cod: 'ls',
  },
  {
    name: 'Peso Argentino',
    cod: 'Ps',
  },
  {
    name: 'Peso Chileno',
    cod: 'Pc',
  },
  {
    name: 'Pesos Chilenos 1',
    cod: 'pc1',
  },
  {
    name: 'Unidad De Fomento',
    cod: 'UF',
  },
  {
    name: 'Yen Japones',
    cod: 'Yj',
  },
];

export const rubro: Array<any> = [
  { name: 'Ventas', cod: '01' },
  {
    name: 'Varios',
    cod: '02',
  },
  {
    name: 'Utiles Oficina',
    cod: '03',
  },
  {
    name: 'Trat. Desechos',
    cod: '04',
  },
  {
    name: 'Trat. Aguas',
    cod: '05',
  },
  {
    name: 'TRansportes',
    cod: '06',
  },
  {
    name: 'Supermercado',
    cod: '07',
  },
  {
    name: '-Sin Industria-',
    cod: '08',
  },
  {
    name: 'Servicios',
    cod: '09',
  },
  {
    name: 'Seguros',
    cod: '10',
  },
  {
    name: 'Sanitaria',
    cod: '11',
  },
  {
    name: 'Restaurant',
    cod: '12',
  },
  {
    name: 'Repuestos',
    cod: '13',
  },
  {
    name: 'Otros',
    cod: '14',
  },
  {
    name: 'Mineria',
    cod: '15',
  },
  {
    name: 'Lubricantes',
    cod: '16',
  },
  {
    name: 'Inmobiliaria',
    cod: '17',
  },
  {
    name: 'Hoteles',
    cod: '18',
  },
  {
    name: 'Hoteleria',
    cod: '19',
  },
  {
    name: 'Ferreteria',
    cod: '20',
  },
];

export const pago: Array<any> = [
  { name: 'ANTICIPO EFECTIVO', cod: '01' },
  {
    name: 'CAD',
    cod: '02',
  },
  {
    name: 'CARTA DE CREDITO',
    cod: '03',
  },
  {
    name: 'CASH IN ADVANCE',
    cod: '04',
  },
  {
    name: 'CH 00/30/60/90/120 DS C/ENTREGA',
    cod: '05',
  },
  {
    name: 'CH 30 DS C/ENTREGA',
    cod: '06',
  },
  {
    name: 'CH 30/60',
    cod: '07',
  },
  {
    name: 'CH 30/60/90',
    cod: '08',
  },
  {
    name: 'CH 30/60/90/120',
    cod: '09',
  },
  {
    name: 'CH 45 DS C/ENTREGA',
    cod: '10',
  },
  {
    name: 'CH 60 DS C/ ENTREGA',
    cod: '11',
  },
  {
    name: 'CH AL DIA /CH 30 DS',
    cod: '12',
  },
  {
    name: 'CH AL DIA /30/60 DS',
    cod: '13',
  },
  {
    name: 'CH AL DIA /30/60/90',
    cod: '14',
  },
  {
    name: 'CH AL DIA C/ENTREGA',
    cod: '15',
  },
  {
    name: 'CH/ENTREGA 45-60 D',
    cod: '16',
  },
  {
    name: 'CH/ENTREGA 30-60',
    cod: '17',
  },
  {
    name: 'Contado',
    cod: '18',
  },
  {
    name: 'CONTADO CTRA FACTURA',
    cod: '19',
  },
  {
    name: 'Contado/ch.30-60',
    cod: '20',
  },
  {
    name: 'CRED 140 DIAS FEC.FACT',
    cod: '21',
  },

  {
    name: 'CRED 15 DS FEC.FACT',
    cod: '22',
  },
  {
    name: 'CRED 30 DS FEC.FACT',
    cod: '23',
  },
  {
    name: 'CRED 30/60',
    cod: '24',
  },
  {
    name: 'CRED 45 DS FEC.FACT',
    cod: '25',
  },
  {
    name: 'CRED 60 DS FEC.FACT',
    cod: '26',
  },
  {
    name: 'Cred 7 dias',
    cod: '27',
  },
  {
    name: 'CRED 75 DIAS FEC.FACT',
    cod: '28',
  },
  {
    name: 'CRED 365 ds FEC.FACT',
    cod: '29',
  },
  {
    name: 'Cuotas 30,60,90 s/int.',
    cod: '30',
  },
  {
    name: 'Draf payable at 60 days from shipment date',
    cod: '31',
  },
  {
    name: 'L/C 60 D SUPP CREDIT',
    cod: '32',
  },
  {
    name: 'L/C 90 D SUPP CREDIT',
    cod: '33',
  },
  {
    name: 'LEASING',
    cod: '34',
  },
  {
    name: 'TRANSF/ EFECTIVO',
    cod: '35',
  },
];

export const indicaImpuesto: Array<any> = [
  {
    cod: 'IVA',
    name: 'IVA Credito y Debito Fiscal',
    tasa: '19.00',
  },
  {
    cod: 'IVA_EXE',
    name: 'IVA EXCENTO',
    tasa: '00.00',
  },
];

export const regiones: Array<any> = [
  {
    region: 'Arica y Parinacota',
    comunas: ['Arica', 'Camarones', 'Putre', 'General Lagos'],
  },
  {
    region: 'Tarapacá',
    comunas: [
      'Iquique',
      'Alto Hospicio',
      'Pozo Almonte',
      'Camiña',
      'Colchane',
      'Huara',
      'Pica',
    ],
  },
  {
    region: 'Antofagasta',
    comunas: [
      'Antofagasta',
      'Mejillones',
      'Sierra Gorda',
      'Taltal',
      'Calama',
      'Ollagüe',
      'San Pedro de Atacama',
      'Tocopilla',
      'María Elena',
    ],
  },
  {
    region: 'Atacama',
    comunas: [
      'Copiapó',
      'Caldera',
      'Tierra Amarilla',
      'Chañaral',
      'Diego de Almagro',
      'Vallenar',
      'Alto del Carmen',
      'Freirina',
      'Huasco',
    ],
  },
  {
    region: 'Coquimbo',
    comunas: [
      'La Serena',
      'Coquimbo',
      'Andacollo',
      'La Higuera',
      'Paiguano',
      'Vicuña',
      'Illapel',
      'Canela',
      'Los Vilos',
      'Salamanca',
      'Ovalle',
      'Combarbalá',
      'Monte Patria',
      'Punitaqui',
      'Río Hurtado',
    ],
  },
  {
    region: 'Valparaíso',
    comunas: [
      'Valparaíso',
      'Casablanca',
      'Concón',
      'Juan Fernández',
      'Puchuncaví',
      'Quintero',
      'Viña del Mar',
      'Isla de Pascua',
      'Los Andes',
      'Calle Larga',
      'Rinconada',
      'San Esteban',
      'La Ligua',
      'Cabildo',
      'Papudo',
      'Petorca',
      'Zapallar',
      'Quillota',
      'Calera',
      'Hijuelas',
      'La Cruz',
      'Nogales',
      'San Antonio',
      'Algarrobo',
      'Cartagena',
      'El Quisco',
      'El Tabo',
      'Santo Domingo',
      'San Felipe',
      'Catemu',
      'Llaillay',
      'Panquehue',
      'Putaendo',
      'Santa María',
      'Quilpué',
      'Limache',
      'Olmué',
      'Villa Alemana',
    ],
  },
  {
    region: 'Región del Libertador Gral. Bernardo O’Higgins',
    comunas: [
      'Rancagua',
      'Codegua',
      'Coinco',
      'Coltauco',
      'Doñihue',
      'Graneros',
      'Las Cabras',
      'Machalí',
      'Malloa',
      'Mostazal',
      'Olivar',
      'Peumo',
      'Pichidegua',
      'Quinta de Tilcoco',
      'Rengo',
      'Requínoa',
      'San Vicente',
      'Pichilemu',
      'La Estrella',
      'Litueche',
      'Marchihue',
      'Navidad',
      'Paredones',
      'San Fernando',
      'Chépica',
      'Chimbarongo',
      'Lolol',
      'Nancagua',
      'Palmilla',
      'Peralillo',
      'Placilla',
      'Pumanque',
      'Santa Cruz',
    ],
  },
  {
    region: 'Región del Maule',
    comunas: [
      'Talca',
      'Constitución',
      'Curepto',
      'Empedrado',
      'Maule',
      'Pelarco',
      'Pencahue',
      'Río Claro',
      'San Clemente',
      'San Rafael',
      'Cauquenes',
      'Chanco',
      'Pelluhue',
      'Curicó',
      'Hualañé',
      'Licantén',
      'Molina',
      'Rauco',
      'Romeral',
      'Sagrada Familia',
      'Teno',
      'Vichuquén',
      'Linares',
      'Colbún',
      'Longaví',
      'Parral',
      'Retiro',
      'San Javier',
      'Villa Alegre',
      'Yerbas Buenas',
    ],
  },
  {
    region: 'Región de Ñuble',
    comunas: [
      'Cobquecura',
      'Coelemu',
      'Ninhue',
      'Portezuelo',
      'Quirihue',
      'Ránquil',
      'Treguaco',
      'Bulnes',
      'Chillán Viejo',
      'Chillán',
      'El Carmen',
      'Pemuco',
      'Pinto',
      'Quillón',
      'San Ignacio',
      'Yungay',
      'Coihueco',
      'Ñiquén',
      'San Carlos',
      'San Fabián',
      'San Nicolás',
    ],
  },
  {
    region: 'Región del Biobío',
    comunas: [
      'Concepción',
      'Coronel',
      'Chiguayante',
      'Florida',
      'Hualqui',
      'Lota',
      'Penco',
      'San Pedro de la Paz',
      'Santa Juana',
      'Talcahuano',
      'Tomé',
      'Hualpén',
      'Lebu',
      'Arauco',
      'Cañete',
      'Contulmo',
      'Curanilahue',
      'Los Álamos',
      'Tirúa',
      'Los Ángeles',
      'Antuco',
      'Cabrero',
      'Laja',
      'Mulchén',
      'Nacimiento',
      'Negrete',
      'Quilaco',
      'Quilleco',
      'San Rosendo',
      'Santa Bárbara',
      'Tucapel',
      'Yumbel',
      'Alto Biobío',
    ],
  },
  {
    region: 'Región de la Araucanía',
    comunas: [
      'Temuco',
      'Carahue',
      'Cunco',
      'Curarrehue',
      'Freire',
      'Galvarino',
      'Gorbea',
      'Lautaro',
      'Loncoche',
      'Melipeuco',
      'Nueva Imperial',
      'Padre las Casas',
      'Perquenco',
      'Pitrufquén',
      'Pucón',
      'Saavedra',
      'Teodoro Schmidt',
      'Toltén',
      'Vilcún',
      'Villarrica',
      'Cholchol',
      'Angol',
      'Collipulli',
      'Curacautín',
      'Ercilla',
      'Lonquimay',
      'Los Sauces',
      'Lumaco',
      'Purén',
      'Renaico',
      'Traiguén',
      'Victoria',
    ],
  },
  {
    region: 'Región de Los Ríos',
    comunas: [
      'Valdivia',
      'Corral',
      'Lanco',
      'Los Lagos',
      'Máfil',
      'Mariquina',
      'Paillaco',
      'Panguipulli',
      'La Unión',
      'Futrono',
      'Lago Ranco',
      'Río Bueno',
    ],
  },
  {
    region: 'Región de Los Lagos',
    comunas: [
      'Puerto Montt',
      'Calbuco',
      'Cochamó',
      'Fresia',
      'Frutillar',
      'Los Muermos',
      'Llanquihue',
      'Maullín',
      'Puerto Varas',
      'Castro',
      'Ancud',
      'Chonchi',
      'Curaco de Vélez',
      'Dalcahue',
      'Puqueldón',
      'Queilén',
      'Quellón',
      'Quemchi',
      'Quinchao',
      'Osorno',
      'Puerto Octay',
      'Purranque',
      'Puyehue',
      'Río Negro',
      'San Juan de la Costa',
      'San Pablo',
      'Chaitén',
      'Futaleufú',
      'Hualaihué',
      'Palena',
    ],
  },
  {
    region: 'Región Aisén del Gral. Carlos Ibáñez del Campo',
    comunas: [
      'Coihaique',
      'Lago Verde',
      'Aisén',
      'Cisnes',
      'Guaitecas',
      'Cochrane',
      'O’Higgins',
      'Tortel',
      'Chile Chico',
      'Río Ibáñez',
    ],
  },
  {
    region: 'Región de Magallanes y de la Antártica Chilena',
    comunas: [
      'Punta Arenas',
      'Laguna Blanca',
      'Río Verde',
      'San Gregorio',
      'Cabo de Hornos (Ex Navarino)',
      'Antártica',
      'Porvenir',
      'Primavera',
      'Timaukel',
      'Natales',
      'Torres del Paine',
    ],
  },
  {
    region: 'Región Metropolitana de Santiago',
    comunas: [
      'Cerrillos',
      'Cerro Navia',
      'Conchalí',
      'El Bosque',
      'Estación Central',
      'Huechuraba',
      'Independencia',
      'La Cisterna',
      'La Florida',
      'La Granja',
      'La Pintana',
      'La Reina',
      'Las Condes',
      'Lo Barnechea',
      'Lo Espejo',
      'Lo Prado',
      'Macul',
      'Maipú',
      'Ñuñoa',
      'Pedro Aguirre Cerda',
      'Peñalolén',
      'Providencia',
      'Pudahuel',
      'Quilicura',
      'Quinta Normal',
      'Recoleta',
      'Renca',
      'Santiago',
      'San Joaquín',
      'San Miguel',
      'San Ramón',
      'Vitacura',
      'Puente Alto',
      'Pirque',
      'San José de Maipo',
      'Colina',
      'Lampa',
      'Tiltil',
      'San Bernardo',
      'Buin',
      'Calera de Tango',
      'Paine',
      'Melipilla',
      'Alhué',
      'Curacaví',
      'María Pinto',
      'San Pedro',
      'Talagante',
      'El Monte',
      'Isla de Maipo',
      'Padre Hurtado',
      'Peñaflor',
    ],
  },
];

export const infoControlView: any = {
  exchangeRates: [
      {
          reportedDate: '2023-08-16',
          currency: 'EUR',
          exchangeRate: '936.17',
          updatedAt: '2023-08-16T10:20:02'
      },
      {
          reportedDate: '2023-08-16',
          currency: 'USD',
          exchangeRate: '859.87',
          updatedAt: '2023-08-16T10:20:02'
      }
  ],
  dataHeaders: [
      { shortName: 'BRYSA', title: 'BRYSA'},
      { shortName: 'CIPP', title: 'CIPP'},
      { shortName: 'HIGHFLY', title: 'HIGHFLY'},
      { shortName: 'INMOBILIARIA', title: 'INMOBILIARIA'},
      { shortName: 'PSG', title: 'PSG'},
      { shortName: 'PSGPERU', title: 'PSGPERU'},
      // { shortName: 'rowSum', title: 'TOTAL'},
  ],
  pivot: [
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Chile Banconexion',
          currency: 'CLP',
          companies: {
            BRYSA: { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 1842888, sapBalance: 1842888, hasDifference: false },
            CIPP: { shortName: 'CIPP', accountNumber: 999999999, realBalance: 1158240, sapBalance: 1012000, hasDifference: true },
            HIGHFLY: { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 486841, sapBalance: 486841, hasDifference: false },
            INMOBILIARIA: { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 372254, sapBalance: 372254, hasDifference: false },
            PSG: { shortName: 'PSG', accountNumber: 999999999, realBalance: 1055298, sapBalance: 1055298, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 34340987, sapBalance: 34340987, hasDifference: false },
          },
          rowSum: 39256508
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Itau',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 1561922, sapBalance: 1561922, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 742875, sapBalance: 742875, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 891099, sapBalance: 891099, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 3195896
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Estado',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 1334655, sapBalance: 1334655, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 4022837, sapBalance: 4022837, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 5357492
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'BCI360Connect',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 8720973, sapBalance: 8720973, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 18529473, sapBalance: 18529473, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 27250446
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Chile Banconexion',
          currency: 'USD',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 154.53, sapBalance: 154.53, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 1950, sapBalance: 2000, hasDifference: true },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 2154.53
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Security',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 232873, sapBalance: 232873, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 610111, sapBalance: 650111, hasDifference: true },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 1206608, sapBalance: 1206608, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 2049592
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Security',
          currency: 'USD',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 2108.55, sapBalance: 2108.55, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 2108.55
      },
      {
          accountType: 'CORRIENTE',
          bankAlias: 'Scotiabank',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 776192, sapBalance: 776192, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 1448452, sapBalance: 1448452, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 2224644
      },
      {
          accountType: 'TOTAL CORRIENTE',
          bankAlias: '-',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 16282581.8885, sapBalance: 16282581.8885, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 26644863.7111, sapBalance: 26644863.7111, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 486841, sapBalance: 486841, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 1263353, sapBalance: 1263353, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 3981646, sapBalance: 3981646, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 34340987, sapBalance: 34340987, hasDifference: false }
          },
          rowSum: 83000272.5996
      },
      {
          accountType: 'TOTAL INVERSION',
          bankAlias: '-',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 0, sapBalance: 0, hasDifference: false }
          },
          rowSum: 0
      },
      {
          accountType: 'TOTAL GENERAL',
          bankAlias: '-',
          currency: 'CLP',
          companies: {
            BRYSA : { shortName: 'BRYSA', accountNumber: 999999999, realBalance: 16282581.8885, sapBalance: 16282581.8885, hasDifference: false },
            CIPP : { shortName: 'CIPP', accountNumber: 999999999, realBalance: 26644863.7111, sapBalance: 26644863.7111, hasDifference: false },
            HIGHFLY : { shortName: 'HIGHFLY', accountNumber: 999999999, realBalance: 486841, sapBalance: 486841, hasDifference: false },
            INMOBILIARIA : { shortName: 'INMOBILIARIA', accountNumber: 999999999, realBalance: 1263353, sapBalance: 1263353, hasDifference: false },
            PSG : { shortName: 'PSG', accountNumber: 999999999, realBalance: 3981646, sapBalance: 3981646, hasDifference: false },
            PSGPERU: { shortName: 'PSGPERU', accountNumber: 999999999, realBalance: 34340987, sapBalance: 34340987, hasDifference: false }
          },
          rowSum: 83000272.5996
      }
  ]
};

// export interface Regiones {
//   id: number;
//   nombre: string;
// }

// export const regionesChile = async function getRegions(): Promise<Regiones[]> {
//   try {
//     const response = await fetch('https://apis.modernizacion.cl/dpa/regiones');
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };
