import axios from 'axios';
import  { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { obtainURLBackendClay } from '../../common/obtainURL';
import { LogsEmpresaClay } from '../../interfaces/LogsEmpresaClay';
import { createHeader } from '../../common/auth';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes, EmpresasDictionary } from '../../common/reducer';
import {IonCol,IonButton,IonDatetimeButton,IonDatetime,IonRow,IonCard,IonIcon,IonItem,IonLabel,IonSelect,IonSelectOption,IonLoading,IonModal, IonCardHeader, IonCardTitle, IonCardContent, IonToast, IonAlert,} from '@ionic/react';
import {trash,globe,cloudDownloadOutline, fileTrayOutline} from 'ionicons/icons';
import { IonDatetimeCustomEvent, DatetimeChangeEventDetail,} from '@ionic/core/components';

let URL = obtainURLBackendClay();

const ImportacionClay = () => {

    interface Empresa {realName: string;rut: string;}
    const selectedEmpresaDefault: Empresa | null = null;
    const empresasDefault: Empresa[] = [];
    const [empresas, setEmpresas] = useState(empresasDefault);
    const [selectedEmpresa, setSelectedEmpresa] = useState(selectedEmpresaDefault);
    const {dispatch: appDispatch} = useAppContext();
    const [rows, setRows] = useState([]);
    const [messageResponse, setMessageResponse] = useState('');
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(),currentDate.getMonth(), 1);
    const defaultEndDate = new Date(currentDate.getFullYear(),currentDate.getMonth(), currentDate.getDate());
    const [selectedStartDate, setStartDate] = useState(defaultStartDate);
    const [selectedEndDate, setEndDate] = useState(defaultEndDate);
    const [startDatePicked, setStartDatePicked] = useState(false);
    const [endDatePicked, setEndDatePicked] = useState(false);
    const [dateFrom, setDateFrom] = useState('');
    // const [dateUntil, setDateUntil] = useState('');
    const [tokenPSG, setTokenPSG] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingLogs, setLoadingLogs] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const [colorToast, setColorToast] = useState('');
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const columns: TableColumn < LogsEmpresaClay > [] = [
    { name: 'ID Carga ',            selector: (row) => row.loadId,            sortable: true},
    { name: 'INICIO',               selector: (row) => row.reportedStartDate, sortable: true},
    { name: 'TERMINO',              selector: (row) => row.reportedEndDate,   sortable: true,                                                                                                        },
    { name: 'REGISTROS IMPORTADOS', selector: (row) => row.loadedRows,        sortable: true},
    { name: 'EJECUTADO POR:',       selector: (row) => row.executedByUser,    sortable: true}
    ]

  useEffect(() => { 
      // const fechaActual = new Date()
      // const yyyy = fechaActual.getFullYear()
      // const mm = String(fechaActual.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
      // const dd = String(fechaActual.getDate()).padStart(2, '0')
      // const dateFrom = `${yyyy}-${mm}-${1}`;
      // const dateUntil = `${yyyy}-${mm}-${dd}`;
      // setDateUntil(dateUntil) //Siempre parado en el dia anterior del dia actual de cada mes

      // Formatear la fecha como "YYYY-MM-DD"
      const dateFrom = `${defaultStartDate.getFullYear()}-${(defaultStartDate.getMonth() + 1).toString().padStart(2, '0')}-${defaultStartDate.getDate().toString().padStart(2, '0')}`;
      setDateFrom(dateFrom); //Siempre parado en el primer dia de cada mes 
      const token = JSON.parse(""+localStorage.getItem("user") ).token;
      setTokenPSG(token);
      loadLogsData(token);
      loadListBusiness(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   const loadLogsData = async ( token:string )=>  {
      try {
        setLoadingLogs(true);
        const response = await axios.get(`${URL}/log-carga`, createHeader(token));
        
        let rows: any = [];
        response.data.data.response[1].forEach((LogsEmpresaClayDet: LogsEmpresaClay) => {
          rows.push({
            loadId:              LogsEmpresaClayDet.loadId,
            reportedStartDate:   LogsEmpresaClayDet.reportedStartDate,
            reportedEndDate:     LogsEmpresaClayDet.reportedEndDate,
            loadedRows:          LogsEmpresaClayDet.loadedRows,
            executedByUser:      LogsEmpresaClayDet.executedByUser,
          });
        });

        setRows(rows);
        setLoadingLogs(false);
    } catch (error) {
        console.error("Error: " + error);
        setMessageResponse("Error en autorizacion, por favor vuelve a iniciar sesion: " + error + " - method: loadLogsData");
        setLoadingLogs(false);
        tokenInvalido();
    }
  }

  const  loadListBusiness = async (token: string) => {
    try {
      const response = await axios.get(URL + '/empresas', createHeader(token));

      const empresasDictionary: EmpresasDictionary = {};
      const rows = response.data.data.response[1].map((empresa: Empresa) => {
        empresasDictionary[empresa.rut] = empresa.realName;
        return { rut: empresa.rut, realName: empresa.realName };
      });

      setEmpresas(rows);
      appDispatch({
        type: AppStateReducerActionTypes.SAVE_EMPRESAS,
        empresas_payload: empresasDictionary,
      });
    } catch (error) {
      console.error("loadListBusiness - Error: " + error);
      setMessageResponse(
        "Error en autorización, por favor vuelve a iniciar sesión: " +
          error+
          " - method: loadListBusiness"
      );
      tokenInvalido();
    }
  }

  const importData = async () => {
    try {
      let dataRequest: any;
      if (selectedEmpresa == null) {
        dataRequest = {
          fechaInicio: dateFrom,
          fechaTermino: dateFrom,
        };
      } else {
        dataRequest = {
          fechaInicio: dateFrom,
          fechaTermino: dateFrom,
          rutEmpresa: selectedEmpresa,
        };
      }
 
      setLoading(true);

      await axios.post(
        `${URL}/cuentas/saldos/import`,
        dataRequest,
        createHeader(tokenPSG)
      );

      setLoading(false);
      setColorToast('success');
      setMessageResponse('Saldos importados desde Clay correctamente');
      setIsOpen(true);
      appDispatch({
        type: AppStateReducerActionTypes.SHOW_IMPORTACION_CLAY,
      });
      setTimeout(() => {
        loadLogsData(tokenPSG)
      }, 2000);
    } catch (error) {
      setColorToast('warning');
      setMessageResponse('Error al Importacion de datos desde CLAY.');
      setMessageResponse('Error: ' + error);
      setIsOpen(true);
      setLoading(false);
      tokenInvalido();
    }
};


  const onDateChange = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>, type: string) =>  {
    if (event.detail.value) {
       let date =  new Date(event.detail.value as string);
       let yyyy = new Date(event.detail.value as string).getFullYear()
       let mm = String(date.getMonth() + 1).padStart(2, '0') // Agrega 1 al mes, ya que los meses se indexan desde 0
       let dd = String(date.getDate()).padStart(2, '0')
       let dateSelec = `${yyyy}-${mm}-${dd}`;
       console.log('dateSelec:', dateSelec);
       if (type === 'start') {
         setStartDate(new Date(event.detail.value as string));
         setDateFrom(dateSelec)
         setStartDatePicked(true);
       } else {
         setEndDate(new Date(event.detail.value as string));
        //  setDateUntil(dateSelec)
         setEndDatePicked(true);
       }
    }
  }

  const clearDateFilter = (type: string)=> {
      if (type === 'start') {
        setStartDatePicked(false);
      } else {
        setEndDatePicked(false);
      }
    }

  const onSelectChanged = (data: any) => {
    if (!data) return;
    setSelectedEmpresa(data.detail.value);
  
  }

  const tokenInvalido =() => {
    setTimeout(function(){
      console.log("cerrar sesion");
      localStorage.removeItem('user');
      localStorage.removeItem('screen');
      localStorage.removeItem('sapcredentials');
      appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
    }, 4000);
  }

 return (
    <>
      <IonLoading isOpen={loading}  message="Importando datos desde CLAY ..." />
      <IonLoading isOpen={loadingLogs}  message="Cargando Logs de importacion CLAY..." />
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Importación de datos desde CLAY:</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow class='ion-align-items-center ion-justify-content-start'>
            <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='4'>
              <IonItem lines='none'>
                <IonLabel color='dark'>Fecha de importación:</IonLabel>
                <IonDatetimeButton datetime='datetimeStart'></IonDatetimeButton>
                <IonButton
                  color='primary'
                  fill='clear'
                  onClick={()=> clearDateFilter('start')}
                  disabled={!startDatePicked}>
                  <IonIcon slot='icon-only' icon={trash}></IonIcon>
                </IonButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    locale='es-CL'
                    onIonChange={(e)=> onDateChange(e, 'start')}
                    presentation='date'
                    value={
                      startDatePicked
                        ? selectedStartDate.toISOString()
                        : defaultStartDate.toISOString()
                    }
                    id='datetimeStart'></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol>
            {/* <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='4'>
              <IonItem lines='none'>
                <IonLabel color='dark'>Fecha hasta:</IonLabel>
                <IonDatetimeButton datetime='datetimeEnd'></IonDatetimeButton>
                <IonButton
                  color='primary'
                  fill='clear'
                  onClick={()=> clearDateFilter('end')}
                  disabled={!endDatePicked}>
                  <IonIcon slot='icon-only' icon={trash}></IonIcon>
                </IonButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    locale='es-CL'
                    onIonChange={(e)=> onDateChange(e, 'end')}
                    presentation='date'
                    value={endDatePicked ? selectedEndDate.toISOString() : defaultEndDate.toISOString()}
                    id='datetimeEnd'></IonDatetime>
                </IonModal>
              </IonItem>
            </IonCol> */}
             <IonCol size='12' sizeMd='12' sizeLg='4' sizeXl='4'>
              <IonItem>
                <IonLabel position='floating'>Seleccione una empresa</IonLabel>
                <IonSelect
                  interface="popover"
                  onIonChange={onSelectChanged}
                  disabled={empresas.length < 2}>
                  <IonSelectOption value={null}>Ninguna</IonSelectOption>
                    {
                      empresas.map((empresa) => (<IonSelectOption value={empresa.rut} key={empresa.rut}>{empresa.realName}</IonSelectOption>))
                    }
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonButton
                  fill='solid'
                  
                  color='primary'
                  expand='block'
                  onClick={()=> setAlertIsOpen(true)}
                  >
                  <IonIcon slot='start' icon={cloudDownloadOutline}></IonIcon>
                  Importar desde CLAY
            </IonButton>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle className='bold' color='primary'>Logs transaccionales</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
        {rows.length > 0 ?
        
        <DataTable
          dense 
          striped
          columns={columns}
          data={rows}
          pagination
        /> :
          <IonCardContent>
            <IonItem>
              <IonIcon color='danger' icon={fileTrayOutline} slot="end"></IonIcon>
              <IonLabel className='bold'>No hay logs disponibles</IonLabel>
            </IonItem>
          </IonCardContent>
        }
        </IonCardContent>
      </IonCard>
      <IonToast
                isOpen={isOpen}
                message={messageResponse}
                onDidDismiss={() => setIsOpen(false)}
                duration={4000}
                icon={globe}
                color={colorToast}
      ></IonToast>
      <IonAlert
        isOpen={isAlertOpen}
        header='Importar desde Clay'
        message='Se importará y actualizará la información de saldos bancarios encontrada en Clay. ¿Desea continuar?'
        buttons={[
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: ()=> {
              setAlertIsOpen(false)
            }
          },
          {
            text: 'Confirmar',
            role: 'confirm',
            handler: ()=> {
              importData()
              setAlertIsOpen(false)
            }
          }
        ]}
        onDidDismiss={() => setAlertIsOpen(false)}
      ></IonAlert>
    </>
  );
};

export default ImportacionClay;
