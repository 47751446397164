import {
  IonButton,
  IonCol,
  IonContent,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import axios from 'axios';
import React, { FormEvent, useState } from 'react';
import { useAppContext } from '../common/appContext';
import { obtainAuthURL } from '../common/obtainURL';
import { AppStateReducerActionTypes } from '../common/reducer';
import { arrowBackOutline, checkmarkDoneOutline } from 'ionicons/icons';
let URL = obtainAuthURL();

const Registration = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [asyncState, setAsyncState] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [showLoading, setShowLoading] = useState(false);

  React.useEffect(() => {});

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    // console.log(event);

    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!validateEmail(formDictionary.email.toString())) {
      setErrorMessage('Email no válido');
    } else if (!formDictionary.password) {
      setErrorMessage('Debe ingresar una contraseña');
    } else if (!formDictionary.password2) {
      setErrorMessage('Debe repetir la contraseñas');
    } else if (
      formDictionary.password.toString().length > 0 &&
      formDictionary.password.toString() !== formDictionary.password2.toString()
    ) {
      setErrorMessage('Las contraseñas no coinciden');
    } else if (!formDictionary.tyc) {
      setErrorMessage('Debe aceptar los términos y condiciones');
    } else {
      setAsyncState(true);
      setShowLoading(true);
      axios
        .post(URL + '/sign-up', {
          email: formDictionary.email,
          password: formDictionary.password,
        })
        .then((result) => {
          setAsyncState(false);

          appDispatch({
            type: AppStateReducerActionTypes.CONFIRM_REGISTRATION,
            confirmation_payload: { code: '', email: result.data.data.email },
          });
        })
        .catch((reason) => {
          setShowLoading(false);
          setAsyncState(false);

          console.error(reason);
          setErrorMessage(
            'Error de registración: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function back() {
    appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
  }

  function resetError() {
    setErrorMessage('');
  }

  return (
    <IonPage>
      <IonContent>
        <IonRow class='ion-justify-content-start ion-padding'>
          <IonCol size='3'>
            <IonButton onClick={back} color='tertiary' size='default' shape='round'>
              <IonIcon slot='start' icon={arrowBackOutline}></IonIcon>
              Regresar
            </IonButton>
          </IonCol>
        </IonRow>
        <form onSubmit={formSubmit}>
          <IonRow class='ion-padding ion-margin-bottom'>
            <IonCol size='12'>
              <IonText class='ion-text-center' color='dark'>
                <h2 className='bold'>Ingrese los datos de registro:</h2>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow class='ion-justify-content-center ion-margin-bottom'>
            <IonCol size='11' sizeLg='10'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Ingresar correo
                </IonLabel>
                <IonInput
                  onIonChange={resetError}
                  type='email'
                  name='email'></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='11' sizeLg='10'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Ingresar contraseña
                </IonLabel>
                <IonInput
                  onIonChange={resetError}
                  type='password'
                  name='password'></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='11' sizeLg='10'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Reingresar contraseña
                </IonLabel>
                <IonInput
                  onIonChange={resetError}
                  type='password'
                  name='password2'></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow class='ion-justify-content-center ion-margin-bottom'>
            <IonCol size='11' sizeLg='10'>
              <IonItem lines='none'>
                <IonCheckbox
                  slot='start'
                  name='tyc'
                  onIonChange={resetError}></IonCheckbox>
                <IonLabel>
                  Acepto los{' '}
                  <a href='terminosycondiciones.pdf'>Términos y Condiciones</a>
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonText class='ion-text-center' color='danger' className='bold'>
              {errorMessage}
            </IonText>
          </IonRow>
          <IonRow class='ion-justify-content-center'>
            <IonCol size='11' sizeLg='10' class='ion-margin-top'>
              <IonButton
                color='primary'
                size='default'
                expand='block'
                disabled={asyncState}
                type='submit'
                onClick={resetError}>
                <IonLoading
                  cssClass='my-custom-class'
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={'Registrando...'}
                />
                Registrarme
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
    // <>
    //   <section id="registration">
    //     <button type="button" className="flexLeft justText" onClick={back}>
    //       <span className="bold">Regresar</span>
    //     </button>
    //     <form onSubmit={formSubmit}>
    //       <p className="bold">Ingrese los datos de registro</p>
    //       <input
    //         type="email"
    //         name="email"
    //         placeholder="Ingresar correo"
    //         onChange={resetError}
    //       />
    //       <input
    //         type="password"
    //         name="password"
    //         placeholder="Ingresar contraseña"
    //         onChange={resetError}
    //       />
    //       <input
    //         type="password"
    //         name="password2"
    //         placeholder="Reingresar contraseña"
    //         onChange={resetError}
    //       />
    //       <div>
    //         <input type="checkbox" name="tyc" onChange={resetError} />
    //         <label>
    //           Acepto los{' '}
    //           <a href="terminosycondiciones.pdf">Términos y Condiciones</a>
    //         </label>
    //       </div>
    //       <p className="bold errorColor">{errorMessage}</p>
    //       <input
    //         type="submit"
    //         disabled={asyncState}
    //         value="Registrarme"
    //         className="colorA standardSize"
    //         onClick={resetError}
    //       />
    //     </form>
    //   </section>
    // </>
  );
};

export default Registration;
