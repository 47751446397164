import React from 'react';
import { Permission } from '../common/reducer';
import { User } from '../interfaces/user';
import {
  // IonCheckbox,
  IonLabel,
  IonItem,
  // IonButton,
  // IonPopover,
  // IonContent
} from '@ionic/react';
import titleCaps from '../common/titleCaps';

interface Params {
  keyValue: string;
  user: User;
  permiso: Permission;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    user: User,
    permiso: Permission
  ) => void;
}

const PermissionCheckbox = (props: Params) => {
  React.useEffect(() => {
    setChecked(props.user.permisos?.includes(props.permiso));
  }, [props]);

  const [checked, setChecked] = React.useState(false);

  function callback(event: any) {
    //setChecked(event.detail.checked);
    setChecked(event.target.checked);
    props.onChange(event, props.user, props.permiso);
  }

  return (
    <div key={'reasnabsj' + props.keyValue}>
      <IonItem
        lines='none'
        id='userAdmin-permission'
        className={checked ? 'permissionSet' : 'permissionUnset'}
        class='ion-text-small ion-text-nopwrap'>
        <input type="checkbox"
          disabled={props.user.super_admin}
          key={'ASDAS' + props.keyValue}
          checked={checked || false}
          onChange={callback}
        />
        <IonLabel>{titleCaps(Permission[props.permiso].replaceAll('_', ' '))}</IonLabel>
        {/*<IonButton id={'click-trigger' + props.keyValue}>Left-Click Me</IonButton>
        <IonPopover trigger={'click-trigger' + props.keyValue} triggerAction="click">
          <IonContent class="ion-padding">Hello World!</IonContent>
  </IonPopover>*/}
      </IonItem>
    </div>
  );
};

export default PermissionCheckbox;
