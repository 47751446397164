import axios from 'axios';
import React, { FormEvent } from 'react';
import PermissionCheckbox from '../../adminusers/permission-checkbox';
import { useAppContext } from '../../common/appContext';
import { obtainAuthURL } from '../../common/obtainURL';
import { AppStateReducerActionTypes, Permission } from '../../common/reducer';
import { User } from '../../interfaces/user';
import {
  IonText,
  IonCol,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonCardContent,
  IonTitle,
  useIonToast,
} from '@ionic/react';
import {
  arrowUndoOutline,
  checkmarkDoneOutline,
  personAddOutline,
} from 'ionicons/icons';
let URL = obtainAuthURL();

const NewUserAdmin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [asyncState, setAsyncState] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const defaultUser: User = {
    email: '',
    permisos: [],
    super_admin: false,
  };

  const [user, setUser] = React.useState(defaultUser);
  const [present] = useIonToast();

  const presentToast = (position: 'top' | 'middle' | 'bottom') => {
    present({
      message: '¡Usuario Registrado Exitosamente!',
      duration: 1500,
      position: position,
      color: 'success',
      icon: checkmarkDoneOutline,
    });
  };

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!validateEmail(formDictionary.email.toString())) {
      setErrorMessage('Email no válido');
    } else if (user.permisos.length === 0) {
      setErrorMessage('No se marcaron permisos');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/sign-up', {
          email: formDictionary.email,
          password: formDictionary.password,
          permisos: user.permisos,
        })
        .then((result) => {
          setAsyncState(false);
          presentToast('bottom');
          appDispatch({
            type: AppStateReducerActionTypes.CONFIRM_REGISTRATION,
            confirmation_payload: { code: '', email: result.data.data.email },
          });
        })
        .catch((reason) => {
          setAsyncState(false);
          console.error('reason', reason);
          setErrorMessage(
            'Error de registración: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function back() {
    appDispatch({ type: AppStateReducerActionTypes.SHOW_USER_ADMIN });
  }

  function resetError() {
    setErrorMessage('');
  }

  function updatePermissions(
    event: React.ChangeEvent<HTMLInputElement>,
    user: User,
    permiso: Permission
  ) {
    let permissions: Permission[] = user.permisos || [];

    if (event.target.checked) {
      if (!permissions.includes(permiso)) {
        permissions.push(permiso);
      }
    } else {
      if (permissions.includes(permiso)) {
        const index = permissions.indexOf(permiso);
        if (index > -1) {
          permissions.splice(index, 1);
        }
      }
    }

    setUser(user);
  }

  return (
    <>
      <IonCard>
        <IonRow class='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
          <IonCol size='12' sizeLg='5'>
            <IonTitle color='primary'>
              <h2 className='bold'> Datos de Registro</h2>
            </IonTitle>
          </IonCol>
          <IonCol size='12' sizeLg='3'>
            <IonButton
              color='success'
              fill='solid'
              expand='block'
              size='default'
              shape='round'
              onClick={back}>
              <IonIcon slot='start' icon={arrowUndoOutline}></IonIcon>
              Regresar
            </IonButton>
          </IonCol>
        </IonRow>

        <IonCardContent>
          <form onSubmit={formSubmit}>
            <IonRow class='ion-justify-content-start ion-align-items-center'>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Correo Electronico
                  </IonLabel>
                  <IonInput type='email' name='email'></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Password
                  </IonLabel>
                  <IonInput
                    type='password'
                    name='password'
                    onClick={resetError}></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size='12' sizeLg='4'>
                <IonItem>
                  <IonLabel position='stacked' className='bold'>
                    Repita Password
                  </IonLabel>
                  <IonInput
                    type='password'
                    name='password2'
                    onClick={resetError}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-left ion-margin-top'>
              <IonCol size='12' class='ion-justify-content-center ion-wrap'>
                <IonTitle color='primary'>
                  <h2 className='bold'>Marque los permisos:</h2>
                </IonTitle>
              </IonCol>
            </IonRow>

            {/* Vista Desktop */}
            <IonRow class='ion-align-items-center ion-hide-md-down'>
              <IonCol size='12' class='ion-justify-content-center ion-nowrap'>
                {Object.values(Permission)
                  .filter((v) => !isNaN(Number(v)))
                  .map((permiso, index) => {
                    return (
                      <>
                        <PermissionCheckbox
                          keyValue={'permiso' + index}
                          onChange={updatePermissions}
                          user={user}
                          permiso={permiso as Permission}
                        />
                      </>
                    );
                  })}
              </IonCol>
            </IonRow>
            {/* Vista MOBILE */}
            <IonRow class='ion-align-item-left ion-hide-md-up'>
              <IonCol size='12' class='ion-justify-content-left ion-nowrap'>
                {Object.values(Permission)
                  .filter((v) => !isNaN(Number(v)))
                  .map((permiso, index) => {
                    return (
                      <PermissionCheckbox
                        keyValue={'permiso' + index}
                        onChange={updatePermissions}
                        user={user}
                        permiso={permiso as Permission}
                      />
                    );
                  })}
              </IonCol>
            </IonRow>

            <IonRow class='ion-align-items-end ion-margin-top'>
              <IonCol size='12' class='ion-justify-content-center ion-wrap'>
                <IonButton
                  expand='block'
                  type='submit'
                  disabled={asyncState}
                  color='primary'>
                  <IonIcon slot='start' icon={personAddOutline}></IonIcon>
                  Crear Usuario
                </IonButton>

                <IonText color='danger' className='bold'>
                  {errorMessage}
                </IonText>
              </IonCol>
            </IonRow>
          </form>
        </IonCardContent>
      </IonCard>

      {/* <section id='newUser'>
        <form onSubmit={formSubmit}>
          <p className='bold'>Ingrese los datos de registro</p>
          <input
            type='email'
            name='email'
            placeholder='Ingresar correo'
            onChange={resetError}
          />
          <input
            type='password'
            name='password'
            placeholder='Ingresar contraseña'
            onChange={resetError}
          />
          <input
            type='password'
            name='password2'
            placeholder='Reingresar contraseña'
            onChange={resetError}
          />
          <div className='newUserPermissions'>
            <p className='bold'>Marque los permisos</p>

            {Object.values(Permission)
              .filter((v) => !isNaN(Number(v)))
              .map((permiso, index) => {
                return (
                  <PermissionCheckbox
                    key={'permiso' + index}
                    onChange={updatePermissions}
                    user={user}
                    permiso={permiso as Permission}
                  />
                );
              })}
          </div>
          <br />
          <input
            type='submit'
            disabled={asyncState}
            value='Crear usuario'
            className='colorA standardSize'
            onClick={resetError}
          />
          <p className='bold errorColor'>{errorMessage}</p>
        </form>
      </section> */}
    </>
  );
};

export default NewUserAdmin;
