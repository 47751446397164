import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonButton,
  useIonModal,
  IonList,
  IonRow,
  IonCard,
  IonIcon,
  IonText,
  IonItem,
  IonLabel,
  IonInput,
  IonCardContent,
  IonCardHeader,
  IonTitle,
} from '@ionic/react';
import { addOutline, searchOutline, eyeOutline } from 'ionicons/icons';
import ContractModal from './modal-contract';
import { OverlayEventDetail } from '@ionic/core/components';

const contract: Array<any> = [
  {
    nombre: 'Aguas arg',
    trabajos: 5,
  },
  {
    nombre: 'Aguas chile',
    trabajos: 5,
  },
  {
    nombre: 'Aguas Venuzuela',
    trabajos: 1,
  },
  {
    nombre: 'Aguas',
    trabajos: 2,
  },
];

const Contract = () => {
  const [present, dismiss] = useIonModal(ContractModal, {
    onDismiss: (data: string) => dismiss(data),
  });

  const [infoContract, setInfoContract] = useState(null);

  const openModal = () => {
    present({
      onWillDismiss: (event: CustomEvent<OverlayEventDetail>) => {
        setInfoContract(event.detail.data);
      },
    });
  };

  useEffect(() => {
    console.log('Contract data received --> ', infoContract);
  }, [infoContract]);
  return (
    <>
      <IonCard>
        <IonRow class='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
          <IonCol size='12' sizeLg='5'>
            <IonTitle color='primary'>
              <h2 className='bold'>CIPP - Contratos</h2>
            </IonTitle>
          </IonCol>
          <IonCol size='12' sizeLg='3'>
            <IonButton
              onClick={() => openModal()}
              color='success'
              fill='solid'
              expand='block'
              size='default'
              shape='round'>
              <IonIcon slot='start' icon={addOutline}></IonIcon>
              Crear contrato
            </IonButton>
          </IonCol>
        </IonRow>
        <IonCardContent>
          <IonRow class='ion-justify-content-start ion-align-items-center'>
            <IonCol size='12' sizeLg='4'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Nombre de contrato
                </IonLabel>
                <IonInput type='text'></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeLg='4'>
              <IonItem>
                <IonLabel position='stacked' className='bold'>
                  Trabajos
                </IonLabel>
                <IonInput type='text'></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow class='ion-justify-content-between ion-align-items-center'>
            <IonCol size='12' sizeLg='4'>
              <IonText color='primary'>
                <h2 className='bold'>Total de contratos: {contract.length}</h2>
              </IonText>
            </IonCol>
            <IonCol size='12' sizeLg='5'>
              <IonRow>
                <IonCol size='6'>
                  <IonButton fill='clear' expand='block' size='default'>
                    Limpiar
                  </IonButton>
                </IonCol>
                <IonCol size='6'>
                  <IonButton fill='solid' expand='block' size='default'>
                    <IonIcon slot='start' icon={searchOutline}></IonIcon>
                    Buscar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          {/* tabla */}
          <IonRow>
            <IonCol class='ion-no-padding'>
              <IonCardHeader class='ion-hide-lg-down'>
                <IonRow class='ion-justify-content-between'>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>
                        Nombre del Contrato
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>
                        N° de trabajos
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size='12' sizeLg='4'>
                    <IonItem button lines='none'>
                      <IonLabel class='ion-text-center bold'>Acciones</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardContent class='ion-no-padding'>
                <IonList class='ion-no-padding'>
                  {contract.length > 0 ? (
                    contract.map((e: any, index: any) => {
                      return (
                        <IonRow key={index}>
                          <IonCol size='12' class='ion-no-padding'>
                            <IonItem button class='ion-no-padding'>
                              <IonCol size='6' sizeLg='4' class='ion-no-padding'>
                                <IonLabel class='ion-text-center'>
                                  <p>{e.nombre}</p>
                                </IonLabel>
                              </IonCol>
                              <IonCol size='3' sizeLg='4' class='ion-no-padding'>
                                <IonLabel class='ion-text-center'>
                                  <p>{e.trabajos}</p>
                                </IonLabel>
                              </IonCol>
                              <IonCol
                                size='3'
                                sizeLg='4'
                                class='ion-text-center ion-no-padding'>
                                {/* Boton desktop */}
                                <IonButton
                                  class='ion-hide-lg-down'
                                  strong={true}
                                  fill='outline'
                                  size='default'>
                                  Ver Contrato
                                  <IonIcon
                                    slot='end'
                                    size='default'
                                    icon={eyeOutline}></IonIcon>
                                </IonButton>
                                {/* Boton mobile*/}
                                <IonButton
                                  class='ion-hide-lg-up'
                                  strong={true}
                                  fill='outline'
                                  size='small'>
                                  <IonIcon
                                    slot='icon-only'
                                    size='default'
                                    icon={eyeOutline}></IonIcon>
                                </IonButton>
                              </IonCol>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      );
                    })
                  ) : (
                    <IonText class='ion-text-center' color='dark'>
                      <h1>¡No hay contratos registrados aun!</h1>
                    </IonText>
                  )}
                </IonList>
              </IonCardContent>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default Contract;
