import React from 'react';
import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import { IonAccordionGroup, IonAccordion } from '@ionic/react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { tabletLandscapeOutline } from 'ionicons/icons';

function FincloudSelect() {
  const { dispatch: stateDispatch } = useAppContext();

  function showFincloud() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_FINCLOUD });
  }

  function showSapFincloud() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_SAP_FINCLOUD });
  }

  return (
    <>
      <IonItem slot="header" color="primary">
        <IonIcon
          color="white"
          icon={tabletLandscapeOutline}
          slot="start"></IonIcon>
        <IonLabel>Fincloud</IonLabel>
      </IonItem>
      <div
        className="ion-padding"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showFincloud}>
        Exportar Rendiciones
      </div>
      <div
        className="ion-padding"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showSapFincloud}>
        Historial
      </div>
    </>
  );
}

export default FincloudSelect;
