import axios from 'axios';
import React, { FormEvent } from 'react';
import { useAppContext } from '../common/appContext';
import { obtainAuthURL } from '../common/obtainURL';
import { AppStateReducerActionTypes } from '../common/reducer';
import {
  IonRow,
  IonInput,
  IonText,
  IonButton,
  IonIcon,
  IonCol,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonToast,
} from '@ionic/react';
import { checkmarkDoneOutline } from 'ionicons/icons';

interface Props {
  codigo: string | null;
  email: string | null;
}

let URL = obtainAuthURL();

const ConfirmRegistration = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [asyncState, setAsyncState] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [present] = useIonToast();

  React.useEffect(() => {});

  const presentToast = (position: 'top' | 'middle' | 'bottom') => {
    present({
      message: '¡Registro Exitoso!',
      duration: 1500,
      position: position,
      color: 'success',
      icon: checkmarkDoneOutline,
    });
  };

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!formDictionary.codigo) {
      setErrorMessage('Debe ingresar el código');
    } else if (!formDictionary.email) {
      setErrorMessage('Debe ingresar el email');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/verify-email', {
          email: formDictionary.email,
          codigoConfirmacion: parseInt(formDictionary.codigo.toString()),
        })
        .then((result) => {
          setAsyncState(false);
          presentToast('bottom');
          //console.log(result);
          window.history.replaceState({}, document.title, '/');
          appDispatch({
            type: AppStateReducerActionTypes.SET_USER_PERMISSIONS,
            permissions_payload: result.data.data.permisos,
          });
          appDispatch({
            type: AppStateReducerActionTypes.LOGIN,
            login_payload: {
              email: result.data.data.email,
              token: result.data.data.token,
            },
          });
        })
        .catch((reason) => {
          setAsyncState(false);
          console.error(reason);
          setErrorMessage(
            'Error de confirmación: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function resetError() {
    setErrorMessage('');
  }

  return (
    <>
      <IonPage>
        <IonContent>
          <IonRow class='ion-margin-top'>
            <IonCol size='12'>
              <IonTitle color='primary' class='ion-text-center'>
                <h1 className='mainTitleSize'>
                  Grupo<span className='bold'>PSG</span>
                </h1>
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow class='ion-margin-bottom'>
            <IonCol size='12'>
              <IonText color='dark' class='ion-text-center'>
                <h2>Confirmar Codigo de Registracion</h2>
              </IonText>
            </IonCol>
          </IonRow>
          <form onSubmit={formSubmit}>
            <IonRow class='ion-justify-content-center'>
              <IonCol
                size='11'
                sizeLg='10'
                class='ion-no-margin ion-no-padding ion-margin-top'>
                <IonRow>
                  <IonCol size='12'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Su codigo
                      </IonLabel>
                      <IonInput
                        type='text'
                        defaultValue={props.codigo || ''}
                        name='codigo'
                        placeholder='Ingresar código'
                        onChange={resetError}></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow class='ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonItem>
                      <IonLabel position='stacked' className='bold'>
                        Su E-mail
                      </IonLabel>
                      <IonInput
                        type='email'
                        defaultValue={props.email || ''}
                        name='email'
                        placeholder='Ingresar email'
                        onChange={resetError}></IonInput>
                    </IonItem>
                    <IonText color='danger' class='ion-text-center'>
                      {errorMessage}
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow class='ion-margin-bottom'>
                  <IonCol size='12'>
                    <IonButton
                      color='primary'
                      size='default'
                      expand='block'
                      type='submit'
                      disabled={asyncState}
                      onClick={resetError}>
                      Confirmar Codigo
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </form>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ConfirmRegistration;

// 	<section id='confirm'>
//     <form onSubmit={formSubmit}>
//       <p className='bold'>Confirmar código de registración</p>
//       <input
//         type='text'
//         defaultValue={props.codigo || ''}
//         name='codigo'
//         placeholder='Ingresar código'
//         onChange={resetError}
//       />
//       <input
//         type='email'
//         defaultValue={props.email || ''}
//         name='email'
//         placeholder='Ingresar email'
//         onChange={resetError}
//       />
//       <p className='bold errorColor'>{errorMessage}</p>
//       <input
//         type='submit'
//         disabled={asyncState}
//         value='Confirmar código'
//         className='colorA standardSize'
//         onClick={resetError}
//       />
//     </form>
//   </section>;
