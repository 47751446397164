import axios from 'axios';
import React, { FormEvent } from 'react';
import { useAppContext } from '../common/appContext';
import { obtainAuthURL } from '../common/obtainURL';
import { AppStateReducerActionTypes } from '../common/reducer';
import {
  IonRow,
  IonInput,
  IonText,
  IonButton,
  IonIcon,
  IonCol,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
} from '@ionic/react';
import { arrowBackOutline, paperPlaneOutline } from 'ionicons/icons';

let URL = obtainAuthURL();

const PasswordRecovery = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const [asyncState, setAsyncState] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');
  const [mailSent, setMailSent] = React.useState('');

  React.useEffect(() => {});

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function formSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement & EventTarget);
    const formDictionary = Object.fromEntries(formData.entries());

    if (!validateEmail(formDictionary.email.toString())) {
      setErrorMessage('Email no válido');
    } else {
      setAsyncState(true);
      axios
        .post(URL + '/password-recovery', { email: formDictionary.email })
        .then((result) => {
          setAsyncState(false);
          console.log(result);
          setMailSent(formDictionary.email.toString());
        })
        .catch((reason) => {
          setAsyncState(false);
          console.error(reason);
          setErrorMessage(
            'Error de restauración: ' + reason.response.data.data.errorMessage
          );
        });
    }
  }

  function back() {
    appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
  }

  function resetError() {
    setErrorMessage('');
  }

  return (
    <>
      <IonPage>
        <IonContent>
          <IonRow class='ion-margin-top'>
            <IonCol size='3' class='ion-padding'>
              <IonButton
                onClick={back}
                color='tertiary'
                size='default'
                shape='round'>
                <IonIcon slot='start' icon={arrowBackOutline}></IonIcon>
                Regresar
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow class='ion-margin-bottom'>
            <IonCol size='11'>
              <IonText color='dark' class='ion-text-center'>
                <h2 className='bold'>Ingrese sus Datos de Registro</h2>
              </IonText>
            </IonCol>
          </IonRow>
          {mailSent.length === 0 ? (
            <form onSubmit={formSubmit}>
              <IonRow class='ion-justify-content-center'>
                <IonCol
                  size='11'
                  sizeLg='10'
                  class='ion-no-margin ion-no-padding ion-margin-top'>
                  <IonText class='ion-text-center'>
                    <h6>
                      {' '}
                      Ingrese su correo, le enviaremos un mensaje con el link de
                      recuperación
                    </h6>
                  </IonText>
                </IonCol>
                <IonCol
                  size='11'
                  sizeLg='10'
                  class='ion-no-margin ion-no-padding ion-margin-top'>
                  <IonRow>
                    <IonCol size='12'>
                      <IonItem>
                        <IonLabel position='stacked' className='bold'>
                          Ingresa tu correo
                        </IonLabel>
                        <IonInput
                          type='email'
                          name='email'
                          placeholder='Ingresa E-mail'
                          onChange={resetError}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow class='ion-margin-bottom ion-padding'>
                    <IonCol size='12'>
                      <IonButton
                        color='primary'
                        size='default'
                        expand='block'
                        type='submit'
                        disabled={asyncState}
                        onClick={resetError}>
                        <IonIcon slot='end' icon={paperPlaneOutline}></IonIcon>
                        Enviar
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <p className='bold errorColor'>{errorMessage}</p>
                </IonCol>
              </IonRow>
            </form>
          ) : (
            <>
              <IonRow class='ion-justify-content-center'>
                <IonCol
                  size='11'
                  sizeLg='10'
                  class='ion-no-margin ion-no-padding ion-margin-top'>
                  <IonText class='ion-text-center'>
                    <h6>Se envió un link de recuperación al correo: {mailSent}</h6>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow class='ion-margin-bottom ion-padding'>
                <IonCol size='12' sizeLg='6'>
                  <IonButton
                    color='primary'
                    size='default'
                    expand='block'
                    type='submit'
                    onClick={back}>
                    De Acuerdo
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default PasswordRecovery;

{
  /* <section id='recovery'>
  <button type='button' className='flexLeft justText' onClick={back}>
    <span className='bold'>Regresar</span>
  </button>

  {mailSent.length === 0 ? (
    <form onSubmit={formSubmit}>
      <p className='bold'>Ingrese los datos de registro</p>
      <div>
        Ingrese su correo, le enviaremos un mensaje
        <br />
        con el link de recuperación
      </div>
      <input
        type='email'
        name='email'
        placeholder='Ingresar correo'
        onChange={resetError}
      />
      <p className='bold errorColor'>{errorMessage}</p>
      <input
        type='submit'
        disabled={asyncState}
        value='Enviar'
        className='colorA standardSize'
        onClick={resetError}
      />
    </form>
  ) : (
    <div>
      Se envió un link de recuperación al correo:
      <p className='bold'>{mailSent}</p>
      <br />
      <input
        type='submit'
        value='De Acuerdo'
        className='colorA standardSize'
        onClick={back}
      />
    </div>
  )}
</section>; */
}
