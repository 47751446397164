import React from 'react';
import { useAppContext } from '../common/appContext';
import { ScreenTypes } from '../common/reducer';
import Buk from './buk/buk';
import Fincloud from './fincloud/fincloud';
import AperturaEmpresas from './efectivo_equivalentes/apertura_empresas';
import HistorialSAPBuk from './buk/historialsapbuk';
import HistorialSAPFincloud from './fincloud/historialsapfincloud';
import Menu from './menu/menu';
import NewUserAdmin from './usuarios/newuser';
import UserAdmin from './usuarios/user-admin';
import Welcome from './welcome';
import Contract from './cipp/contract';
import Trabajos from './cipp/trabajos';
import { AppStateReducerActionTypes } from '../common/reducer';
import {
  IonContent,
  useIonAlert,
  IonButtons,
  IonMenuButton,
  IonItem,
  IonAvatar,
  IonLabel,
  IonButton,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  IonSplitPane,
  IonChip,
} from '@ionic/react';
import { logOutOutline, calendarOutline } from 'ionicons/icons';
import EditUserAdmin from './usuarios/edituser';
import ImportacionClay from './importacion_clay/importacion_clay';
import AperturaEmpresasMontos from './montos_agrupados/apertura_empresas_montos';
import InversionesModulo from './inversiones/inversiones';
import InversionesModuloCrear from './inversiones/crear_inversion';
import CuentaModuloCrear from './inversiones/cuenta';
import VistaControl from './efectivo_equivalentes/vistas_control';
const Main = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [presentAlert] = useIonAlert();
  const time = Date.now();
  const currentDate = new Date(time).toLocaleDateString();

  function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('screen');
    localStorage.removeItem('sapcredentials');
    appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
  }

  let page = <></>;

  switch (appState.screen) {
    case ScreenTypes.Fincloud:
      page = <Fincloud />;
      break;
    case ScreenTypes.Buk:
      page = <Buk />;
      break;
    case ScreenTypes.FincloudSAP:
      page = <HistorialSAPFincloud />;
      break;
    case ScreenTypes.BukSAP:
      page = <HistorialSAPBuk />;
      break;
    case ScreenTypes.UserAdmin:
      page = <UserAdmin />;
      break;
    case ScreenTypes.NewUser:
      page = <NewUserAdmin />;
      break;
    case ScreenTypes.EditUser:
      page = <EditUserAdmin />;
      break;
    case ScreenTypes.Contract:
      page = <Contract />;
      break;
    case ScreenTypes.trabajo:
      page = <Trabajos />;
      break;
    case ScreenTypes.AperturaEmpresas:
      page = <AperturaEmpresas />;
      break;
    case ScreenTypes.VistaControl:
      page = <VistaControl />;
      break;
    case ScreenTypes.AperturaEmpresasMontos:
      page = <AperturaEmpresasMontos />;
      break;
    case ScreenTypes.ImportacionClay:
      page = <ImportacionClay />;
      break;
    case ScreenTypes.InversionesModulo:
        page = <InversionesModulo />;
        break;
    case ScreenTypes.createAccount:
        page = <InversionesModuloCrear />;
        break;
    case ScreenTypes.account:
        page = <CuentaModuloCrear />;
        break;
    default:
      page = <Welcome />;
      break;
  }

  function detectDEVQA() {
    let URL
    switch (window.location.hostname) {
      case 'localhost':
        URL = 'Local DEV';
        break;
      case 'dev.grupopsg.net':
        URL = 'Version de Desarrollo';
        break;
      case 'qa.grupopsg.net':
        URL = 'Version de Pruebas';
        break;
      case 'grupopsg.net':
        URL = '';
        break;
      default:
        URL = '';
    }
    return URL;
  }

  function getSAPCredentials() {
    return appState.sapCredentials ? 'Credenciales SAP configuradas' : 'Credenciales SAP no configuradas';
  }

  return (
    <>
      <IonSplitPane when='sm' contentId='main'>
        <Menu />
        <div className='ion-page' id='main'>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot='start'>
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonGrid>
                <IonRow class='ion-justify-content-between ion-align-items-right'>
                  {/* <IonCol size='12' class='ion-hide-md-up' color="white">
                    <img src="/Logo-PSG-Fondo-Blanco.png" alt="Grupo PSG" style={{ maxWidth: '25%' }} />
                  </IonCol> */}
                  <IonCol size='4' class='ion-hide-md-down'>
                    <IonItem lines='none'>
                      <IonChip color='primary'>
                        <IonIcon size='default' icon={calendarOutline}></IonIcon>
                        <IonLabel className='bold'>{currentDate}</IonLabel>
                      </IonChip>
                    </IonItem>
                  </IonCol>
                  <IonCol size='auto'>
                    <IonLabel color='danger' className='bold'><h1>{detectDEVQA()}</h1></IonLabel>
                    <IonLabel>{getSAPCredentials()}</IonLabel>
                  </IonCol>
                  <IonCol size='4' class='ion-hide-md-down'>
                    <IonItem lines='none'>
                      <IonAvatar slot='start'>
                        <img
                          alt="Silhouette of a person's head"
                          src='https://ionicframework.com/docs/img/demos/avatar.svg'
                        />
                      </IonAvatar>
                      <IonLabel className='bold'>{appState.email}</IonLabel>
                      {appState.loggedIn ? (
                        <IonButton
                          expand='block'
                          fill='clear'
                          size='default'
                          onClick={() =>
                            presentAlert({
                              header: '¿Esta seguro que quiere salir de la sesión?',
                              buttons: [
                                {
                                  text: 'No',
                                  role: 'cancel',
                                  //   handler: () => {
                                  // 	setHandlerMessage('Alert canceled');
                                  //   },
                                },
                                {
                                  text: 'Si',
                                  role: 'confirm',
                                  handler: () => {
                                    logout();
                                  },
                                },
                              ],
                            })
                          }>
                          <IonIcon
                            slot='icon-only'
                            size='large'
                            color='primary'
                            icon={logOutOutline}></IonIcon>
                        </IonButton>
                      ) : (
                        <></>
                      )}
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          </IonHeader>
          <IonContent className='ion-padding'>
            {page}
          </IonContent>
        </div>
      </IonSplitPane>
    </>
  );
};

export default Main;
