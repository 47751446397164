import React, { ChangeEvent } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { BukSap } from '../interfaces/BukSap';
import { IonText, IonCol, IonRow, IonCard, IonItem, IonButton } from '@ionic/react';
import { useAppContext } from '../common/appContext';
import { AppStateReducerActionTypes } from '../common/reducer';

interface bst {
  keyValue: number;
  title: string;
  subtitle?: string;
  rows: BukSap[];
  showToggle: boolean;
  empresa: string;
}

const toggle_key = 'BUK'

const BukSAPTable = (props: bst) => {
  const sapColumns: TableColumn<BukSap>[] = [
    {
      name: 'Line num',
      selector: (row) => row.line_num,
      sortable: true,
    },
    {
      name: 'Account Code',
      selector: (row) => row.account_code,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Debit',
      selector: (row) => row.debit,
      sortable: true,
    },
    {
      name: 'Credit',
      selector: (row) => row.credit,
      sortable: true,
    },
    {
      name: 'Line Memo',
      selector: (row) => row.line_memo,
      sortable: true,
    },
    {
      name: 'Reference 1',
      selector: (row) => row.reference_1,
      sortable: true,
    },
    {
      name: 'Reference 2',
      selector: (row) => row.reference_2,
      sortable: true,
    },
    {
      name: 'Costing Code',
      selector: (row) => row.costing_code,
      sortable: true,
    },
    {
      name: 'Costing Code 2',
      selector: (row) => row.costing_code2,
      sortable: true,
    },
    {
      name: 'Costing Code 3',
      selector: (row) => row.costing_code3,
      sortable: true,
    },
    {
      name: 'Costing Code 4',
      selector: (row) => row.costing_code4,
      sortable: true,
    },
  ];

  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [dictionarySeeded, setDictionarySeeded] = React.useState(false);
  const [theme, setTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

  React.useEffect(() => {
    if (!dictionarySeeded) {
      appDispatch({
        type: AppStateReducerActionTypes.TOGGLE_ADD_ITEM,
        toggle_item_payload: {
          empresa: props.empresa,
          id: props.keyValue.toString(),
          key: toggle_key,
        }
      });
      setDictionarySeeded(true);
    }
    setSAPReady(props && props.rows && props.rows.length > 0);
    setSAPRows(props && props.rows);
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme); // "dark" or "light"
        setTheme(event.matches ? 'dark' : 'light');
      });
  }, [appDispatch, dictionarySeeded, props]);

  const sapRowsDefault: BukSap[] = [];
  const [sapRows, setSAPRows] = React.useState(sapRowsDefault);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sapReady, setSAPReady] = React.useState(false);

  const [showDetails, setShowDetails] = React.useState(false);

  function onChanged() {
    setShowDetails(!showDetails);
  }

  function onToggled(event: ChangeEvent<HTMLInputElement>) {
    //function onToggled(event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) {
    appDispatch({
      type: event.target.checked
        ? AppStateReducerActionTypes.TOGGLE_ADD_ITEM
        : AppStateReducerActionTypes.TOGGLE_REMOVE_ITEM,
      toggle_item_payload: { key: toggle_key, empresa: props.empresa, id: props.keyValue.toString() },
    });
  }

  return (
    <>
      <IonRow class='ion-align-items-center'>
        <IonCol size='12' sizeLg='3'>
          <IonItem
            lines='none'
            key={props.keyValue + 'd'}
            class='ion-item ion-button'>
            <div
              id='sapItemFincloud'
              className={props.showToggle ? 'withToggle' : 'withoutToggle'}
              key={props.keyValue + 'a'}>
              <div id='sel'>
                {props.showToggle ? (
                  <input type="checkbox" onChange={onToggled}
                    checked={
                      appState.toggleDictionaries[toggle_key] ?
                        appState.toggleDictionaries[toggle_key][props.empresa][props.keyValue]
                        :
                        false
                    } />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <IonButton
              size='large'
              fill='clear'
              color='primary'
              key={props.keyValue + 'd'}
              onClick={() => onChanged()}>
              {showDetails ? 'Cerrar' : 'Ver detalle'}
            </IonButton>
          </IonItem>
        </IonCol>
        <IonCol size='12' sizeLg='9' key={props.keyValue + 'a'}>
          <IonText id='title' className='bold'>{props.title}</IonText>
          {
            props.subtitle ?
              <><br /><IonText id='title'>{props.subtitle}</IonText></>
              :
              <>
              </>
          }
        </IonCol>
        {showDetails ? (
          <IonCard id='table'>
            <DataTable
              key={props.keyValue + 'b'}
              title={props.title}
              columns={sapColumns}
              data={sapRows}
              pagination
              dense
              striped
            // theme={theme}
            />
          </IonCard>
        ) : (
          <></>
        )}
      </IonRow>
    </>
  );
};

export default BukSAPTable;

