import axios from 'axios';
import React, { useCallback } from 'react';
import { ResponseCache } from '../../interfaces/ResponseCache';
import { obtainURL } from '../../common/obtainURL';
import { FincloudSap } from '../../interfaces/FincloudSap';
import FincloudSAPTable from '../../fincloud/fincloudsaptable';
import { useAppContext } from '../../common/appContext';
import { createHeader, logoutIfUnauthorized } from '../../common/auth';
import { onConnectionError } from '../../common/connectionerrorhandling';
import {
  IonButton,
  IonProgressBar,
  IonCard,
  IonCol,
  IonRow,
  IonTitle,
  IonIcon,
  IonList,
} from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';

interface AsientoSAP {
  id: number;
  rendicion: number;
  sap: FincloudSap;
  display: FincloudSap;
  empresa: string;
  export_date: string;
  export_sap_user: string;
  export_user: string;
}

let URL = obtainURL();

const HistorialSAPFincloud = () => {
  const defaultCache: ResponseCache = {
    cached: false,
    response: null,
  };

  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [responseSapCache, setResponseSapCache] = React.useState(defaultCache);
  const [errorRetries, setErrorRetries] = React.useState(0);
  const [moreRowsAvailable, setMoreRowsAvailable] = React.useState(false);
  const [loadingMoreRows, setLoadingMoreRows] = React.useState(false);
  const [nextPageParameter, setNextPageParameter] = React.useState('');

  const refreshSAP = useCallback(() => {
    if (responseSapCache.cached) return;

    axios
      .get(URL + '/fincloud-sap', createHeader(appState.token))
      .then((response) => {
        setResponseSapCache({ cached: true, response: response.data.data.sap });

        setAsientosSAP(response.data.data.sap.reverse());

        checkNextPage(response);
      })
      .catch((error) => {
        console.error(error);
        setErrorRetries(errorRetries + 1);
        if (errorRetries + 1 === 3) {
          onConnectionError({ custom: 'El servicio no responde.' }, appDispatch);
        } else {
          onConnectionError(error, appDispatch);
        }
        setResponseSapCache({ cached: false, response: null });
        logoutIfUnauthorized(error, appDispatch);
      });
  }, [appDispatch, appState.token, errorRetries, responseSapCache.cached]);

  const checkNextPage = (response: any) => {
    let nextPage = ''
    if (response.data?.metadata?.lastEvaluatedKey?.SK) {
      nextPage = "?nextPage=" + encodeURIComponent(response.data?.metadata?.lastEvaluatedKey?.SK);
      setMoreRowsAvailable(true);
    } else {
      setMoreRowsAvailable(false);
    }

    setNextPageParameter(nextPage);
  }

  function clearCache() {
    setResponseSapCache({ cached: false, response: null });
    setAsientosSAP([]);
    setErrorRetries(0);
  }

  const getMoreRows = useCallback(() => {
    setMoreRowsAvailable(false);
    setLoadingMoreRows(true);

    axios.get(URL + '/fincloud-sap' + nextPageParameter, createHeader(appState.token))
      .then((response) => {
        // responseSapCache.response + response.data.data.sap
        setResponseSapCache({ cached: true, response: responseSapCache.response.concat(response.data.data.sap) });

        // asientosSAP + response.data.data.sap
        setAsientosSAP(asientosSAP.concat(response.data.data.sap));
        setLoadingMoreRows(false);

        checkNextPage(response);
      })
      .catch((error) => {
        console.error(error);
        setLoadingMoreRows(false);
        setErrorRetries(errorRetries + 1);
        if (errorRetries + 1 === 3) {
          onConnectionError({ custom: 'El servicio no responde.' }, appDispatch);
        } else {
          onConnectionError(error, appDispatch);
        }
        setResponseSapCache({ cached: false, response: null });
        logoutIfUnauthorized(error, appDispatch);
      });
  }, [appDispatch, appState.token, errorRetries, nextPageParameter])

  const asientosSAPDefault: AsientoSAP[] = [];
  const [asientosSAP, setAsientosSAP] = React.useState(asientosSAPDefault);

  React.useEffect(() => {
    if (errorRetries < 3) {
      refreshSAP();
    } else {
      setResponseSapCache({ cached: true, response: null });
    }
  }, [errorRetries, refreshSAP]);


  return (
    <>
      <IonCard>
        <IonRow class='ion-align-items-center ion-justify-content-between ion-margin-bottom'>
          <IonCol size='12' sizeLg='5'>
            <IonTitle color='primary' class='ion-text-center'>
              <h2 className='bold'>Historial SAP Fincloud</h2>
            </IonTitle>
          </IonCol>
          <IonCol size='12' sizeLg='3'>
            <IonButton
              expand='block'
              color='primary'
              onClick={clearCache}
              disabled={!responseSapCache.cached}>
              Refrescar
              <IonIcon slot='start' icon={refreshOutline}></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
        {responseSapCache.cached === false ? (
          <IonProgressBar type='indeterminate'></IonProgressBar>
        ) : <></>}
        <IonCard>
          <IonList id='sap' lines='none'>
            {
              asientosSAP.length > 0 ?
                asientosSAP.map((asiento, index) => {
                  const asientoRef = asiento.display ? asiento.display : asiento.sap;

                  if (asiento.sap.Reference === '2621') {
                    console.log(asiento);
                  }

                  if (!asiento.display) {
                    console.log(
                      'Sin version display para rendicion',
                      asiento.id,
                      asiento.sap.Memo
                    );
                  }

                  const empresa = appState.empresas[asiento.empresa]
                    ? appState.empresas[asiento.empresa]
                    : '';
                  return (
                    <>
                      <FincloudSAPTable
                        key={index}
                        keyValue={asientoRef.Reference}
                        showToggle={false}
                        title={
                          'Asiento ' +
                          asiento.id +
                          ' / ' +
                          asientoRef.Memo +
                          ' / ' +
                          empresa
                        }
                        subtitle={asiento.export_user ?
                          'Exportado por: ' + asiento.export_user +
                          ', usuario SAP: ' + asiento.export_sap_user +
                          ' en la fecha: ' + new Date(asiento.export_date).toLocaleString('es-CL')
                          :
                          ''
                        }
                        sapRegister={asientoRef}
                        empresa={asiento.empresa}
                      />
                    </>
                  );
                })
                :
                <>No hay registros</>
            }
            {
              moreRowsAvailable ?
                <IonButton
                  expand='block'
                  color='primary'
                  onClick={getMoreRows}
                  disabled={!responseSapCache.cached}>
                  Traer mas rendiciones
                  <IonIcon slot='start' icon={refreshOutline}></IonIcon>
                </IonButton>
                :
                <></>
            }
            {
              loadingMoreRows ?
                <IonProgressBar type='indeterminate'></IonProgressBar>
                :
                <></>
            }
          </IonList>
        </IonCard>
      </IonCard>

      {/* <IonButton className="colorA" onClick={clearCache} disabled={!responseSapCache.cached}>Refrescar</IonButton>
			{
				asientosSAP.length === 0 ?
				<IonProgressBar type="indeterminate"></IonProgressBar>
					:
					<div id="sap">
						{asientosSAP.map(
							(asiento, index) => {
								const asientoRef = asiento.display ? asiento.display : asiento.sap;

								if (!asiento.display) {
									console.log('Sin version display para rendicion', asiento.id, asiento.sap.Memo)
								}

								const empresa = appState.empresas[asiento.empresa] ? appState.empresas[asiento.empresa] : '';
								return (
									<FincloudSAPTable key={index} keyValue={asientoRef.Reference} showToggle={false} title={'Asiento ' + asiento.id + ' / ' + asientoRef.Memo + ' / ' + empresa} sapRegister={asientoRef} empresa={asiento.empresa} />
								)
							}
						)}
					</div>
			} */}
    </>
  );
};

export default HistorialSAPFincloud;
