import axios from 'axios';
import React, { useCallback } from 'react';
import PermissionCheckbox from '../../adminusers/permission-checkbox';
import { useAppContext } from '../../common/appContext';
import { obtainURL } from '../../common/obtainURL';
import { AppStateReducerActionTypes, Permission } from '../../common/reducer';
import { createHeader, logoutIfUnauthorized } from '../../common/auth';
import { User } from '../../interfaces/user';
import { onConnectionError } from '../../common/connectionerrorhandling';
import {
  IonCol,
  IonButton,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonProgressBar,
  IonCardContent,
  IonCardHeader,
  IonTitle,
  IonText,
} from '@ionic/react';
import { addOutline } from 'ionicons/icons';

let URL = obtainURL();

const UserAdmin = () => {
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const defaultUserList: User[] = [];
  const [users, setUsers] = React.useState(defaultUserList);
  const [errorRetries, setErrorRetries] = React.useState(0);

  const refresh = useCallback(() => {
    axios
      .get(URL + '/list-users', createHeader(appState.token))
      .then((result) => {
        setUsers(result.data.data);
      })
      .catch((error) => {
        console.error(error);
        setErrorRetries(errorRetries + 1);
        if (errorRetries + 1 === 3) {
          onConnectionError({ custom: 'El servicio no responde.' }, appDispatch);
        } else {
          onConnectionError(error, appDispatch);
        }
        logoutIfUnauthorized(error, appDispatch);
      });
  }, [appDispatch, appState.token, errorRetries]);

  function addUser() {
    appDispatch({ type: AppStateReducerActionTypes.ADD_USER_ADMIN });
  }

  function updatePermissions(
    event: React.ChangeEvent<HTMLInputElement>,
    user: User,
    permiso: Permission
  ) {
    let permissions: Permission[] = user.permisos || [];

    if (event.target.checked) {
      if (!permissions.includes(permiso)) {
        permissions.push(permiso);
      }
    } else {
      if (permissions.includes(permiso)) {
        const index = permissions.indexOf(permiso);
        if (index > -1) {
          permissions.splice(index, 1);
        }
      }
    }

    axios
      .post(
        URL + '/user-permissions',
        {
          email: user.email,
          permisos: permissions,
        },
        createHeader(appState.token)
      )
      .then((result) => {
        refresh();
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
        onConnectionError(error, appDispatch);
        logoutIfUnauthorized(error, appDispatch);
      });
  }

  function editSAPCredentials(email: string) {
    appDispatch({ type: AppStateReducerActionTypes.EDIT_USER_ADMIN, edit_sap_credentials_payload: email });
  }

  React.useEffect(() => {
    if (errorRetries < 3) {
      refresh();
    }
  }, [errorRetries, refresh]);

  return (
    <>
      <IonCard>
        <IonRow class="ion-align-items-center ion-justify-content-around ion-margin-bottom">
          <IonCol size="12" sizeLg="5" class="ion-float-left">
            <IonTitle color="primary">
              <h2 className="bold">Usuarios</h2>
            </IonTitle>
          </IonCol>
          <IonCol size="12" sizeLg="3">
            <IonButton
              color="success"
              fill="solid"
              expand="block"
              size="default"
              shape="round"
              onClick={addUser}>
              <IonIcon slot="start" icon={addOutline}></IonIcon>
              Nuevo Usuario
            </IonButton>
          </IonCol>
        </IonRow>

        <IonRow ion-align-items-stretch>
          <IonCol>
            <IonCardHeader>
              <IonRow class="ion-justify-content-center ion-hide-sm-down">
                <IonCol size="6" sizeLg="4">
                  <IonItem button lines="none">
                    <IonLabel class="ion-text-left bold">Usuarios</IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol size="6" sizeLg="4">
                  <IonItem button lines="none">
                    <IonLabel class="ion-text-left bold">Permisos</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonCardHeader>
            <IonCardContent class="ion-no-padding">
              {users.length === 0 && errorRetries < 3 ? (
                <IonProgressBar type="indeterminate"></IonProgressBar>
              ) : (
                users.map((user, index) => {
                  return (
                    <div key={'root' + index}>
                      {/* Vista Desk */}
                      <IonRow class="ion-align-items-center ion-hide-md-down">
                        <IonCol size="12" sizeLg="3">
                          <IonButton size="small" onClick={() => { editSAPCredentials(user.email) }}>Editar</IonButton>
                          <IonLabel class="ion-float-lef ion-text-nowrapt">
                            <IonText key={'usuario' + index}>{user.email}</IonText>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" sizeLg="9">
                          <IonItem>
                            {Object.values(Permission)
                              .filter((v) => !isNaN(Number(v)))
                              .map((permiso, index) => {
                                return (
                                  <div key={'emptydesk' + index}>
                                    <PermissionCheckbox
                                      keyValue={'permiso' + index}
                                      key={'permiso' + index}
                                      onChange={updatePermissions}
                                      user={user}
                                      permiso={permiso as Permission}
                                    />
                                    <div
                                      id="userAdmin-separation"
                                      key={'separation' + index}></div>
                                  </div>
                                );
                              })}
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      {/* Vista Mobile  */}
                      <IonRow class="ion-align-items-center ion-hide-md-up">
                        <IonButton size="small" onClick={() => { editSAPCredentials(user.email) }}>Editar</IonButton>
                        <IonCol size="12" sizeLg="3">
                          <IonLabel class="ion-float-lef ion-text-nowrapt">
                            <IonText id="userAdmin-user" key={'usuario' + index}>
                              {user.email}
                            </IonText>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12" sizeLg="9">
                          {Object.values(Permission)
                            .filter((v) => !isNaN(Number(v)))
                            .map((permiso, index) => {
                              return (
                                <div key={'empty' + index}>
                                  <PermissionCheckbox
                                    keyValue={'permiso' + user + index}
                                    key={'permiso' + index}
                                    onChange={updatePermissions}
                                    user={user}
                                    permiso={permiso as Permission}
                                  />
                                  <div
                                    id="userAdmin-separation"
                                    key={'separation' + index}></div>
                                </div>
                              );
                            })}
                        </IonCol>
                      </IonRow>
                    </div>
                  );
                })
              )}
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonCard>
    </>
  );
};

export default UserAdmin;
