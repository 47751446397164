import axios from 'axios';
import React, { useCallback } from 'react';
import { BukEntry } from '../../interfaces/BukEntry';
import { obtainURL } from '../../common/obtainURL';
import BukSAPTable from '../../buk/buksaptable';
import { BukSap } from '../../interfaces/BukSap';
import { ResponseCache } from '../../interfaces/ResponseCache';
import { useAppContext } from '../../common/appContext';
import { createHeader, logoutIfUnauthorized } from '../../common/auth';
import { onConnectionError } from '../../common/connectionerrorhandling';
import {
  IonCol,
  IonButton,
  IonList,
  IonRow,
  IonCard,
  IonIcon,
  IonTitle,
  IonProgressBar,
} from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';

interface SAPEntries {
  periodo: Empresa;
  reference_1: string;
  export_date: string,
  export_sap_user: string,
  JdtNum: string;
  sapError: any;
  export_user: string,
}

// interface Periodo {
//   [Key: string]: Empresa;
// }

interface Empresa {
  rut: string;
  razon_social: string;
  entries: BukEntry[];
  sap: BukSap[];
  balanced: boolean;
  JdtNum: string;
  sapError: any;
}

interface AsientoSAP {
  id: number;
  referencia: string;
  empresa: string;
  registers: BukSap[];
  JdtNum: string;
  sapError: string;
  export_date: string,
  export_sap_user: string,
  export_user: string,
}

let URL = obtainURL();

const HistorialSAPBuk = () => {
  const defaultCache: ResponseCache = {
    cached: false,
    response: null,
  };

  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [responseSapCache, setResponseSapCache] = React.useState(defaultCache);
  const [errorRetries, setErrorRetries] = React.useState(0);

  const asientosSAPDefault: AsientoSAP[] = [];
  const [asientosSAP, setAsientosSAP] = React.useState(asientosSAPDefault);

  const refreshSAP = useCallback(() => {
    if (responseSapCache.cached) return;

    axios
      .get(URL + '/buk-sap', createHeader(appState.token))
      .then((response) => {
        setResponseSapCache({ cached: true, response: response.data.data });

        let asientos: AsientoSAP[] = [];
        response.data.data.forEach((item: SAPEntries) => {
          let a = convertToSAP(item);
          asientos = asientos.concat(a);
        });

        asientos.sort((a, b) => {
          return b.referencia.localeCompare(a.referencia);
        });

        setAsientosSAP(asientos);
      })
      .catch((error) => {
        console.error(error);
        setErrorRetries(errorRetries + 1);
        if (errorRetries + 1 === 3) {
          onConnectionError({ custom: 'El servicio no responde.' }, appDispatch);
        } else {
          onConnectionError(error, appDispatch);
        }
        setResponseSapCache({ cached: false, response: null });
        logoutIfUnauthorized(error, appDispatch);
      });
  }, [appDispatch, appState.token, errorRetries, responseSapCache.cached]);

  function clearCache() {
    setResponseSapCache({ cached: false, response: null });
    setAsientosSAP([]);
    setErrorRetries(0);
  }

  function convertToSAP(item: SAPEntries) {

    // const periodo: Empresa = item.periodo;
    const reference: string = item.reference_1;
    const asientos: AsientoSAP[] = [];
    let id = 1;

    // for (const key in periodo) {
    const empresa: Empresa = item.periodo; // periodo[key] as Empresa;
    empresa.JdtNum = item.JdtNum;
    empresa.sapError = item.sapError;
    asientos.push({
      id: id++,
      referencia: reference,
      empresa: empresa.razon_social,
      registers: empresa.sap,
      JdtNum: empresa.JdtNum !== undefined ? '' + empresa.JdtNum : '',
      export_date: item.export_date,
      export_sap_user: item.export_sap_user,
      export_user: item.export_user,
      sapError:
        empresa.sapError !== undefined && empresa.sapError !== ''
          ? '[' +
          empresa.sapError.error.code +
          '] ' +
          empresa.sapError.error.message.value
          : '',
    });

    return asientos;
  }

  React.useEffect(() => {
    if (errorRetries < 3) {
      refreshSAP();
    } else {
      setResponseSapCache({ cached: true, response: null });
    }
  }, [errorRetries, refreshSAP]);

  return (
    <>
      <IonCard>
        <IonRow class="ion-align-items-center ion-justify-content-between ion-margin-bottom">
          <IonCol size="11" sizeLg="4">
            <IonTitle color="primary" class="ion-text-center">
              <h2 className="bold">Historial SAP BUK</h2>
            </IonTitle>
          </IonCol>
          <IonCol size="12" sizeLg="3">
            <IonButton
              expand="block"
              color="primary"
              onClick={clearCache}
              disabled={!responseSapCache.cached}>
              {' '}
              <IonIcon slot="start" icon={refreshOutline}></IonIcon>
              Refrescar
            </IonButton>
          </IonCol>
        </IonRow>

        {responseSapCache.cached === false ? (
          <IonProgressBar type="indeterminate"></IonProgressBar>
        ) : (
          <IonCard>
            <IonList id="sap" lines="none">
              {
                asientosSAP.length > 0 ?
                  asientosSAP.map((asiento, index) => {
                    return (
                      <BukSAPTable
                        key={index}
                        keyValue={index}
                        title={
                          'Período ' +
                          asiento.referencia +
                          // ' Asiento ' +
                          // asiento.id +
                          ' / ' +
                          (asiento.JdtNum.length > 0
                            ? '[' + asiento.JdtNum + '] '
                            : '') +
                          asiento.empresa
                        }
                        subtitle={
                          asiento.export_user ?
                            'Exportado por: ' + asiento.export_user +
                            ', usuario SAP: ' + asiento.export_sap_user +
                            ' en la fecha: ' + new Date(asiento.export_date).toLocaleString('es-CL')
                            :
                            ''
                        }
                        rows={asiento.registers}
                        empresa={asiento.empresa}
                        showToggle={false}
                      />
                    );
                  })
                  :
                  <>No hay registros</>
              }
            </IonList>
          </IonCard>
        )}
      </IonCard>

      {/* <button className="colorA" onClick={clearCache} disabled={!responseSapCache.cached}>Refrescar</button>
			{
				asientosSAP.length === 0 ?
					<div>Cargando...</div>
					:
					<div id="sap">
						{asientosSAP.map(
							(asiento, index) => {
								return (
									<BukSAPTable key={index} keyValue={index} title={'Período ' + asiento.referencia + ' Asiento ' + asiento.id + ' / ' + (asiento.JdtNum.length > 0 ? '[' + asiento.JdtNum + '] ' : '') + asiento.empresa} rows={asiento.registers} />
								)
							}
						)}
					</div>
			} */}
    </>
  );
};

export default HistorialSAPBuk;
