import React, { ChangeEvent } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useAppContext } from '../common/appContext';
import { AppStateReducerActionTypes } from '../common/reducer';
import { FincloudSap, FincloudSapJournalEntry } from '../interfaces/FincloudSap';
import {
  IonText,
  IonCol,
  IonRow,
  IonCard,
  IonItem,
  // IonCheckbox,
  IonButton,
  // CheckboxChangeEventDetail,
  // IonInput,
} from '@ionic/react';
// import { IonCheckboxCustomEvent } from '@ionic/core'

interface fst {
  title: string;
  subtitle?: string;
  sapRegister: FincloudSap;
  keyValue: string;
  showToggle: boolean;
  empresa: string;
}

const toggle_key = 'FINCLOUD'

const FincloudSAPTable = (props: fst) => {
  const sapColumns: TableColumn<FincloudSapJournalEntry>[] = [
    {
      name: 'Line_ID',
      selector: (row) => row.Line_ID.toString(),
      sortable: true,
      width: '100px',
      center: true,
    },
    {
      name: 'AccountCode',
      selector: (row) => row.AccountCode,
      sortable: true,
      width: '130px',
      center: true,
    },
    {
      name: 'DueDate',
      selector: (row) => row.DueDate,
      sortable: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Debit',
      selector: (row) => row.Debit.toString(),
      sortable: true,
      width: '100px',
      right: true,
    },
    {
      name: 'Credit',
      selector: (row) => row.Credit.toString(),
      sortable: true,
      width: '100px',
      right: true,
    },
    {
      name: 'ShortName',
      selector: (row) => row.ShortName,
      sortable: true,
      width: '120px',
    },
    {
      name: 'LineMemo',
      selector: (row) => row.LineMemo,
      sortable: true,
      width: '300px',
    },
    {
      name: 'ReferenceDate1',
      selector: (row) => row.ReferenceDate1,
      sortable: true,
      width: '130px',
      center: true,
    },
    {
      name: 'Reference1',
      selector: (row) => row.Reference1,
      sortable: true,
      width: '250px',
    },
    {
      name: 'Reference2',
      selector: (row) => row.Reference2,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Reference3',
      selector: (row) => row.AdditionalReference,
      sortable: true,
      width: '130px',
    },
    {
      name: 'CostingCode',
      selector: (row) => row.CostingCode,
      sortable: true,
      width: '150px',
    },
    {
      name: 'CostingCode2',
      selector: (row) => row.CostingCode2,
      sortable: true,
      width: '200px',
    },
    {
      name: 'CostingCode3',
      selector: (row) => row.CostingCode3,
      sortable: true,
      width: '200px',
    },
    {
      name: 'CostingCode4',
      selector: (row) => row.CostingCode4,
      sortable: true,
      width: '200px',
    },
    {
      name: 'FederalTaxID',
      selector: (row) => row.FederalTaxID,
      width: '100px',
    },
  ];

  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [theme, setTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  React.useEffect(() => {
    setSAPReady(
      props && props.sapRegister && props.sapRegister.JournalEntryLines.length > 0
    );
    setSAPRows(props && props.sapRegister.JournalEntryLines);
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? "dark" : "light";
        console.log(colorScheme); // "dark" or "light"
        setTheme(event.matches ? 'dark' : 'light');
      });

  }, [props]);

  const sapRowsDefault: FincloudSapJournalEntry[] = [];
  const [sapRows, setSAPRows] = React.useState(sapRowsDefault);
  const [sapReady, setSAPReady] = React.useState(false);

  const [showDetails, setShowDetails] = React.useState(false);

  // const [hasFocus, setHasFocus] = React.useState(false);

  function onChanged() {
    setShowDetails(!showDetails);
  }

  function onToggled(event: ChangeEvent<HTMLInputElement>) {
    //function onToggled(event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) {
    appDispatch({
      type: event.target.checked
        ? AppStateReducerActionTypes.TOGGLE_ADD_ITEM
        : AppStateReducerActionTypes.TOGGLE_REMOVE_ITEM,
      toggle_item_payload: { key: toggle_key, empresa: props.empresa, id: props.keyValue },
    });
  }

  return (
    <>
      {sapReady ? (
        <IonRow class='ion-align-items-center'>
          <IonCol size='12' sizeLg='3'>
            <IonItem
              lines='none'
              key={props.keyValue + 'a'}
              class='ion-item ion-button'>
              <div
                id='sapItemFincloud'
                className={props.showToggle ? 'withToggle' : 'withoutToggle'}
                key={props.keyValue + 'a'}>
                <div id='sel'>
                  {props.showToggle ? (
                    // <IonCheckbox
                    //   onIonChange={onToggled}
                    //   checked={
                    //     appState.toggleDictionary[props.empresa][props.keyValue]
                    //   }></IonCheckbox>
                    <input type="checkbox" onChange={onToggled}
                      checked={
                        appState.toggleDictionaries[toggle_key] ?
                          appState.toggleDictionaries[toggle_key][props.empresa][props.keyValue]
                          :
                          false
                      } />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <IonButton
                size='large'
                fill='clear'
                color='primary'
                key={props.keyValue + 'd'}
                onClick={() => onChanged()}>
                {showDetails ? 'Cerrar' : 'Ver detalle'}
              </IonButton>
            </IonItem>
          </IonCol>

          <IonCol size='12' sizeLg='9'>
            <IonText id='title' class='ion-text-center bold'>
              {props.title}
            </IonText>
            <IonText id='subtitle'>
              Cta: <>{sapRows[0]?.AccountCode}</> (Crédito: <>{sapRows[0]?.Credit}</>
              ) <>{sapRows[0]?.ShortName}</>
            </IonText>
            {
              props.subtitle ?
                <><br /><IonText id='title'>{props.subtitle}</IonText></>
                :
                <>
                </>
            }
          </IonCol>
          {showDetails ? (
            <IonCard id='table'>
              <DataTable
                key={props.keyValue + 'b'}
                title={props.title}
                columns={sapColumns}
                data={sapRows}
                pagination
                dense
                striped
              // theme={theme}
              />
            </IonCard>
          ) : (
            <></>
          )}
        </IonRow>
      ) : (
        <></>
      )}
    </>
  );
};

export default FincloudSAPTable;

//  {
//    sapReady ? (
//      <div
//        id='sapItemFincloud'
//        className={props.showToggle ? 'withToggle' : 'withoutToggle'}
//        key={props.keyValue + 'a'}>
//        <div id='sel'>
//          {props.showToggle ? (
//            <input
//              type='checkbox'
//              onChange={onToggled}
//              checked={appState.toggleDictionary[props.empresa][props.keyValue]}
//            />
//          ) : (
//            <></>
//          )}
//        </div>
//        <IonItem lines='none' key={props.keyValue + 'd'} class='ion-item ion-button'>
//          <IonCheckbox
//            slot='start'
//            checked={showDetails}
//            id='check'
//            key={props.keyValue + 'd'}
//            onIonChange={onChanged}></IonCheckbox>
//          <IonLabel>{showDetails ? 'Cerrar' : 'Ver detalle'}</IonLabel>
//        </IonItem>

//        <IonText id='title'>{props.title}</IonText>
//        <div id='subtitle'>
//          Cta: <>{sapRows[0]?.AccountCode}</> (Crédito: <>{sapRows[0]?.Credit}</>){' '}
//          <>{sapRows[0]?.ShortName}</>
//        </div>

//        {showDetails ? (
//          <IonCard id='table'>
//            <DataTable
//              key={props.keyValue + 'b'}
//              title={props.title}
//              columns={sapColumns}
//              data={sapRows}
//              pagination
//              dense
//              striped
//            />
//          </IonCard>
//        ) : (
//          <></>
//        )}
//      </div>
//    ) : (
//      <></>
//    );
//  }
