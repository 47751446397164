import React from 'react';
import { IonCard } from '@ionic/react';

const Welcome = () => {
  return (
    <>
      <IonCard id='welcome' color="white">
        <img src="/Logo-PSG-Fondo-Blanco.png" alt="Grupo PSG" />
      </IonCard>
    </>
  );
};

export default Welcome;
