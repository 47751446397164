import React, { Dispatch } from 'react';
import { AppState, AppStateAction } from './reducer';

interface IAppContextProps {
  state: AppState;
  dispatch: Dispatch<AppStateAction>;
}

const AppContext = React.createContext({} as IAppContextProps);

export function useAppContext() {
  return React.useContext(AppContext);
}

export default AppContext;
