import { useAppContext } from '../../common/appContext';
import { AppStateReducerActionTypes } from '../../common/reducer';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { documentTextOutline } from 'ionicons/icons';

function BukSelect() {
  const { dispatch: stateDispatch } = useAppContext();

  function showBuk() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_BUK });
  }

  function showSapBuk() {
    stateDispatch({ type: AppStateReducerActionTypes.SHOW_SAP_BUK });
  }

  return (
    <>
      <IonItem slot="header" color="primary">
        <IonIcon
          color="white"
          icon={documentTextOutline}
          slot="start"></IonIcon>
        <IonLabel>BUK</IonLabel>
      </IonItem>
      <div
        className="ion-padding divStyle"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showBuk}>
        Exportar BUK
      </div>
      <div
        className="ion-padding"
        style={{ color: '#ffff', background: '#002a54' }}
        slot="content"
        onClick={showSapBuk}>
        Historial SAP Buk
      </div>
    </>
  );
}

export default BukSelect;
