import React from "react";
import { AppStateAction, AppStateReducerActionTypes } from "./reducer";

export const logoutIfUnauthorized = (reason: any, appDispatch: React.Dispatch<AppStateAction>) => {
	if (reason.response.status === 401) {
		localStorage.removeItem("user");
		localStorage.removeItem("screen");
		localStorage.removeItem("sapcredentials");
		appDispatch({ type: AppStateReducerActionTypes.LOGOUT });
	}
}

export const createHeader = (token: string) => {
	return {
		headers: { Authorization: `Bearer ${token}` },
	};
}